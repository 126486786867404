import {FormikHelpers} from "formik";
import {
    useCreateSuccessStoryMutation,
    useUpdateSuccessStoryMutation
} from "../../../../queries/successStories/mutations";
import {SuccessStoryFormValues, UseSuccessStoryForm} from "./types";
import {CreateSuccessStoryRequest, UpdateSuccessStoryRequest} from "../../../../queries/successStories/types";
import {SuccessStory} from "../../../../types/successStories";

const useSuccessStoryForm = (): UseSuccessStoryForm => {
    const createMutation = useCreateSuccessStoryMutation();
    const updateMutation = useUpdateSuccessStoryMutation();

    const create = async (values: SuccessStoryFormValues, actions: FormikHelpers<SuccessStoryFormValues>) => {
        try {
            const request: CreateSuccessStoryRequest = values;
            await createMutation.mutateAsync({request}, {
                onSuccess: async () => {
                },
            });
        } catch (error) {
        }
    };

    const update = async (values: SuccessStoryFormValues, actions: FormikHelpers<SuccessStoryFormValues>, model: SuccessStory) => {
        try {
            const request: UpdateSuccessStoryRequest = values;
            await updateMutation.mutateAsync(
                {request, successStoryId: String(model.id)},
                {
                    onSuccess: async () => {
                    },
                },
            );
        } catch (error) {
        }
    };

    return {
        create,
        update,
    };
};

export default useSuccessStoryForm;