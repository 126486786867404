import { useQuery, useQueryClient, UseQueryResult } from "react-query";
import { httpClient } from "../../api/axios";
import { Project } from "../../types/projects";

export const useGetAllAvailableProjectsQuery = (): UseQueryResult<Project[], Error> =>
    useQuery<Project[], Error>(
        "projects",
        async () => {
            const response = await httpClient.get(`/projects?excludeAttached=true`);
            return response.data;
        }
    );
export const useGetAllProjectsQuery = (): UseQueryResult<Project[], Error> => useQuery<Project[], Error>(
    "projects",
    async () => {
        const response = await httpClient.get("/projects");
        return response.data;
    }
);

export const useGetSingleProjectQuery = (projectId: string | undefined): UseQueryResult<Project, Error> => {
    const queryClient = useQueryClient();

    return useQuery<Project, Error>(
        ["projects", projectId],
        async () => {
            if (projectId) {
                const response = await httpClient.get(`/projects/${projectId}`);
                return response.data;
            }
        },
        {
            placeholderData: (): Project | undefined => queryClient
                .getQueryData<Project[]>("projects")
                ?.find((c: Project) => c.id === projectId)
        }
    );
};