import React from "react";
import {Container, Divider, Paper, Typography,} from "@mui/material";
import {AppRoutes} from "../../../routes/AppRoutes";
import { AppCreateEditForm } from "../../../components/AppCreateEditForm";
import AppFormHeader from "../../../components/AppFormHeader";
import {useHeaderStyles} from "../../../utils/style";
import TeamMemberForm from "../Forms/TeamMemberForm/TeamMemberForm";
import Diversity3Icon from "@mui/icons-material/Diversity3";

function CreateTeamMember() {
    const classes = useHeaderStyles();
    return (
        <AppCreateEditForm>
            <AppFormHeader level1Route={AppRoutes.TEAM}
                           level1Caption='Team Member'
                           level1Icon={Diversity3Icon}
                           level2Caption='Create Team Member'
                           actionButton={null}/>
            <Paper className={classes.paper}>
                <Container sx={{pt: 3, pb: 2}}>
                    <Typography variant="h5" gutterBottom>
                        Create Team Member
                    </Typography>
                </Container>
                <Divider/>
                <TeamMemberForm/>
            </Paper>
        </AppCreateEditForm>
    );
}

export default CreateTeamMember;
