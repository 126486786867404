import {useMutation, UseMutationResult, useQueryClient} from 'react-query';
import {httpClient} from "../../api/axios";
import {Project, ProjectImage} from "../../types/projects";
import {
    CreateProjectImageMutationVariables,
    CreateProjectMutationVariables,
    DeleteProjectMutationVariables,
    UpdateProjectMutationVariables
} from "./types";
import { AxiosResponse } from "axios";
import { AppRoutes } from "../../routes/AppRoutes";
import { useNavigate } from "react-router-dom";

export const useCreateProjectMutation = (): UseMutationResult<Project, Error, CreateProjectMutationVariables> => {
    const queryClient = useQueryClient();
    const navigate = useNavigate()

    return useMutation<Project, Error, CreateProjectMutationVariables>(
        async ({request}) => {
            const response = await httpClient.post('/projects', request);
            const projectId = response.headers.location?.split("/")[1] || "";

            if (projectId) {
                if (request?.image?.file !== undefined) {
                    const formData = new FormData();
                    formData.append("file", request.image.file as Blob);
                    await httpClient.post(`/projects/${projectId}/images`, formData, { headers: { "Content-Type": "multipart/form-data" } });
                }
            }
            navigate(`${AppRoutes.PROJECTS}`);
            return response.data
        },
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries('projects');
            },
        },
    );
};

export const useUpdateProjectMutation = (): UseMutationResult<Project, Error, UpdateProjectMutationVariables> => {
    const queryClient = useQueryClient();

    return useMutation<Project, Error, UpdateProjectMutationVariables>(
        async ({request, projectId}) => {
            const response = await httpClient.put(`/projects/${projectId}`, request);
            return response.data;
        },
        {
            onSuccess: async (data, variables) => {
                await queryClient.invalidateQueries(['projects', variables.projectId]);
            },
        },
    );
};

export const useDeleteProjectMutation = (): UseMutationResult<AxiosResponse, Error, DeleteProjectMutationVariables> => {
    const queryClient = useQueryClient();

    return useMutation<AxiosResponse, Error, DeleteProjectMutationVariables>(
        async ({ id }) => {
            return await httpClient.delete(`/projects/${id}`);
        },
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries('projects');
            },
        },
    );
};

export const useCreateProjectImageMutation = (): UseMutationResult<ProjectImage, Error, CreateProjectImageMutationVariables> => {
    const queryClient = useQueryClient();

    return useMutation<ProjectImage, Error, CreateProjectImageMutationVariables>(
        async ({projectId, request}) => {
            const formData = new FormData();
            if (request?.file !== undefined) {
                formData.append('file', request?.file as Blob);
            }

            const response = await httpClient.post(`/projects/${projectId}/images`, formData, {headers: {'Content-Type': 'multipart/form-data'}});
            return response.data;
        },
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries('project-images');
            },
        },
    );
};
