import { FormikHelpers } from "formik";
import {
    CreateProjectImageRequest,
    CreateProjectRequest,
    UpdateProjectRequest
} from "../../../../queries/projects/types";
import {
    useCreateProjectImageMutation,
    useCreateProjectMutation,
    useUpdateProjectMutation,
    useDeleteProjectMutation
} from "../../../../queries/projects/mutations";
import {
    ProjectFormValues,
    ProjectImageFormValues,
    UseProjectForm,
    UseProjectImageForm
} from "./types";

export const useProjectForm = (): UseProjectForm => {
    const createMutation = useCreateProjectMutation();
    const updateMutation = useUpdateProjectMutation();
    const deleteMutation = useDeleteProjectMutation();

    const create = async (values: ProjectFormValues, actions: FormikHelpers<ProjectFormValues>) => {
        try {
            const request: CreateProjectRequest = values as CreateProjectRequest;
            const result = await createMutation.mutateAsync({ request }, {
                onSuccess: async (data) => {
                    console.log("data", data);
                }
            });
            console.log("result", result);
        } catch (error) {
        }
    };

    const update = async (values: ProjectFormValues, projectId: string) => {
        try {
            const request: UpdateProjectRequest = values;
            await updateMutation.mutateAsync(
                { request, projectId },
                {
                    onSuccess: async () => {
                    }
                }
            );
        } catch (error) {
        }
    };

    const remove = async (id: string) => {
        try {
            const data = await deleteMutation.mutateAsync(
                { id },
                {
                    onSuccess: async () => {
                    }
                }
            );
            console.log("delete data", data);
        } catch (error) {
        }
    };

    return {
        create,
        update,
        remove
    };
};


export const useProjectImageForm = (): UseProjectImageForm => {
    const createMutation = useCreateProjectImageMutation();

    const create = async (values: ProjectImageFormValues, actions: FormikHelpers<ProjectImageFormValues>) => {
        try {
            const request: CreateProjectImageRequest = values;

            await createMutation.mutateAsync({ projectId: values.projectId, request }, {
                onSuccess: async () => {
                }
            });
        } catch (error) {
        }
    };

    return {
        create
    };
};
