import {SvgIcon} from '@mui/material';
// SVG assets
import {ReactComponent as LogoIcon} from './logo.svg';
// Material Icons
import DefaultIcon from '@mui/icons-material/MoreHoriz';
import SettingsIcon from '@mui/icons-material/Settings';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import DayNightIcon from '@mui/icons-material/Brightness4';
import NightIcon from '@mui/icons-material/Brightness3';
import DayIcon from '@mui/icons-material/Brightness5';
import SearchIcon from '@mui/icons-material/Search';
import InfoIcon from '@mui/icons-material/Info';
import HomeIcon from '@mui/icons-material/Home';
import AccountCircle from '@mui/icons-material/AccountCircle';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonIcon from '@mui/icons-material/Person';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import NotificationsIcon from '@mui/icons-material/NotificationsOutlined';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import TokenIcon from '@mui/icons-material/Token';
import SolarPowerIcon from '@mui/icons-material/SolarPower';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import BallotIcon from '@mui/icons-material/Ballot';
import ApartmentIcon from '@mui/icons-material/Apartment';
import React from "react";

/**
 * How to use:
 * 1. Import all required MUI or other SVG icons into this file.
 * 2. Add icons with "unique lowercase names" into ICONS object.
 * 3. Use icons everywhere in the App by their names in <AppIcon name="xxx" /> component
 * Important: properties of ICONS object MUST be lowercase!
 * Note: You can use camelCase or UPPERCASE in the <AppIcon name="someIconByName" /> component
 */
const ICONS: Record<string, React.ComponentType> = {
    default: DefaultIcon,
    logo: () => (
        <SvgIcon>
            <LogoIcon width="100%" height="100%" />
        </SvgIcon>
    ),
    close: CloseIcon,
    menu: MenuIcon,
    settings: SettingsIcon,
    visibilityon: VisibilityIcon,
    visibilityoff: VisibilityOffIcon,
    daynight: DayNightIcon,
    night: NightIcon,
    day: DayIcon,
    search: SearchIcon,
    info: InfoIcon,
    home: HomeIcon,
    account: AccountCircle,
    signup: PersonAddIcon,
    login: PersonIcon,
    logout: ExitToAppIcon,
    notifications: NotificationsIcon,
    add: AddIcon,
    delete: DeleteIcon,
    back: ArrowBackIcon,
    pool: TokenIcon,
    project: SolarPowerIcon,
    team: Diversity3Icon,
    success: AutoStoriesIcon,
    testimonial: BallotIcon,
    company: ApartmentIcon,
    platform: SettingsIcon
};

interface Props {
    name?: string; // Icon's name
    icon?: string; // Icon's name alternate prop
}

const AppIcon: React.FC<Props> = ({name, icon, ...restOfProps}) => {
    const iconName = (name || icon || 'default').trim().toLowerCase();
    const ComponentToRender = ICONS[iconName] || DefaultIcon;
    return <ComponentToRender {...restOfProps} />;
};

export default AppIcon;
