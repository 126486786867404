import React, { ReactElement } from "react";
import { Breadcrumbs, Button, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { componentHeaderStyle } from "../../utils/style";
import { AppRoutes } from "../../routes/AppRoutes";
import HomeIcon from "@mui/icons-material/Home";
import { Theme } from "@mui/material/styles";
import SvgIcon from "@mui/material/SvgIcon/SvgIcon";
import SideBarLink from "../SideBar/SideBarLink";

const useStyles = makeStyles((theme: Theme) => ({
    panelHeader: {
        ...componentHeaderStyle(theme)
    }
}));

interface AppFormHeaderProps {
    level1Route: string;
    level1Caption: string,
    level1Icon: typeof SvgIcon,
    level2Caption: string | null;
    actionButton: ReactElement | null;
}

const AppFormHeader: React.FC<AppFormHeaderProps> = (props: AppFormHeaderProps): ReactElement => {
    const classes = useStyles();
    return (
        <div className={classes.panelHeader}>
            <Breadcrumbs aria-label="breadcrumb">
                <Button
                    sx={{ display: "flex", alignItems: "center" }}
                    component={SideBarLink}
                    to={AppRoutes.HOME as string}
                >
                    <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                    Home
                </Button>
                <Button
                    sx={{ display: "flex", alignItems: "center" }}
                    component={SideBarLink}
                    to={props.level1Route as string}
                >
                    <SvgIcon sx={{ mr: 0.5 }} fontSize="inherit" component={props.level1Icon} />
                    {props.level1Caption}
                </Button>
                {props.level2Caption && <Typography color="text.primary">{props.level2Caption}</Typography>}
            </Breadcrumbs>
            {props.actionButton}
        </div>
    );
};

export default AppFormHeader;
