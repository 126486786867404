import {
    PoolFormValues,
    PoolImageFormValues,
    PoolProjectFormValues,
    UsePoolForm,
    UsePoolImageForm,
    UsePoolProjectForm
} from "./types";
import { FormikHelpers } from "formik";
import {
    CreatePoolImageRequest,
    CreatePoolProjectMutationVariables,
    CreatePoolRequest,
    DeletePoolProjectMutationVariables,
    UpdatePoolRequest
} from "../../../../queries/pools/types";
import {
    useCreatePoolImageMutation,
    useCreatePoolMutation,
    useCreatePoolProjectMutation,
    useDeletePoolMutation,
    useDeletePoolProjectMutation, usePoolVisibilityMutation,
    useUpdatePoolMutation,
    useUpdateStatusMutation
} from "../../../../queries/pools/mutations";
import axios from "axios";
import { PoolStatus } from "../../../../types/pools";

export const usePoolForm = (): UsePoolForm => {
    const createMutation = useCreatePoolMutation();
    const updateMutation = useUpdatePoolMutation();
    const deleteMutation = useDeletePoolMutation();
    const updateStatusMutation = useUpdateStatusMutation();
    const visibilityMutation = usePoolVisibilityMutation()

    const create = async (values: PoolFormValues, actions: FormikHelpers<PoolFormValues>) => {
        try {
            const request: CreatePoolRequest = values as CreatePoolRequest;
            return await createMutation.mutateAsync({ request }, {
                onSuccess: async (data) => {
                }
            });
        } catch (error) {
        }
    };

    const update = async (values: PoolFormValues, poolId: string) => {
        try {
            const request: UpdatePoolRequest = values;
            const data = await updateMutation.mutateAsync(
                { request, poolId },
                {
                    onSuccess: async () => {
                    }
                }
            );
            console.log("updated data", data);
        } catch (error) {
        }
    };

    const updateStatus = async (poolId: string, status: PoolStatus) => {
        try {
            const data = await updateStatusMutation.mutateAsync(
                { poolId, status },
                {
                    onSuccess: async () => {
                    }
                }
            );
            console.log("updated status data", data);
        } catch (error) {
        }
    };

    const remove = async (poolId: string) => {
        try {
            const data = await deleteMutation.mutateAsync(
                { poolId },
                {
                    onSuccess: async () => {
                    }
                }
            );
            console.log("delete data", data);
        } catch (error) {
        }
    };

    const toggleVisibility = async (poolId: string) => {
        try {
            const data = await visibilityMutation.mutateAsync(
                { poolId },
                {
                    onSuccess: async () => {
                    }
                }
            );
            console.log("change visibility", data);
        } catch (error) {
        }
    };

    return {
        create,
        update,
        updateStatus,
        remove,
        toggleVisibility
    };
};

export const usePoolImageForm = (): UsePoolImageForm => {
    const createMutation = useCreatePoolImageMutation();

    const create = async (values: PoolImageFormValues, actions: FormikHelpers<PoolImageFormValues>) => {
        try {
            const request: CreatePoolImageRequest = values;

            await createMutation.mutateAsync({ poolId: values.poolId, request }, {
                onSuccess: async () => {
                }
            });
        } catch (error) {
        }
    };

    return {
        create
    };
};

export const usePoolProjectForm = (): UsePoolProjectForm => {
    const createMutation = useCreatePoolProjectMutation();
    const deleteMutation = useDeletePoolProjectMutation();

    const create = async (values: CreatePoolProjectMutationVariables, actions: FormikHelpers<PoolProjectFormValues>) => {

        try {
            await createMutation.mutateAsync(values);
        }
            // @ts-ignore
        catch (err: Error | AxiosError) {
            if (axios.isAxiosError(err)) {
                actions.setStatus(err.response?.data.detail);
            } else {
            }
        }
    };

    const deletePoolProjects = async (values: DeletePoolProjectMutationVariables, actions: FormikHelpers<PoolProjectFormValues>) => {
        try {
            await deleteMutation.mutateAsync(values);
        }
            // @ts-ignore
        catch (err: Error | AxiosError) {
            if (axios.isAxiosError(err)) {
                actions.setStatus(err.response?.data.detail);
            } else {
                // Just a stock error
            }
        }
    };

    return {
        create,
        deletePoolProjects
    };
};

