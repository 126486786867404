import React, { ReactElement } from "react";
import { DataGrid, GridColumns, GridFilterModel } from "@mui/x-data-grid";
import { RowsPerPage } from "../../utils";
import { GridEventListener } from "@mui/x-data-grid/models/events";

export interface DataGridProps {
    rows: any;
    columns: GridColumns;
    isLoading: boolean;
    onRowClick?: GridEventListener<"rowClick">;
    columnField?: string;
    filterValue?: string | undefined;
}

const AppDataGrid: (props: DataGridProps) => React.ReactElement = (props: DataGridProps): ReactElement => {
    const { rows, columns, isLoading, onRowClick, filterValue, columnField = "" } = props;

    const gridFilterModel: GridFilterModel = {
        items: [{ columnField, operatorValue: "contains", value: `${filterValue}` }],
    };

    return (
        <DataGrid
            filterModel={filterValue ? gridFilterModel : undefined}
            // columnVisibilityModel={{
            //     draft: false
            // }}
            density={"comfortable"}
            autoHeight
            rows={rows || []}
            columns={columns}
            pageSize={RowsPerPage()}
            rowsPerPageOptions={[RowsPerPage()]}
            checkboxSelection
            onRowClick={onRowClick}
            loading={isLoading}
        />
    );
};

export default AppDataGrid;
