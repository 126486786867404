import React, { ReactElement, useCallback } from "react";
import { CompanyFormProps, CompanyFormValues, CompanyValidationSchema } from "./types";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import { Box, Container, Grid } from "@mui/material";
import { AppTextField } from "../../../../components/AppFields";
import { AppButton } from "../../../../components";
import { AppRoutes } from "../../../../routes/AppRoutes";
import { useNavigate } from "react-router-dom";
import useCompanyForm from "./hooks";
import { useGetSingleCompanyQuery } from "../../../../queries/companies";

const CompanyForm: React.FC<CompanyFormProps> = (props: CompanyFormProps): ReactElement => {
    const navigate = useNavigate();
    const { create, update } = useCompanyForm();
    const id = props.model?.id;
    const { data: model } = useGetSingleCompanyQuery(id);

    let initialValues: CompanyFormValues;

    const handleFinish = useCallback(() => {
        navigate(`${AppRoutes.COMPANIES}`);
    }, [navigate]);


    if (model) {
        initialValues = {
            name: model.name,
            description: model.description
        };
    } else {
        initialValues = {
            name: "",
            description: ""
        };
    }

    return (
        <>
            <Formik
                validateOnChange={true}
                validateOnBlur={false}
                initialValues={initialValues}
                enableReinitialize={true}
                validationSchema={CompanyValidationSchema}
                onSubmit={
                    async (values: CompanyFormValues, actions: FormikHelpers<CompanyFormValues>) => {
                        if (model?.id) {
                            await update(values, actions, model);
                        } else {
                            await create(values, actions);
                        }
                        navigate(`${AppRoutes.COMPANIES}`);
                    }
                }
            >
                {(formProps: FormikProps<CompanyFormValues>) => {
                    const {
                        values,
                        errors,
                        handleChange,
                        isSubmitting
                    } = formProps;
                    return (
                        <Form noValidate>
                            <Container sx={{ pt: 3, pb: 3 }}>
                                <Grid
                                    rowSpacing={4}
                                    container spacing={2}
                                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                                >
                                    <Grid item xs={12} sm={6}>
                                        <AppTextField
                                            id="name"
                                            label="Name"
                                            required
                                            error={!!errors.name}
                                            helperText={errors.name}
                                            value={values.name}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <AppTextField
                                            id="description"
                                            label="Description"
                                            multiline
                                            rows={10}
                                            error={!!errors.description}
                                            helperText={errors.description}
                                            value={values.description}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                </Grid>
                            </Container>
                            <Container sx={{ pt: 2, pb: 2 }}>
                                <Grid container spacing={2} rowSpacing={2}>
                                    {/*<Grid item xs={12}>*/}
                                    {/*    <Typography variant="h6" gutterBottom>*/}
                                    {/*        Manage settings*/}
                                    {/*    </Typography>*/}
                                    {/*</Grid>*/}
                                    <Grid item xs={12}>
                                        <Box display="flex" justifyContent="flex-end">
                                            <AppButton variant="outlined" onClick={handleFinish}>
                                                Cancel
                                            </AppButton>

                                            <AppButton type="submit" variant="contained" disabled={isSubmitting}>
                                                {model?.id ? "Save" : "Create"}
                                            </AppButton>
                                        </Box>
                                    </Grid>
                                    {/*{model?.id &&*/}
                                    {/*    <>*/}
                                    {/*        <Grid item xs={12}>*/}
                                    {/*            <Divider />*/}
                                    {/*        </Grid>*/}
                                    {/*        <Grid item xs={12}>*/}
                                    {/*            <Typography color="error" variant="h6" gutterBottom>*/}
                                    {/*                Danger zone*/}
                                    {/*            </Typography>*/}
                                    {/*        </Grid>*/}
                                    {/*        <Grid item xs={6}>*/}
                                    {/*            <Typography color="error" gutterBottom>*/}
                                    {/*                Delete company*/}
                                    {/*            </Typography>*/}
                                    {/*        </Grid>*/}
                                    {/*        <Grid item xs={6}>*/}
                                    {/*            <Box display="flex" justifyContent="flex-end">*/}
                                    {/*                <AppButtonError variant="text">*/}
                                    {/*                    Delete*/}
                                    {/*                </AppButtonError>*/}
                                    {/*            </Box>*/}
                                    {/*        </Grid>*/}
                                    {/*    </>*/}
                                    {/*}*/}
                                </Grid>
                            </Container>
                        </Form>
                    );
                }
                }
            </Formik>
        </>
    );
};

export default CompanyForm;
