import {PlatformImpact} from "../../types/platformImpact";
import {useMutation, UseMutationResult, useQueryClient} from "react-query";
import {httpClient} from "../../api/axios";
import {UpdatePlatformImpactMutationVariables} from "./types";

export const useUpdatePlatformImpactMutation = (): UseMutationResult<PlatformImpact, Error, UpdatePlatformImpactMutationVariables> => {
    const queryClient = useQueryClient();

    return useMutation<PlatformImpact, Error, UpdatePlatformImpactMutationVariables>(
        async ({request}) => {
            const response = await httpClient.put(`/platform`, request);
            return response.data;
        },
        {
            onSuccess: async (data, variables) => {
                await queryClient.invalidateQueries(['platform']);
            },
        },
    );
};