import * as yup from "yup";
import {FormProps} from "../../../../utils/FormProps";
import {FormikHelpers} from "formik";
import {SuccessStory} from "../../../../types/successStories";

export interface SuccessStoryFormValues {
    id?: string;
    name?: string | null;
    description?: string | null;
}

export const SuccessStoryValidationSchema = yup.object().shape({
    name: yup.string().required("Required"),
    description: yup.string().required("Required"),
});

export type SuccessStoryFormProps = FormProps<SuccessStory>;

export interface UseSuccessStoryForm {
    create: (values: SuccessStoryFormValues, actions: FormikHelpers<SuccessStoryFormValues>) => void
    update: (values: SuccessStoryFormValues, actions: FormikHelpers<SuccessStoryFormValues>, model: SuccessStory) => void
}
