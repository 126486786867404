import {FormikHelpers} from "formik";
import {SuccessStoryImageFormValues, UseSuccessStoryImageForm} from "./types";
import {useCreateSuccessStoryImageMutation} from "../../../../queries/successStories/mutations";
import {CreateSuccessStoryImageRequest} from "../../../../queries/successStories/types";

const useSuccessStoryImageForm = (): UseSuccessStoryImageForm => {
    const createMutation = useCreateSuccessStoryImageMutation();

    const create = async (values: SuccessStoryImageFormValues, actions: FormikHelpers<SuccessStoryImageFormValues>) => {
        try {
            const request: CreateSuccessStoryImageRequest = values;

            await createMutation.mutateAsync({successStoryId: values.successStoryId, request}, {
                onSuccess: async () => {
                },
            });
        } catch (error) {
        }
    };

    return {
        create
    };
};

export default useSuccessStoryImageForm;