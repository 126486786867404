import { AppStore } from "./store";
import { AppThemeProvider } from "./theme";
import Routes from "./routes";
import Layout from "./layout";
import { BrowserRouter } from "react-router-dom";
import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";

export const queryClient = new QueryClient();

function getLibrary(provider: any): Web3Provider {
    const library = new Web3Provider(provider);
    library.pollingInterval = 12000;
    return library;
}

export const App = () => {
    return (
        <QueryClientProvider client={queryClient}>
            <Web3ReactProvider getLibrary={getLibrary}>
                <AppStore>
                    <AppThemeProvider>
                        <BrowserRouter>
                            <Layout>
                                <Routes />
                            </Layout>
                        </BrowserRouter>
                    </AppThemeProvider>
                </AppStore>
            </Web3ReactProvider>
        </QueryClientProvider>
    );
};
