import {useQuery, useQueryClient, UseQueryResult} from "react-query";
import {httpClient} from "../../api/axios";
import {SuccessStory} from "../../types/successStories";

export const useGetAllSuccessStoriesQuery = (): UseQueryResult<SuccessStory[], Error> => useQuery<SuccessStory[], Error>(
    'success-stories',
    async () => {
        const response = await httpClient.get('/success-stories');
        return response.data;
    },
);

export const useGetSingleSuccessStoryQuery = (successStoryId: string | undefined): UseQueryResult<SuccessStory, Error> => {
    const queryClient = useQueryClient();

    return useQuery<SuccessStory, Error>(
        ['success-stories', successStoryId],
        async () => {
            if (successStoryId) {
                const response = await httpClient.get(`/success-stories/${successStoryId}`);
                return response.data;
            }
        },
        {
            placeholderData: (): SuccessStory | undefined => queryClient
                .getQueryData<SuccessStory[]>('success-stories')
                ?.find((c: SuccessStory) => c.id === successStoryId),
        },
    );
};