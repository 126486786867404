import {useQuery, useQueryClient, UseQueryResult} from "react-query";
import {httpClient} from "../../api/axios";
import {Testimonial} from "../../types/testimonials";
import {Company} from "../../types/companies";

export const useGetAllTestimonialsQuery = (): UseQueryResult<Testimonial[], Error> => useQuery<Testimonial[], Error>(
    'testimonials',
    async () => {
        const response = await httpClient.get('/testimonials');
        return response.data;
    },
);

export const useGetSingleTestimonialQuery = (testimonialId: string | undefined): UseQueryResult<Testimonial, Error> => {
    const queryClient = useQueryClient();

    return useQuery<Company, Error>(
        ['testimonials', testimonialId],
        async () => {
            if (testimonialId) {
                const response = await httpClient.get(`/testimonials/${testimonialId}`);
                return response.data;
            }
        },
        {
            placeholderData: (): Testimonial | undefined => queryClient
                .getQueryData<Testimonial[]>('testimonials')
                ?.find((c: Testimonial) => c.id === testimonialId),
        },
    );
};