import React, { ReactElement, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PoolFormProps } from "../PoolForm/types";
import {
    Box,
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Grid,
    LinearProgress,
    Switch,
    Typography,
} from "@mui/material";
import { AppRoutes } from "../../../../routes/AppRoutes";
import { AppButton } from "../../../../components";
import { ListNames } from "../../../../types/shared";
import { usePoolForm } from "../PoolForm/hooks";
import { PoolStatus } from "../../../../types/pools";
import { DeleteButton } from "../../../DeleteButton";
import { useBlockChain } from "../BlockchainForm/hooks";
import { useWeb3React } from "@web3-react/core";
import { dummyReactSyntheticEvent, PoolPageTabs } from "../../../../constants";

export const PoolManageForm: React.FC<PoolFormProps> = (props: PoolFormProps): ReactElement => {
    const { account } = useWeb3React();
    const navigate = useNavigate();
    const [blockchainModelId, setBlockchainModelId] = React.useState<string>();
    const { getPool_bch, closePool_bch } = useBlockChain();
    const { toggleVisibility } = usePoolForm();
    const [isRender, setIsRender] = React.useState(false);
    const [openDelete, setOpenDelete] = React.useState(false);
    const [openPoolClosure, setPoolClosed] = React.useState(false);
    const [openMetamask, setOpenMetamask] = React.useState(false);

    const poolId = props.model?.id;
    const isVisible = props.model?.isVisible;

    const handleBack = () => {
        if (props?.onTabChange) {
            props.onTabChange(dummyReactSyntheticEvent, PoolPageTabs.Blockchain);
        } else {
            navigate(`${AppRoutes.POOLS}`);
        }
    };
    useEffect(() => {
        if (props.model?.id) {
            getPool_bch(props.model?.id as string)
                .then((pool) => {
                    setBlockchainModelId(pool.poolId);
                    setIsRender(true);
                })
                .catch(() => {
                    setIsRender(true);
                });
        }
    }, [props.model?.id, getPool_bch]);

    const handleCloseDelete = () => {
        setOpenDelete(false);
    };

    const handlePoolClosureExit = () => {
        setPoolClosed(false);
    };

    const closeCurrentPool = async () => {
        console.log("signer", account);
        if (account == null) {
            await window.ethereum.request({ method: "eth_requestAccounts" });
            setPoolClosed(false);
            setOpenMetamask(true);
            throw Error("Account is null");
        }
        await closePool_bch(blockchainModelId as string);
        setPoolClosed(false);
    };

    const handleCloseMetamask = () => {
        setOpenMetamask(false);
    };
    const handleNext = () => {
        if (props.onTabChange) {
            props.onTabChange(dummyReactSyntheticEvent, PoolPageTabs.Funds);
        } else {
            navigate(`${AppRoutes.POOLS}`);
        }
    };

    return (
        <>
            {!isRender ? (
                <LinearProgress />
            ) : (
                <Container sx={{ pt: 2, pb: 2 }}>
                    <Grid container spacing={2} rowSpacing={2} alignItems="center" justifyContent={"end"}>
                        <Grid item xs={12}>
                            <Typography variant="h6" gutterBottom>
                                Manage settings
                            </Typography>
                        </Grid>
                        <Dialog
                            open={openMetamask}
                            onClose={handleCloseMetamask}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"You are not connected to Metamask!"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    For sending data to blockchain you have to connect to Metamask.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseMetamask}>OK</Button>
                            </DialogActions>
                        </Dialog>
                        <Grid item xs={6}>
                            <Typography gutterBottom>Make Pool Info Public</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Box display="flex" justifyContent="flex-end">
                                <Switch
                                    id="draft"
                                    checked={isVisible}
                                    onChange={() => {
                                        if (props.model) {
                                            toggleVisibility(props.model.id as string);
                                        }
                                    }}
                                    inputProps={{ "aria-label": "controlled" }}
                                />
                            </Box>
                        </Grid>
                        {props.model?.id && (
                            <>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography gutterBottom>Delete pool</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box display="flex" justifyContent="flex-end">
                                        <DeleteButton
                                            id={poolId as string}
                                            item={props.model}
                                            listName={ListNames.Pools}
                                            isRedirect
                                        />
                                        <Dialog
                                            open={openDelete}
                                            onClose={handleCloseDelete}
                                            aria-labelledby="alert-dialog-title"
                                            aria-describedby="alert-dialog-description"
                                        >
                                            <DialogTitle id="alert-dialog-title">
                                                {"Do you want to delete the pool?"}
                                            </DialogTitle>
                                            <DialogContent>
                                                <DialogContentText id="alert-dialog-description">
                                                    This operation will delete pool all data.
                                                </DialogContentText>
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={handleCloseDelete}>Disagree</Button>
                                                <Button onClick={handleCloseDelete} autoFocus>
                                                    Agree
                                                </Button>
                                            </DialogActions>
                                        </Dialog>
                                    </Box>
                                </Grid>
                            </>
                        )}
                        <>
                            <Grid item xs={6}>
                                <Typography gutterBottom>Close pool</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Box display="flex" justifyContent="flex-end">
                                    <AppButton
                                        variant="text"
                                        disabled={props.model?.status !== PoolStatus.Active}
                                        onClick={() => {
                                            setPoolClosed(true);
                                        }}
                                    >
                                        Close
                                    </AppButton>
                                    <Dialog
                                        open={openPoolClosure}
                                        onClose={handlePoolClosureExit}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >
                                        <DialogTitle id="alert-dialog-title">
                                            {"Are you sure you want to terminate the pool?"}
                                        </DialogTitle>
                                        <DialogContent>
                                            <DialogContentText id="alert-dialog-description">
                                                This operation will close the pool for investments.
                                            </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={handlePoolClosureExit}>Disagree</Button>
                                            <Button onClick={closeCurrentPool} autoFocus>
                                                Agree
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                </Box>
                            </Grid>
                        </>
                        <Grid width={"100%"}>
                            <Box sx={{ display: "flex", flexDirection: "row", pt: 10 }}>
                                <AppButton color="inherit" onClick={handleBack} sx={{ mr: 1 }}>
                                    Back
                                </AppButton>
                                <Box sx={{ flex: "1 1 auto" }} />
                                <AppButton type="button" onClick={handleNext} variant="contained">
                                    Next
                                </AppButton>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            )}
        </>
    );
};
