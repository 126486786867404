import {Card, CardActions, CardContent, CardHeader, Grid} from '@mui/material';
import {AppButton, AppForm, AppLink} from '../../components';
import React from "react";

const AboutView = () => {
    return (
        <AppForm>
            <Grid container
                  direction="row"
                  justifyContent="center"
                  alignItems="center">
                <Card sx={{minWidth:"100%"}}>
                    <CardHeader title="Helios Admin" subheader="Version 0.1"/>
                    <CardContent>Helios Admin Details</CardContent>
                    <CardActions>
                        <AppLink to="/">
                            <AppButton color="primary">OK</AppButton>
                        </AppLink>
                    </CardActions>
                </Card>
            </Grid>
        </AppForm>
    );
};

export default AboutView;
