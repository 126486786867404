import React  from "react";

import { TextField } from "@mui/material";
import { TextFieldProps } from "@mui/material/TextField/TextField";

export const AppTextField: React.FC<TextFieldProps> = ({
                                                              InputLabelProps = {},
                                                              ...props
                                                          }) => {
    return (
        <TextField
            InputLabelProps={{ ...InputLabelProps, shrink: true }}
            variant="outlined"
            fullWidth
            size="small"
            {...props}
        />
    );
};
