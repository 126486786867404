import React from "react";
import {Container, Divider, Paper, Typography,} from "@mui/material";
import {AppRoutes} from "../../../routes/AppRoutes";
import { AppCreateEditForm } from "../../../components/AppCreateEditForm";
import AppFormHeader from "../../../components/AppFormHeader";
import {useHeaderStyles} from "../../../utils/style";
import SuccessStoryForm from "../Forms/SuccessStoryForm/SuccessStoryForm";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";

function CreateSuccessStory() {
    const classes = useHeaderStyles();
    return (
        <AppCreateEditForm>
            <AppFormHeader level1Route={AppRoutes.SUCCESS_STORIES}
                           level1Caption='Success Stories'
                           level1Icon={AutoStoriesIcon}
                           level2Caption='Create Success Story'
                           actionButton={null}/>
            <Paper className={classes.paper}>
                <Container sx={{pt: 3, pb: 2}}>
                    <Typography variant="h5" gutterBottom>
                        Create Success Story
                    </Typography>
                </Container>
                <Divider/>
                <SuccessStoryForm/>
            </Paper>
        </AppCreateEditForm>
    );
}

export default CreateSuccessStory;
