import * as yup from "yup";
import {FormProps} from "../../../../utils/FormProps";
import {FormikHelpers} from "formik";
import {PlatformImpact} from "../../../../types/platformImpact";

export interface PlatformImpactFormValues {
    emissionsAverted?: number;
    totalSolarFunded?: number;
    projectsFunded?: number;
    returnsGenerated?: number;
}

export const PlatformImpactValidationSchema = yup.object().shape({
    emissionsAverted: yup.number().moreThan(-1),
    totalSolarFunded: yup.number().moreThan(-1),
    projectsFunded: yup.number().moreThan(-1),
    returnsGenerated: yup.number().moreThan(-1),
});

export type PlatformImpactFormProps = FormProps<PlatformImpact>;

export interface UsePlatformImpactForm {
    update: (values: PlatformImpactFormValues, actions: FormikHelpers<PlatformImpactFormValues>, model: PlatformImpact) => void
}
