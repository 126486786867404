import { useMutation, UseMutationResult, useQueryClient } from "react-query";
import { httpClient } from "../../api/axios";
import { Pool, PoolImage, PoolStatus } from "../../types/pools";
import {
    CreatePoolImageMutationVariables,
    CreatePoolMutationVariables,
    CreatePoolProjectMutationVariables, DeletePoolMutationVariables,
    DeletePoolProjectMutationVariables, PoolProjectRequest, PoolVisibilityMutationVariables,
    UpdatePoolMutationVariables, UpdatePoolStatusMutationVariables
} from "./types";
import axios, { AxiosResponse } from "axios";
import { AppRoutes } from "../../routes/AppRoutes";
import { useNavigate } from "react-router-dom";

export const useCreatePoolMutation = (): UseMutationResult<AxiosResponse, Error, CreatePoolMutationVariables> => {
    const queryClient = useQueryClient();
    const navigate=useNavigate();

    return useMutation<AxiosResponse, Error, CreatePoolMutationVariables>(
        async ({ request }) => {
            const response = await httpClient.post("/pools", request);
            const poolId = response.headers.location?.split("/")[1] || "";
            if (poolId) {
                if (request?.image?.file !== undefined) {
                    const formData = new FormData();
                    formData.append("file", request.image.file as Blob);
                    await httpClient.post(`/pools/${poolId}/images`, formData, { headers: { "Content-Type": "multipart/form-data" } });
                }
            }
            navigate(`${AppRoutes.POOLS}/${poolId}/edit`);
            return response.data
        },
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries("pools");
            }
        }
    );
};

export const useUpdatePoolMutation = (): UseMutationResult<Pool, Error, UpdatePoolMutationVariables> => {
    const queryClient = useQueryClient();

    return useMutation<Pool, Error, UpdatePoolMutationVariables>(
        async ({ request, poolId }) => {
            return await httpClient.put(`/pools/${poolId}`, request);
        },
        {
            onSuccess: async (data, variables) => {
                await queryClient.invalidateQueries(["pools", variables.poolId]);
            }
        }
    );
};

export const usePoolVisibilityMutation = (): UseMutationResult<Pool, Error, PoolVisibilityMutationVariables> => {
    const queryClient = useQueryClient();

    return useMutation<Pool, Error, PoolVisibilityMutationVariables>(
        async ({ poolId }) => {
            return await httpClient.put(`/pools/${poolId}/visibility`);
        },
        {
            onSuccess: async (data, variables) => {
                await queryClient.invalidateQueries(["pools", variables.poolId]);
            }
        }
    );
};

export const useUpdateStatusMutation = (): UseMutationResult<Pool, Error, UpdatePoolStatusMutationVariables> => {
    const queryClient = useQueryClient();

    return useMutation<Pool, Error, UpdatePoolStatusMutationVariables>(
        async ({ poolId, status }) => {
            switch (status) {
                case PoolStatus.Active:
                    return await httpClient.put(`/pools/${poolId}/activate`);
                case PoolStatus.Closed:
                    return await httpClient.put(`/pools/${poolId}/close`);
                default:
                    return await httpClient.put(`/pools/${poolId}/draft`);
            }
        },
        {
            onSuccess: async (data, variables) => {
                await queryClient.invalidateQueries(["pools", variables.poolId]);
            }
        }
    );
};

export const useDeletePoolMutation = (): UseMutationResult<AxiosResponse, Error, DeletePoolMutationVariables> => {
    const queryClient = useQueryClient();

    return useMutation<AxiosResponse, Error, DeletePoolMutationVariables>(
        async ({ poolId }) => {
            return await httpClient.delete(`/pools/${poolId}`);
        },
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries("pools");
            }
        }
    );
};

export const useCreatePoolImageMutation = (): UseMutationResult<PoolImage, Error, CreatePoolImageMutationVariables> => {
    const queryClient = useQueryClient();

    return useMutation<PoolImage, Error, CreatePoolImageMutationVariables>(
        async ({ poolId, request }) => {
            const formData = new FormData();
            if (request?.file !== undefined) {
                formData.append("file", request?.file as Blob);
            }

            const response = await httpClient.post(`/pools/${poolId}/images`, formData, { headers: { "Content-Type": "multipart/form-data" } });
            return response.data;
        },
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries("pool-images");
            }
        }
    );
};

export const useCreatePoolProjectMutation = (): UseMutationResult<void, Error, CreatePoolProjectMutationVariables> => {
    const queryClient = useQueryClient();

    return useMutation<void, Error, CreatePoolProjectMutationVariables>(
        async ({ requests }) => {
            const httpRequests = requests.map((request: PoolProjectRequest) => {
                const { poolId, projectId } = request;
                return httpClient.post(`/pools/${poolId}/projects/${projectId}`);
            });

            await axios.all(httpRequests);
        },

        {
            onSuccess: async () => {
                await queryClient.invalidateQueries("pool-projects");
            }
        }
    );
};


export const useDeletePoolProjectMutation = (): UseMutationResult<void, Error, DeletePoolProjectMutationVariables> => {
    const queryClient = useQueryClient();

    return useMutation<void, Error, DeletePoolProjectMutationVariables>(
        async ({ requests }) => {
            const httpRequests = requests.map((request: PoolProjectRequest) => {
                const { poolId, projectId } = request;
                return httpClient.delete(`/pools/${poolId}/projects/${projectId}`);
            });

            await axios.all(httpRequests);
        },

        {
            onSuccess: async () => {
                await queryClient.invalidateQueries("pool-projects");
            }
        }
    );
};
