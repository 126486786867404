import postAuthLoginMutator from "../../api/axios";
import {AccessTokenResponse, CredentialsLoginRequest} from "./types";

export const postAuthLogin = (
    credentialsLoginRequest: CredentialsLoginRequest,
) => {
    return postAuthLoginMutator<AccessTokenResponse>(
        {
            url: `/auth/login`, method: 'post',
            headers: {'Content-Type': 'application/json',},
            data: credentialsLoginRequest
        },
    );
}