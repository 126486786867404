import { useAppStore } from "../store";
import PublicRoutes from "./PublicRoutes";
import PrivateRoutes from "./PrivateRoutes";
import { useEffect } from "react";

const Routes = () => {
    const [state, ] = useAppStore();

    useEffect(() => {
        // Check isn't token expired?
        // const isLogged = isUserStillLoggedIn();

        // if (state.isAuthenticated && !isLogged) {
        //   // Token was expired, logout immediately!
        //   console.warn('Token was expired, logout immediately!');
        //   api?.auth?.logout();
        //   // dispatch({ type: LOG_OUT_ACTION }); // Not needed due to reloading App in api.auth.logout()
        //   return; // That's all for now, the App will be completely re-rendered soon
        // }

        // if (isLogged && !state.isAuthenticated) {
        //   // Valid token is present, but we are not logged in somehow, lets fix it
        //   console.warn('Token found, lets try to auto login');
        //   api?.auth?.refresh().then(() => {
        //     dispatch({ type: LOG_IN_ACTION }); // Update global store only if token refresh was successful.
        //   });
        // }
    }, []); // Effect for every state.isAuthenticated change actually

    return state.isAuthenticated ? <PrivateRoutes /> : <PublicRoutes />;
};
export default Routes;
