import {useMutation, UseMutationResult, useQueryClient} from 'react-query';
import {httpClient} from "../../api/axios";
import {
    CreateTestimonialImageMutationVariables,
    CreateTestimonialMutationVariables,
    UpdateTestimonialMutationVariables
} from "./types";
import {Testimonial, TestimonialImage} from "../../types/testimonials";

export const useCreateTestimonialMutation = (): UseMutationResult<Testimonial, Error, CreateTestimonialMutationVariables> => {
    const queryClient = useQueryClient();

    return useMutation<Testimonial, Error, CreateTestimonialMutationVariables>(
        async ({request}) => {
            const response = await httpClient.post('/testimonials', request);
            return response.data;
        },
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries('testimonial');
            },
        },
    );
};

export const useUpdateTestimonialMutation = (): UseMutationResult<Testimonial, Error, UpdateTestimonialMutationVariables> => {
    const queryClient = useQueryClient();

    return useMutation<Testimonial, Error, UpdateTestimonialMutationVariables>(
        async ({request, testimonialId}) => {
            const response = await httpClient.put(`/testimonials/${testimonialId}`, request);
            return response.data;
        },
        {
            onSuccess: async (data, variables) => {
                await queryClient.invalidateQueries(['testimonial', variables.testimonialId]);
            },
        },
    );
};

export const useCreateTestimonialImageMutation = (): UseMutationResult<TestimonialImage, Error, CreateTestimonialImageMutationVariables> => {
    const queryClient = useQueryClient();

    return useMutation<TestimonialImage, Error, CreateTestimonialImageMutationVariables>(
        async ({testimonialId, request}) => {
            const formData = new FormData();
            if (request?.file !== undefined) {
                formData.append('file', request?.file as Blob);
            }

            const response = await httpClient.post(`/testimonials/${testimonialId}/images`, formData, {headers: {'Content-Type': 'multipart/form-data'}});
            return response.data;
        },
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries('testimonial-images');
            },
        },
    );
};
