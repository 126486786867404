export enum whiteListedTokens {
    stablecoin = "USDC",
    HUSD = "HUSD",
}

export enum SystemIDs {
    BlendedPoolID = "blended-pool",
}

export const dummyReactSyntheticEvent = {} as React.SyntheticEvent;

export enum PoolPageTabs {
    Overview = "overview",
    Blockchain = "blockchain",
    Funds = "funds",
    Manage = "manage",
    Investments = "investments",
    Investors = "investors",
}

export const ETHEREUM_ADDRESS_REGEX = /^0x[a-fA-F0-9]{40}$/;
