import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { PoolFormProps, PoolFormValues, PoolImageFormValues, PoolProjectFormValues } from "./types";
import { FieldArray, Form, Formik, FormikHelpers, FormikProps } from "formik";
import {
    Autocomplete,
    Box,
    Card,
    CardMedia,
    Container,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
    Alert,
} from "@mui/material";
import { AppNumberField, AppTextField } from "../../../../components/AppFields";
import { AppButton } from "../../../../components";
import { AppRoutes } from "../../../../routes/AppRoutes";
import { useNavigate } from "react-router-dom";
import { usePoolForm, usePoolImageForm, usePoolProjectForm } from "./hooks";
import { PoolProject, PoolRegion, PoolStatus } from "../../../../types/pools";
import AddIcon from "@mui/icons-material/Add";
import AppIconButton from "../../../../components/AppIconButton";
import { Project } from "../../../../types/projects";
import { useGetAllAvailableProjectsQuery } from "../../../../queries/projects";
import { ProjectFormValues } from "../../../Projects/Forms/ProjectForm/types";
import { PoolProjectRequest } from "../../../../queries/pools/types";
import { BlendedPoolValidationSchema, RegionalPoolValidationSchema } from "./validation";
import { isEmpty } from "lodash";
import { Editor } from "@tinymce/tinymce-react";
import { dummyReactSyntheticEvent, PoolPageTabs, SystemIDs } from "../../../../constants";

const addDeleteChange = (initial: Array<PoolProject>, modified: Array<PoolProject>) => {
    let deleted = initial.filter((x) => !modified.includes(x));
    let added = modified.filter((x) => !initial.includes(x));
    return { added: added, deleted: deleted };
};

export const PoolForm: React.FC<PoolFormProps> = ({ model = {}, onTabChange }: PoolFormProps): ReactElement => {
    const navigate = useNavigate();
    const { create, update } = usePoolForm();
    const { create: createImg } = usePoolImageForm();
    const { create: createProject, deletePoolProjects } = usePoolProjectForm();
    const { data: projects, isLoading: isProjectsLoading } = useGetAllAvailableProjectsQuery();

    const [render, setRender] = useState(false);
    const [selected, setSelected] = useState<Project>();
    const [isValidate, setIsValidate] = useState(false);
    const [alertIsOpen, setAlertIsOpen] = useState(false);

    const poolId = model.id || "";

    const poolImage = model.images?.length ? model.images[0] : undefined;

    const handleFinish = useCallback(() => {
        navigate(`${AppRoutes.POOLS}`);
    }, [navigate]);

    useEffect(() => {
        if (!isProjectsLoading) {
            setRender(true);
        }
    }, [isProjectsLoading]);

    if (poolId === SystemIDs.BlendedPoolID) {
        return (
            <Typography variant="h6" gutterBottom>
                Blended Pool Edit functionality in development
            </Typography>
        );
    }

    return (
        <>
            {render && (
                <Formik
                    enableReinitialize
                    validateOnChange={isValidate}
                    validateOnBlur={isValidate}
                    initialValues={{
                        name: model?.name || "",
                        status: model?.status || PoolStatus.Draft,
                        overview: model?.overview || "",
                        apy: model?.apy || 0,
                        description: model?.description || "",
                        descriptionTitle: model?.descriptionTitle || "",
                        annualEmissionsAverted: model?.annualEmissionsAverted || 0,
                        annualSolarEnergyProduced: model?.annualSolarEnergyProduced || 0,
                        region: model?.region || PoolRegion.None,
                        image: poolImage
                            ? {
                                  poolId,
                                  url: poolImage?.urls ? poolImage?.urls["original"] : "",
                              }
                            : { poolId },
                        projects: model?.projects as ProjectFormValues[] | [],
                    }}
                    validationSchema={
                        poolId === SystemIDs.BlendedPoolID ? BlendedPoolValidationSchema : RegionalPoolValidationSchema
                    }
                    onSubmit={async (values: PoolFormValues, actions: FormikHelpers<PoolFormValues>) => {
                        const { added, deleted } = addDeleteChange(model?.projects || [], values?.projects || []);
                        if (poolId) {
                            await update(values, poolId);
                            setAlertIsOpen(true);
                            setTimeout(() => {
                                setAlertIsOpen(false);
                            }, 3000);
                        } else {
                            await create(values, actions);
                        }
                        if (values.image?.file && poolId) {
                            await createImg(
                                values.image as PoolImageFormValues,
                                actions as FormikHelpers<PoolImageFormValues>
                            );
                        }
                        setAlertIsOpen(true);
                        setTimeout(() => {
                            setAlertIsOpen(false);
                        }, 2000);

                        if (deleted && poolId) {
                            let poolProjectList = new Array<PoolProjectRequest>();

                            deleted.forEach(function (item) {
                                const poolProject: PoolProjectRequest = {
                                    poolId,
                                    projectId: item.id,
                                };

                                poolProjectList.push(poolProject);
                            });
                            deletePoolProjects(
                                { requests: poolProjectList },
                                actions as FormikHelpers<PoolProjectFormValues>
                            );
                        }

                        if (added && poolId) {
                            let poolProjectList = new Array<PoolProjectRequest>();

                            added.forEach(function (item) {
                                const poolProject: PoolProjectRequest = {
                                    poolId: poolId,
                                    projectId: item.id,
                                };

                                poolProjectList.push(poolProject);
                            });

                            createProject(
                                { requests: poolProjectList },
                                actions as FormikHelpers<PoolProjectFormValues>
                            );
                        }
                    }}
                >
                    {(formProps: FormikProps<PoolFormValues>) => {
                        const {
                            values,
                            errors,
                            status,
                            setStatus,
                            handleChange,
                            setFieldValue,
                            isSubmitting,
                            dirty,
                            validateForm,
                            submitForm,
                        } = formProps;

                        const handleFileChange = (event: React.FormEvent<HTMLInputElement>) => {
                            if (event.currentTarget.files?.length) {
                                const fileBlob = event.currentTarget.files[0];
                                const imageFile = URL.createObjectURL(fileBlob);
                                setFieldValue("image", { ...values.image, file: fileBlob, url: imageFile });
                            }
                        };

                        const handleSubmit = () => {
                            if (!isSubmitting) {
                                validateForm(values).then(async (invalidValues) => {
                                    if (isEmpty(invalidValues)) {
                                        await submitForm();
                                    }
                                    setIsValidate(true);
                                });
                            }
                        };

                        const redirect = () => {
                            if (!isSubmitting) {
                                if (onTabChange) {
                                    onTabChange(dummyReactSyntheticEvent, PoolPageTabs.Blockchain);
                                } else {
                                    navigate(`${AppRoutes.POOLS}`);
                                }
                            }
                        };

                        return (
                            <Form>
                                <Container sx={{ pt: 3, pb: 3 }}>
                                    <Grid rowSpacing={4} container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                        <Grid item xs={12}>
                                            <Typography variant="h6" gutterBottom>
                                                Pool Info
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <AppTextField
                                                id="name"
                                                label="Name"
                                                required
                                                error={!!errors.name}
                                                helperText={errors.name}
                                                value={values.name}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <AppTextField
                                                id="overview"
                                                label="Overview"
                                                error={!!errors.overview}
                                                helperText={errors.overview}
                                                value={values.overview}
                                                onChange={handleChange}
                                                inputProps={{
                                                    maxLength: 160,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <AppTextField
                                                id="descriptionTitle"
                                                label="Description Title"
                                                error={!!errors.descriptionTitle}
                                                helperText={errors.descriptionTitle}
                                                value={values.descriptionTitle}
                                                onChange={handleChange}
                                            />
                                        </Grid>

                                        {/* For Regional Pools only */}
                                        {poolId !== SystemIDs.BlendedPoolID && (
                                            <>
                                                <Grid item xs={12} sm={6}>
                                                    <FormControl fullWidth>
                                                        <InputLabel size="small" id="region-label">
                                                            Region
                                                        </InputLabel>
                                                        <Select
                                                            id="region"
                                                            label="region"
                                                            labelId="region-label"
                                                            name="region"
                                                            size="small"
                                                            error={!!errors.region}
                                                            value={values.region}
                                                            onChange={handleChange}
                                                        >
                                                            <MenuItem key={PoolRegion.None} value={PoolRegion.None}>
                                                                -
                                                            </MenuItem>
                                                            <MenuItem key={PoolRegion.Africa} value={PoolRegion.Africa}>
                                                                {PoolRegion.Africa}
                                                            </MenuItem>
                                                            <MenuItem
                                                                key={PoolRegion.Americas}
                                                                value={PoolRegion.Americas}
                                                            >
                                                                {PoolRegion.Americas}
                                                            </MenuItem>
                                                            <MenuItem key={PoolRegion.Asia} value={PoolRegion.Asia}>
                                                                {PoolRegion.Asia}
                                                            </MenuItem>
                                                            <MenuItem
                                                                key={PoolRegion.Antarctic}
                                                                value={PoolRegion.Antarctic}
                                                            >
                                                                {PoolRegion.Antarctic}
                                                            </MenuItem>
                                                            <MenuItem key={PoolRegion.Europe} value={PoolRegion.Europe}>
                                                                {PoolRegion.Europe}
                                                            </MenuItem>
                                                            <MenuItem
                                                                key={PoolRegion.Oceania}
                                                                value={PoolRegion.Oceania}
                                                            >
                                                                {PoolRegion.Oceania}
                                                            </MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <AppNumberField
                                                        id="annualEmissionsAverted"
                                                        label="Annual Emissions Averted"
                                                        value={values.annualEmissionsAverted}
                                                        error={!!errors.annualEmissionsAverted}
                                                        helperText={errors.annualEmissionsAverted}
                                                        setValue={setFieldValue}
                                                        suffix=" kg"
                                                    />
                                                </Grid>
                                            </>
                                        )}
                                        <Grid item xs={12} sm={6}>
                                            <AppNumberField
                                                id="apy"
                                                label="Apy"
                                                value={values.apy}
                                                error={!!errors.apy}
                                                helperText={errors.apy}
                                                setValue={setFieldValue}
                                                suffix=" %"
                                            />
                                        </Grid>

                                        {/* For Regional Pools only */}
                                        {poolId !== SystemIDs.BlendedPoolID && (
                                            <Grid item xs={12} sm={12}>
                                                <AppNumberField
                                                    id="annualSolarEnergyProduced"
                                                    label="Annual Solar Energy Produced"
                                                    value={values.annualSolarEnergyProduced}
                                                    error={!!errors.annualSolarEnergyProduced}
                                                    helperText={errors.annualSolarEnergyProduced}
                                                    setValue={setFieldValue}
                                                    suffix=" kWh"
                                                />
                                            </Grid>
                                        )}
                                        <Grid item xs={12}>
                                            <Typography variant="h6" gutterBottom>
                                                Description
                                            </Typography>
                                            <Editor
                                                apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                                                init={{
                                                    plugins:
                                                        "anchor autolink image link lists media visualblocks wordcount linkchecker",
                                                    menubar: false,
                                                    content_css: "dark",
                                                    skin: "oxide-dark",
                                                    toolbar:
                                                        "undo redo | fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent",
                                                }}
                                                value={values.description?.toString()}
                                                onEditorChange={(content) => {
                                                    setFieldValue("description", content);
                                                }}
                                                id={"description"}
                                            />
                                        </Grid>
                                    </Grid>
                                </Container>
                                <Divider />
                                <Container sx={{ pt: 3, pb: 3 }}>
                                    <Grid rowSpacing={4} container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                        <Grid item xs={12}>
                                            <Typography variant="h6" gutterBottom>
                                                Pool Image
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Card variant="outlined">
                                                <CardMedia
                                                    component="img"
                                                    image={values.image ? values.image.url : ""}
                                                />
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <AppButton variant="contained" component="label">
                                                <AddIcon /> Select file
                                                <input
                                                    type="file"
                                                    name="file"
                                                    id="file"
                                                    hidden
                                                    onChange={handleFileChange}
                                                />
                                            </AppButton>
                                        </Grid>
                                    </Grid>
                                </Container>
                                <Divider />
                                {status && (
                                    <Alert
                                        onClose={() => {
                                            setStatus("");
                                        }}
                                        severity="error"
                                    >
                                        {status}
                                    </Alert>
                                )}
                                <Container sx={{ pt: 3, pb: 3 }}>
                                    <Grid rowSpacing={4} container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                        <Grid item xs={12}>
                                            <Typography variant="h6" gutterBottom>
                                                Project Assignment
                                            </Typography>
                                        </Grid>

                                        <FieldArray name="projects">
                                            {({ push, remove }) => (
                                                <React.Fragment>
                                                    {values.projects?.length ? (
                                                        values.projects?.map((project, index) => (
                                                            <Grid
                                                                container
                                                                item
                                                                spacing={2}
                                                                key={`pool-projects-${index}`}
                                                            >
                                                                <Grid item xs={4}>
                                                                    <TextField
                                                                        fullWidth
                                                                        id={`projects[${index}].name`}
                                                                        name={`projects[${index}].name`}
                                                                        value={project.name}
                                                                        disabled
                                                                        size="small"
                                                                    ></TextField>
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    <TextField
                                                                        fullWidth
                                                                        id={`projects[${index}].overview`}
                                                                        name={`projects[${index}].overview`}
                                                                        value={project.overview}
                                                                        disabled
                                                                        size="small"
                                                                    ></TextField>
                                                                </Grid>
                                                                <Grid item xs={1}>
                                                                    <AppIconButton
                                                                        icon="delete"
                                                                        onClick={() => remove(index)}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        ))
                                                    ) : (
                                                        <Grid container item spacing={2}>
                                                            <Grid item>You haven't added any project</Grid>
                                                        </Grid>
                                                    )}
                                                    <Grid container item spacing={2} alignItems="flex-start">
                                                        <Grid item>
                                                            <Autocomplete
                                                                disablePortal
                                                                size="small"
                                                                id="combo-box-projects"
                                                                isOptionEqualToValue={(option, value) =>
                                                                    option.name === value.name
                                                                }
                                                                options={projects || []}
                                                                sx={{ mt: 0.9, minWidth: 200 }}
                                                                filterSelectedOptions
                                                                onChange={(e, value) => {
                                                                    setSelected(value as Project);
                                                                }}
                                                                getOptionLabel={({ name }) => {
                                                                    return `${name}`;
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField {...params} label="Projects" />
                                                                )}
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <AppButton
                                                                size="medium"
                                                                disabled={!selected?.id}
                                                                onClick={() => {
                                                                    if (
                                                                        !values.projects?.find(
                                                                            (project) => project.id === selected?.id
                                                                        )
                                                                    )
                                                                        push({
                                                                            id: selected?.id,
                                                                            name: selected?.name,
                                                                            overview: selected?.overview,
                                                                        });
                                                                    setSelected(undefined);
                                                                }}
                                                            >
                                                                Add Project
                                                            </AppButton>
                                                        </Grid>
                                                    </Grid>
                                                </React.Fragment>
                                            )}
                                        </FieldArray>
                                    </Grid>
                                </Container>
                                <Divider />
                                <Container sx={{ pt: 2, pb: 2 }}>
                                    <Grid container spacing={2} rowSpacing={2}>
                                        <Grid item xs={12}>
                                            <Box display="flex" justifyContent="space-between">
                                                {alertIsOpen && (
                                                    <Alert severity="success">Pool data is successfully saved!</Alert>
                                                )}
                                                <Box display="flex" flex={1} justifyContent="flex-end">
                                                    <AppButton variant="outlined" onClick={handleFinish}>
                                                        Cancel
                                                    </AppButton>
                                                    <AppButton
                                                        // loading={isSubmitting}
                                                        onClick={dirty || !model.id ? handleSubmit : redirect}
                                                        // loadingIndicator={<CircularProgress color="inherit" size={16} />}
                                                        variant="contained"
                                                        disabled={isSubmitting}
                                                    >
                                                        {!dirty && model.id ? "Next" : "Save"}
                                                    </AppButton>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Container>
                            </Form>
                        );
                    }}
                </Formik>
            )}
        </>
    );
};
