import {FormikHelpers} from "formik";
import {PlatformImpactFormValues, UsePlatformImpactForm} from "./types";
import {PlatformImpact} from "../../../../types/platformImpact";
import {useUpdatePlatformImpactMutation} from "../../../../queries/platform/mutations";
import {UpdatePlatformImpactRequest} from "../../../../queries/platform/types";

const usePlatformImpactForm = (): UsePlatformImpactForm => {
    const updateMutation = useUpdatePlatformImpactMutation();

    const update = async (values: PlatformImpactFormValues, actions: FormikHelpers<PlatformImpactFormValues>, model: PlatformImpact) => {
        try {
            const request: UpdatePlatformImpactRequest = values;
            await updateMutation.mutateAsync(
                {request},
                {
                    onSuccess: async () => {
                    },
                },
            );
        } catch (error) {
        }
    };

    return {
        update
    };
};

export default usePlatformImpactForm;