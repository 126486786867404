import { Theme } from '@mui/material/styles';
import makeStyles from "@mui/styles/makeStyles";

export type ColorName =
  | 'default' // MUI 5.x removes 'default' form Button, we need to fix this
  | 'primary'
  | 'secondary'
  | 'error' // Missing in MUI 4.x
  | 'warning' // Missing in MUI 4.x
  | 'info' // Missing in MUI 4.x
  | 'success' // Missing in MUI 4.x
  | 'false' // Missing in MUI 5.x
  | 'true'; // Missing in MUI 5.x

export const paperStyle = (theme: Theme) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
});

export const formStyle = () => ({
  width: '100%',
  maxWidth: '40rem',
});

export const editFormStyle = () => ({
  width: '100%',
  maxWidth: '68rem',
  marginBottom: '2rem',
});

export const panelTabStyle = (theme: Theme) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  marginLeft: theme.spacing(0),
  marginRight: theme.spacing(0)
});

export const useHeaderStyles = makeStyles((theme: Theme) => ({
  panelHeader: {
    ...componentHeaderStyle(theme),
  },
  paper: {
    ...paperStyle(theme),
  },
  panelTab: {
    ...panelTabStyle(theme)
  }
}));

export const dialogStyles = (
  theme: Theme
): { xButton: any; paper: any; formControl: any; content: any; actions: any } => ({
  xButton: {
    position: 'absolute',
    right: theme.spacing(0.5),
    top: theme.spacing(0.5),
  },
  paper: {
    [theme.breakpoints.up('md')]: {
      minWidth: theme.breakpoints.values.md / 2,
    },
    [theme.breakpoints.down('md')]: {
      minWidth: theme.breakpoints.values.sm / 2,
    },
  },
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  content: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  actions: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
});

export const componentHeaderStyle = (theme: Theme) => ({
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'space-between',
    minHeight: "3rem",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
});

