import * as yup from "yup";
import {FormProps} from "../../../../utils/FormProps";
import {FormikHelpers} from "formik";
import {TeamMember} from "../../../../types/team";

export interface TeamMemberFormValues {
    id?: string;
    first?: string | null;
    last?: string | null;
    description?: string | null;
    position?: string | null;
}

export const TeamMemberValidationSchema = yup.object().shape({
    first: yup.string().required("Required"),
    last: yup.string().required("Required"),
    description: yup.string().required("Required"),
});

export type TeamMemberFormProps = FormProps<TeamMember>;

export interface UseTeamMemberForm {
    create: (values: TeamMemberFormValues, actions: FormikHelpers<TeamMemberFormValues>) => void
    update: (values: TeamMemberFormValues, actions: FormikHelpers<TeamMemberFormValues>, model: TeamMember) => void
}
