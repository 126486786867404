import { Tooltip } from '@mui/material';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { ColorName } from '../../utils/style';
import AppIcon from '../AppIcon';
import React from "react";

interface Props extends Omit<IconButtonProps, 'color'> {
  color?: ColorName | 'inherit';
  icon?: string;
  // Missing props
  component?: React.ElementType; // Could be RouterLink, AppLink, etc.
  to?: string; // Link prop
  href?: string; // Link prop
}
const AppIconButton: React.FC<Props> = ({ color, className, children, disabled, icon, title, ...restOfProps }) => {
  const renderIcon = () => (
    <IconButton disabled={disabled} {...restOfProps}>
      <AppIcon icon={icon} />
      {children}
    </IconButton>
  );

  // When title is set, wrap te IconButton with Tooltip.
  // Note: when IconButton is disabled the Tooltip is not working, so we don't need it
  if (title && !disabled) {
    return <Tooltip title={title}>{renderIcon()}</Tooltip>;
  }

  return title && !disabled ? <Tooltip title={title}>{renderIcon()}</Tooltip> : renderIcon();
};

export default AppIconButton;
