import { EthersAdapter } from "@safe-global/protocol-kit";
import SafeApiKit from "@safe-global/api-kit";
import { ethers } from "ethers";

let signer = null;
let provider;
const chainId = process.env.REACT_APP_CHAIN_ID as string;

export const switchNetwork = async (id: string): Promise<void> => {
    const networkUrl = process.env.REACT_APP_CHAIN_NETWORK_URL;
    const explorerUrl = process.env.REACT_APP_TRANSACTION_EXPLORER_LINK;

    console.log(id);

    try {
        await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: `0x${parseInt(id, 10).toString(16)}` }],
        });
    } catch (switchError: any) {
        if (switchError.code === 4902 || switchError.code === -32602) {
            try {
                await window.ethereum.request({
                    method: "wallet_addEthereumChain",
                    params: [
                        {
                            chainId: `0x${parseInt(id, 10).toString(16)}`,
                            chainName: "Base",
                            nativeCurrency: { name: "ETH", symbol: "ETH", decimals: 18 },
                            rpcUrls: [networkUrl],
                            blockExplorerUrls: [explorerUrl],
                            iconUrls: ["future"],
                        },
                    ],
                });
            } catch (addError) {
                console.log("Error");
            }
        } else {
            console.log(switchError, "You can't switch your network right now");
        }
    }
};

if (window.ethereum == null) {
    // If MetaMask is not installed, we use the default provider,
    // which is backed by a variety of third-party services (such
    // as INFURA). They do not have private keys installed,
    // so they only have read-only access
    console.log("MetaMask not installed; using read-only defaults");
    provider = ethers.getDefaultProvider();
} else {
    // Connect to the MetaMask EIP-1193 object. This is a standard
    // protocol that allows Ethers access to make all read-only
    // requests through MetaMask.
    await window.ethereum.on("chainChanged", (chainId: string) => {
        console.log("Chain changed to: ", chainId);
        window.location.reload();
    });
    provider = new ethers.BrowserProvider(window.ethereum);
    console.log("MetaMask installed; using injected provider");

    // It also provides an opportunity to request access to write
    // operations, which will be performed by the private key
    // that MetaMask manages for the user.
    signer = await provider.getSigner();
}

export const ethAdapter = new EthersAdapter({
    ethers,
    signerOrProvider: signer || provider,
});

export const apiKit = new SafeApiKit({
    chainId: ethers.toBigInt(process.env.REACT_APP_CHAIN_ID as string),
});
