import React from "react";
import { Button, Grid, Typography, Box, Paper, LinearProgress, Dialog, ButtonBase } from "@mui/material";
import useInvestorList from "./useInvestorList";
import { useQuery } from "react-query";
import { AppTextField } from "../../components/AppFields";
import { ETHEREUM_ADDRESS_REGEX } from "../../constants";
import { AppButton } from "../../components";

interface InvestorRowProps {
    holder: string;
    poolId: string;
}

const InvestorRow: React.FC<InvestorRowProps> = ({ holder, poolId }) => {
    const { useInvestorsFinancialDataQuery, withdrawInvestorsYield, getInvestorsFinancialData } = useInvestorList();
    const [isWithdrawalModalOpen, setIsWithdrawalModalOpen] = React.useState(false);
    const [transactionStatus, setTransactionStatus] = React.useState<{
        status: boolean;
        message: string;
    } | null>(null);
    const { data, isLoading, error } = useInvestorsFinancialDataQuery(holder, poolId || "");

    const { refetch: refetchInvestorFinancialData } = useQuery({
        queryKey: ["investorsFinancialData", holder, poolId],
        queryFn: () => getInvestorsFinancialData(holder, poolId || ""),
        enabled: false,
    });

    const [isTransactionInProgress, setIsTransactionInProgress] = React.useState(false);

    const [beneficiaryAddress, setBeneficiaryAddress] = React.useState<string | null>(null);

    const handleWithdrawYield = async () => {
        if (beneficiaryAddress) {
            setIsTransactionInProgress(true);
            const res = await withdrawInvestorsYield(holder, beneficiaryAddress, poolId);
            setTransactionStatus(res);
            refetchInvestorFinancialData().then(() => {
                setTimeout(() => {
                    setTransactionStatus(null);
                    setIsTransactionInProgress(false);
                }, 3000);
            });
        } else {
            setTransactionStatus({
                status: false,
                message: "Beneficiary address is required",
            });
        }
    };

    if (isLoading) return <LinearProgress />;
    if (error) return <div>Error Loading Data</div>;

    return (
        <Paper
            elevation={3}
            sx={{
                p: 4,
                mb: 2,
                borderRadius: 1,
            }}
        >
            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                        <Typography variant="body1" color="text.primary">
                            Holder: {holder}
                        </Typography>

                        <Typography variant="body2" color="text.secondary">
                            Yield:{" "}
                            <Typography
                                component="span"
                                sx={{
                                    color: "success.main",
                                }}
                            >
                                {data?.yearnedYield} {data?.symbol}
                            </Typography>
                        </Typography>
                    </Box>
                </Grid>
                <Grid item>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={data?.yearnedYield === 0}
                            onClick={() => setIsWithdrawalModalOpen(true)}
                        >
                            Withdraw Yield
                        </Button>
                    </Box>
                </Grid>
            </Grid>

            <Dialog
                open={isWithdrawalModalOpen}
                onClose={() => setIsWithdrawalModalOpen(false)}
                sx={{
                    "& .MuiDialog-paper": {
                        width: "100%",
                        maxWidth: "550px",
                    },
                }}
            >
                <Box sx={{ p: 4 }}>
                    <Typography variant="h5">Withdraw Yield</Typography>
                    <Typography variant="body1">
                        Are you sure you want to withdraw{" "}
                        <Typography component="span" sx={{ color: "success.main" }}>
                            {data?.yearnedYield} {data?.symbol}
                        </Typography>
                        ?
                    </Typography>

                    {/* Input for Beneficiary Address */}
                    <Box sx={{ mt: 4 }}>
                        <AppTextField
                            placeholder="Enter Beneficiary Address"
                            value={beneficiaryAddress || ""}
                            onChange={(e) => {
                                const address = e.target.value;
                                setBeneficiaryAddress(address);
                            }}
                        />
                    </Box>

                    <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2, mt: 4 }}>
                        <AppButton
                            loading={isTransactionInProgress}
                            disabled={
                                data?.yearnedYield === 0 ||
                                !beneficiaryAddress ||
                                isTransactionInProgress ||
                                !ETHEREUM_ADDRESS_REGEX.test(beneficiaryAddress)
                            }
                            onClick={handleWithdrawYield}
                        >
                            Confirm
                        </AppButton>
                    </Box>

                    <Box
                        sx={{
                            mt: 2,
                            display: "flex",
                            flexDirection: "column",
                            gap: 2,
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Typography variant="body1" sx={{ color: "error.main" }}>
                            Note: Please make sure you have entered a valid Ethereum address
                        </Typography>

                        {/* Transaction status */}
                        {transactionStatus && (
                            <Typography
                                variant="body1"
                                sx={{
                                    color: transactionStatus.status ? "success.main" : "error.main",
                                }}
                            >
                                {transactionStatus.message}
                            </Typography>
                        )}
                    </Box>
                </Box>
            </Dialog>
        </Paper>
    );
};

export default InvestorRow;
