export const AppRoutes = {
    HOME: "/",

    POOLS: "/pools",

    BLENDED_POOL: "/blended-pool",

    PROJECTS: "/projects",

    TEAM: "/team",

    SUCCESS_STORIES: "/success-stories",

    TESTIMONIALS: "/testimonials",

    COMPANIES: "/companies",

    PLATFORM_IMPACT: "/platform-impact",

    UNDER_CONSTRUCTION: "/under-construction",
};
