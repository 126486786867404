import { useMutation, UseMutationResult, useQueryClient } from "react-query";
import { httpClient } from "../../api/axios";
import { DeleteMutationVariables } from "./types";
import { AxiosResponse } from "axios";
import { ListNames } from "../../types/shared";

export const useDeleteMutation = (name: ListNames): UseMutationResult<AxiosResponse, Error, DeleteMutationVariables> => {
    const queryClient = useQueryClient();

    return useMutation<AxiosResponse, Error, DeleteMutationVariables>(
        async ({ id }) => {
            return await httpClient.delete(`/${name}/${id}`);
        },
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries(name);
            }
        }
    );
};

