import MuiAlert, {AlertColor} from '@mui/material/Alert';
import React, {ReactElement} from "react";
import {Box} from "@mui/material";

export interface AlertProps {
    message: string,
    type: AlertColor | undefined,
}

const AppAlert: (props: AlertProps) => React.ReactElement = (props: AlertProps): ReactElement => {
    const {message, type} = props;

    return (
        <Box my={2}>
            {!!message && <MuiAlert severity={type}>{message}</MuiAlert>}
        </Box>
    );
};

export default AppAlert;
