import React from "react";

import { NumericFormat } from "react-number-format";
import { TextFieldProps } from "@mui/material/TextField/TextField";
import { AppTextField } from "./AppTextField";


type AppNumberFieldProps = TextFieldProps & {
    suffix?: string,
    prefix?: string,
    setValue?: (name: string, value: number) => void
}

export const AppNumberField: React.FC<AppNumberFieldProps> = ({
                                                                  suffix = "",
                                                                  prefix = "",
                                                                  name='',
                                                                  id='',
                                                                  value,
                                                                  setValue = () => {
                                                                  },
                                                                  ...props
                                                              }) => {

    const { required, label, error, helperText, disabled } = props;
    return <NumericFormat
        customInput={AppTextField}
        thousandSeparator
        onValueChange={({ floatValue }) => {
            setValue(name || id, floatValue as number);
        }}
        value={value as number}
        suffix={suffix}
        prefix={prefix}
        required={required}
        helperText={helperText}
        error={error}
        label={label}
        disabled={disabled}
    />;
};
