import {FormikHelpers} from "formik";
import {TeamMemberFormValues, UseTeamMemberForm} from "./types";
import {useCreateTeamMemberMutation, useUpdateTeamMemberMutation} from "../../../../queries/team/mutations";
import {CreateTeamMemberRequest, UpdateTeamMemberRequest} from "../../../../queries/team/types";
import {TeamMember} from "../../../../types/team";

const useTeamMemberForm = (): UseTeamMemberForm => {
    const createMutation = useCreateTeamMemberMutation();
    const updateMutation = useUpdateTeamMemberMutation();

    const create = async (values: TeamMemberFormValues, actions: FormikHelpers<TeamMemberFormValues>) => {
        try {
            const request: CreateTeamMemberRequest = {
                fullName: {
                    firstName: values.first,
                    lastName: values.last
                },
                description: values.description,
                position: values.position
            };

            await createMutation.mutateAsync({request}, {
                onSuccess: async () => {
                },
            });
        } catch (error) {
        }
    };

    const update = async (values: TeamMemberFormValues, actions: FormikHelpers<TeamMemberFormValues>, model: TeamMember) => {
        try {
            const request: UpdateTeamMemberRequest = {
                fullName: {
                    firstName: values.first,
                    lastName: values.last
                },
                description: values.description,
                position: values.position
            };
            await updateMutation.mutateAsync(
                {request, teamMemberId: String(model.id)},
                {
                    onSuccess: async () => {
                    },
                },
            );
        } catch (error) {
        }
    };

    return {
        create,
        update,
    };
};

export default useTeamMemberForm;