import React, { ReactElement, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PoolFormProps } from "../PoolForm/types";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import {
    Box,
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    LinearProgress,
    Typography,
    Alert,
    MenuItem,
    InputLabel,
    Select,
    FormHelperText,
    FormControl,
} from "@mui/material";

import { AppRoutes } from "../../../../routes/AppRoutes";
import { AppButton } from "../../../../components";
import { PoolBlockchainFormValues } from "./types";
import { AppNumberField, AppTextField } from "../../../../components/AppFields";
import { useBlockChain } from "./hooks";
import { PoolBlockchainValidationSchema } from "./validation";
import { isEmpty } from "lodash";
import { PoolStatus } from "../../../../types/pools";
import { usePoolForm } from "../PoolForm/hooks";
import { useWeb3React } from "@web3-react/core";
import { dummyReactSyntheticEvent, PoolPageTabs, whiteListedTokens } from "../../../../constants";
import { bool } from "yup";

export const BlockchainForm: React.FC<PoolFormProps> = ({ model = {}, onTabChange }: PoolFormProps): ReactElement => {
    const { account } = useWeb3React();
    const navigate = useNavigate();
    const { getPool_bch, createPool_bch } = useBlockChain();
    const { updateStatus } = usePoolForm();

    const [transaction, setTransaction] = useState<boolean>(false);

    const [open, setOpen] = React.useState(false);
    const [openMetamask, setOpenMetamask] = React.useState(false);
    const [isValidate, setIsValidate] = useState(false);
    const [openLoader, setOpenLoader] = useState(false);
    const [alertIsOpen, setAlertIsOpen] = useState(false);
    const [isRender, setIsRender] = useState(false);
    const [blockChainModel, setBlockChainModel] = React.useState<PoolBlockchainFormValues>(
        {} as PoolBlockchainFormValues
    );

    const {
        poolId,
        poolSize,
        minInvestmentSize,
        lockupPeriod,
        symbol = "",
        poolStatus,
        poolTokenName = "",
        poolTokenSymbol = "",
    } = blockChainModel;
    const initialValues: PoolBlockchainFormValues = {
        poolId: poolId || "",
        poolSize: poolSize || 0,
        minInvestmentSize: minInvestmentSize || 0,
        lockupPeriod: lockupPeriod || 0,
        symbol,
        poolStatus: poolStatus || PoolStatus.Draft,
        poolTokenName,
        poolTokenSymbol,
    };

    useEffect(() => {
        if (model.id && !isRender) {
            getPool_bch(model.id as string)
                .then((pool) => {
                    setBlockChainModel(pool);
                    setIsRender(true);
                })
                .catch((error) => {
                    console.log(error);
                    setIsRender(true);
                });
        }
    }, [model.id, transaction]);

    const handleNext = () => {
        if (onTabChange) {
            onTabChange(dummyReactSyntheticEvent, PoolPageTabs.Manage);
        } else {
            navigate(`${AppRoutes.POOLS}`);
        }
    };

    const handleBack = () => {
        if (onTabChange) {
            onTabChange(dummyReactSyntheticEvent, PoolPageTabs.Overview);
        } else {
            navigate(`${AppRoutes.POOLS}`);
        }
    };

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validateOnChange={isValidate}
            validateOnBlur={isValidate}
            validationSchema={PoolBlockchainValidationSchema}
            onSubmit={async (values: PoolBlockchainFormValues, actions: FormikHelpers<PoolBlockchainFormValues>) => {
                try {
                    if (!poolId && model.id) {
                        await window.ethereum.request({ method: "eth_requestAccounts" });
                        // Get PoolFactory contract object
                        if (account == null) {
                            setOpen(false);
                            setOpenMetamask(true);
                            throw Error("Account is null");
                        }
                        setOpenLoader(true);
                        setOpen(false);
                        createPool_bch({ ...values, poolId: model.id }).then(({ poolStatus }) => {
                            if (poolStatus === PoolStatus.Draft) {
                                setOpenLoader(false);
                                setAlertIsOpen(true);
                                setTimeout(() => {
                                    setAlertIsOpen(false);
                                }, 3000);

                                updateStatus(model.id as string, PoolStatus.Active);
                            } else {
                                setOpenLoader(false);
                            }

                            console.log("Pool created successfully!");

                            setIsRender(false);
                            setTransaction(true);
                        });
                    }
                } catch (error) {
                    alert("Blockchain deploy error. Notify the dev team about this incident.");
                    console.log("Blockchain deploy error:", error);
                }
            }}
        >
            {(formProps: FormikProps<PoolBlockchainFormValues>) => {
                const { values, errors, isSubmitting, validateForm, submitForm, dirty, setFieldValue } = formProps;

                const handleClose = () => {
                    setOpen(false);
                };

                const handleCloseMetamask = () => {
                    setOpenMetamask(false);
                };

                const handleClickOpen = () => {
                    validateForm(values).then((invalidValues) => {
                        console.log("Validating", invalidValues);
                        if (isEmpty(invalidValues)) {
                            setOpen(true);
                        }
                        setIsValidate(true);
                    });
                };

                return (
                    <Form>
                        {!isRender ? (
                            <LinearProgress />
                        ) : (
                            <Container sx={{ pt: 3, pb: 3 }}>
                                <Grid rowSpacing={4} container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                    <Grid item xs={12}>
                                        <Typography variant="h6" gutterBottom>
                                            Blockchain Pool Info
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box sx={{ display: "flex", flexDirection: "column", pt: 2 }}>
                                            <Grid
                                                rowSpacing={4}
                                                container
                                                spacing={2}
                                                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                                            >
                                                <Grid item sm={12}>
                                                    <FormControl sx={{ minWidth: 120, width: "100%" }}>
                                                        <InputLabel id="symbol">Symbol</InputLabel>
                                                        <Select
                                                            labelId="symbol"
                                                            id="symbol"
                                                            value={values.symbol}
                                                            disabled={!!poolId}
                                                            label="Symbol"
                                                            name="symbol"
                                                            required
                                                            onChange={(e) => {
                                                                setFieldValue("symbol", e.target.value);
                                                            }}
                                                        >
                                                            <MenuItem value={whiteListedTokens.stablecoin}>
                                                                {whiteListedTokens.stablecoin}
                                                            </MenuItem>
                                                            <MenuItem value={whiteListedTokens.HUSD}>
                                                                {whiteListedTokens.HUSD}
                                                            </MenuItem>
                                                        </Select>
                                                        <FormHelperText>
                                                            This field defines which token will be used as a primary
                                                            asset of the pool
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item sm={8}>
                                                    <AppTextField
                                                        id="poolTokenName"
                                                        label="Pool Token Name"
                                                        helperText={errors.poolTokenName}
                                                        error={!!errors.poolTokenName}
                                                        value={values.poolTokenName}
                                                        disabled={!!poolId}
                                                        onChange={(e) => {
                                                            setFieldValue("poolTokenName", e.target.value);
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item sm={4}>
                                                    <AppTextField
                                                        id="poolTokenSymbol"
                                                        label="Pool Token Symbol"
                                                        helperText={errors.poolTokenSymbol}
                                                        error={!!errors.poolTokenSymbol}
                                                        value={values.poolTokenSymbol}
                                                        disabled={!!poolId}
                                                        onChange={(e) => {
                                                            setFieldValue("poolTokenSymbol", e.target.value);
                                                        }}
                                                    />
                                                </Grid>

                                                <Grid item sm={12}>
                                                    <AppNumberField
                                                        id="poolSize"
                                                        label="Pool Size"
                                                        name="poolSize"
                                                        required
                                                        error={!!errors.poolSize}
                                                        helperText={errors.poolSize}
                                                        value={values.poolSize}
                                                        setValue={setFieldValue}
                                                        disabled={!!poolId}
                                                        suffix={` ${symbol}`}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <AppNumberField
                                                        id="minInvestmentSize"
                                                        label="Minimum Deposit"
                                                        type="number"
                                                        required
                                                        error={!!errors.minInvestmentSize}
                                                        helperText={errors.minInvestmentSize}
                                                        value={values.minInvestmentSize}
                                                        setValue={setFieldValue}
                                                        disabled={!!poolId}
                                                        suffix={` ${symbol}`}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <AppNumberField
                                                        id="lockupPeriod"
                                                        label="Hold Period"
                                                        type="number"
                                                        required
                                                        error={!!errors.lockupPeriod}
                                                        helperText={errors.lockupPeriod}
                                                        value={values.lockupPeriod}
                                                        setValue={setFieldValue}
                                                        suffix={values.lockupPeriod === 1 ? " day" : " days"}
                                                        disabled={!!poolId}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Dialog
                                            open={openMetamask}
                                            onClose={handleCloseMetamask}
                                            aria-labelledby="alert-dialog-title"
                                            aria-describedby="alert-dialog-description"
                                        >
                                            <DialogTitle id="alert-dialog-title">
                                                {"You are not connected to Metamask!"}
                                            </DialogTitle>
                                            <DialogContent>
                                                <DialogContentText id="alert-dialog-description">
                                                    For sending data to blockchain you have to connect to Metamask.
                                                </DialogContentText>
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={handleCloseMetamask}>OK</Button>
                                            </DialogActions>
                                        </Dialog>
                                        <Dialog
                                            open={open}
                                            onClose={handleClose}
                                            aria-labelledby="alert-dialog-title"
                                            aria-describedby="alert-dialog-description"
                                        >
                                            <DialogTitle id="alert-dialog-title">
                                                {"Do you want to deploy pool data on blockchain?"}
                                            </DialogTitle>
                                            <DialogContent>
                                                <DialogContentText id="alert-dialog-description">
                                                    This operation costs some gas fee.
                                                </DialogContentText>
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={handleClose}>Disagree</Button>
                                                <Button onClick={submitForm} autoFocus>
                                                    {/*openLoader? <CircularProgress color="inherit" size='1.5rem'/>:*/}
                                                    {"Agree"}
                                                </Button>
                                            </DialogActions>
                                        </Dialog>
                                        <Box sx={{ display: "flex", flexDirection: "row", pt: 10 }}>
                                            <AppButton color="inherit" onClick={handleBack} sx={{ mr: 1 }}>
                                                Back
                                            </AppButton>
                                            <Box sx={{ flex: "1 1 auto" }}>
                                                {alertIsOpen && (
                                                    <Alert severity="success">Pool data is successfully saved!</Alert>
                                                )}
                                            </Box>
                                            {!poolId && (
                                                <AppButton
                                                    // loading={openLoader}
                                                    // loadingIndicator={(() => <CircularProgress color="inherit"
                                                    //                                            size="1.5rem" />)()}
                                                    onClick={dirty || !model ? handleClickOpen : handleNext}
                                                    variant="contained"
                                                    disabled={isSubmitting}
                                                >
                                                    {!dirty && model ? "Next" : openLoader ? "Pending..." : "Save"}
                                                </AppButton>
                                            )}
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Container>
                        )}
                    </Form>
                );
            }}
        </Formik>
    );
};
