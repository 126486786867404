import Axios, {AxiosRequestConfig} from 'axios';
import {localStorageGet, TOKEN_KEY} from "../utils";

const httpClient = Axios.create({baseURL: process.env.REACT_APP_URL_API});

const customInstance = <T>(config: AxiosRequestConfig): Promise<T> => {
    return httpClient({...config}).then(({data}) => data);
};

httpClient.interceptors.request.use(
    config => {
        const token = localStorageGet(TOKEN_KEY);
        if (config.headers) {
            config.headers['Authorization'] = '';
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

export {customInstance as default, httpClient};