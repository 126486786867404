import React, { ReactElement } from "react";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import { LoginFormValues } from "./types";
import { Card, CardContent, CardHeader, Grid, TextField } from "@mui/material";
import { navigateTo, SHARED_CONTROL_PROPS } from "../../../utils";
import { AppAlert, AppButton, AppForm, AppLink } from "../../../components";
import Button from "@mui/material/Button";
import { AppRoutes } from "../../../routes/AppRoutes";
import useLoginForm from "./hooks";

const LoginForm: React.FC = (): ReactElement => {
    const { authenticate, formAlert } = useLoginForm();

    const initialValues: LoginFormValues = {
        email: "",
        password: "",
    };

    return (
        <>
            <Formik
                initialValues={initialValues}
                onSubmit={async (values: LoginFormValues, actions: FormikHelpers<LoginFormValues>) => {
                    await authenticate(values, actions);
                    navigateTo(AppRoutes.POOLS);
                }}
            >
                {(props: FormikProps<LoginFormValues>) => {
                    const { values, errors, handleChange, isSubmitting } = props;
                    return (
                        <AppForm>
                            <Form noValidate>
                                <Card>
                                    <CardHeader title="Login with Email" />
                                    <CardContent>
                                        <TextField
                                            required
                                            id="email"
                                            label="Email"
                                            name="email"
                                            value={values.email}
                                            onChange={handleChange}
                                            error={!!errors.email}
                                            helperText={errors.email}
                                            {...SHARED_CONTROL_PROPS}
                                        />
                                        <TextField
                                            required
                                            id="password"
                                            type="password"
                                            label="Password"
                                            name="password"
                                            value={values.password}
                                            error={!!errors.password}
                                            helperText={errors.password}
                                            onChange={handleChange}
                                            {...SHARED_CONTROL_PROPS}
                                        />
                                        <AppAlert message={formAlert.message} type={formAlert.type} />
                                        <Grid container justifyContent="center" alignItems="center">
                                            <AppButton type="submit">Login with Email</AppButton>
                                            <Button
                                                disabled={isSubmitting}
                                                variant="text"
                                                color="inherit"
                                                component={AppLink}
                                                to="/auth/recovery/password"
                                            >
                                                Forgot Password
                                            </Button>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Form>
                        </AppForm>
                    );
                }}
            </Formik>
        </>
    );
};

export default LoginForm;
