import { whiteListedTokens } from "../../constants";
import { Box, Typography } from "@mui/material";

export const BlockchainInfo = () => {
    const address = process.env.REACT_APP_CONTRACT_BLENDED_POOL;
    const assetAddress = process.env.REACT_APP_CONTRACT_USDC as string;
    const explorerLink = process.env.REACT_APP_TRANSACTION_EXPLORER_LINK;

    // open new page with explorer
    const handleAddressClick = () => {
        if (explorerLink) {
            window.open(`${explorerLink}/address/${address}`, "_blank");
        }
    };

    return (
        <div>
            <h1 className="h1-bold text-dark100_light900">Blockchain Info</h1>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                }}
            >
                <Typography>
                    Contract address:{" "}
                    <Typography
                        display="inline"
                        onClick={handleAddressClick}
                        sx={{
                            textDecoration: "underline",
                            cursor: "pointer",
                        }}
                    >
                        {address}
                    </Typography>
                </Typography>
                <Typography>Asset Name: {whiteListedTokens.stablecoin}</Typography>
                <Typography>
                    Asset address:{" "}
                    <Typography
                        display="inline"
                        onClick={handleAddressClick}
                        sx={{
                            textDecoration: "underline",
                            cursor: "pointer",
                        }}
                    >
                        {assetAddress}
                    </Typography>
                </Typography>
            </Box>
        </div>
    );
};
