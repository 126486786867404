import React, {ReactElement, useCallback} from "react";
import {useNavigate} from "react-router-dom";
import {Form, Formik, FormikHelpers, FormikProps} from "formik";
import {Box, Card, CardMedia, Container, Grid, LinearProgress, Typography} from "@mui/material";
import {AppRoutes} from "../../../../routes/AppRoutes";
import {AppButton} from "../../../../components";
import AddIcon from "@mui/icons-material/Add";
import useSuccessStoryImageForm from "./hooks";
import {SuccessStoryFormProps} from "../SuccessStoryForm/types";
import {SuccessStoryImageFormValues} from "./types";
import {useGetSingleSuccessStoryQuery} from "../../../../queries/successStories";

const SuccessStoryImageForm: React.FC<SuccessStoryFormProps> = (props: SuccessStoryFormProps): ReactElement => {
    const navigate = useNavigate();

    const {create} = useSuccessStoryImageForm();

    const successStoryId = props.model?.id;
    const {data: model, isLoading} = useGetSingleSuccessStoryQuery(successStoryId);

    const handleFinish = useCallback(() => {
        navigate(`${AppRoutes.SUCCESS_STORIES}`);
    }, [navigate]);

    let initialValues: SuccessStoryImageFormValues;

    if (model) {
        initialValues = {
            successStoryId: successStoryId,
            url: model?.urls ? model?.urls["original"] : undefined,
            file: undefined,
        };
    } else {
        initialValues = {
            successStoryId: successStoryId,
            url: undefined,
            file: undefined,
        };
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={
                async (values: SuccessStoryImageFormValues, actions: FormikHelpers<SuccessStoryImageFormValues>) => {
                    await create(values, actions);
                }
            }
        >
            {(formProps: FormikProps<SuccessStoryImageFormValues>) => {
                const {
                    values,
                    setFieldValue,
                    isSubmitting,
                    dirty
                } = formProps;

                const handleFileChange = (event: React.FormEvent<HTMLInputElement>) => {
                    if (event.currentTarget.files?.length) {
                        const fileBlob = event.currentTarget.files[0];
                        setFieldValue("file", fileBlob)
                        const imageFile = URL.createObjectURL(fileBlob);
                        setFieldValue("url", imageFile);
                    }
                }

                return (
                    <Form noValidate>
                        <Container sx={{pt: 3, pb: 3}}>
                            <Grid
                                rowSpacing={4}
                                container spacing={2}
                                columnSpacing={{xs: 1, sm: 2, md: 3}}
                            >
                                <Grid item xs={12}>
                                    <Typography variant="h6" gutterBottom>
                                        Image
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Card variant="outlined">
                                        <CardMedia
                                            component={isLoading ? LinearProgress : "img"}
                                            image={values.url}
                                        />
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <AppButton variant="contained" component="label">
                                        <AddIcon/> Select file
                                        <input type="file" name="file" id="file" hidden
                                               onChange={handleFileChange}/>
                                    </AppButton>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box display="flex" justifyContent="flex-end">
                                        <AppButton variant='outlined' onClick={handleFinish}>
                                            Cancel
                                        </AppButton>
                                        <AppButton type='submit' variant='contained'
                                                   disabled={!dirty || isSubmitting}>
                                            Save
                                        </AppButton>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Container>
                    </Form>
                );
            }
            }
        </Formik>
    );
}
export default SuccessStoryImageForm;
