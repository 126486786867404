import { useQuery, useQueryClient, UseQueryResult } from "react-query";
import { Pool } from "../../types/pools";
import { httpClient } from "../../api/axios";

export const useGetAllPoolsQuery = (): UseQueryResult<Pool[], Error> => useQuery<Pool[], Error>(
    "pools",
    async () => {
        const response = await httpClient.get("/pools");
        return response.data;
    }
);

export const useGetSinglePoolQuery = (poolId: string | undefined): UseQueryResult<Pool, Error> => {
    const queryClient = useQueryClient();

    return useQuery<Pool, Error>(
        ["pools", poolId],
        async () => {
            if (poolId) {
                const response = await httpClient.get(`/pools/${poolId}`);
                return response.data;
            }
        },
        {
            placeholderData: (): Pool | undefined => queryClient
                .getQueryData<Pool[]>("pools")
                ?.find((c: Pool) => c.id === poolId)
        }
    );
};
