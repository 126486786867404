import {useMutation, UseMutationResult, useQueryClient} from 'react-query';
import {httpClient} from "../../api/axios";
import {Company} from "../../types/companies";
import {
    CreateCompanyImageMutationVariables,
    CreateCompanyMutationVariables,
    UpdateCompanyMutationVariables
} from "./types";

export const useCreateCompanyMutation = (): UseMutationResult<Company, Error, CreateCompanyMutationVariables> => {
    const queryClient = useQueryClient();

    return useMutation<Company, Error, CreateCompanyMutationVariables>(
        async ({request}) => {
            const response = await httpClient.post('/companies', request);
            return response.data;
        },
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries('companies');
            },
        },
    );
};

export const useUpdateCompanyMutation = (): UseMutationResult<Company, Error, UpdateCompanyMutationVariables> => {
    const queryClient = useQueryClient();

    return useMutation<Company, Error, UpdateCompanyMutationVariables>(
        async ({request, companyId}) => {
            const response = await httpClient.put(`/companies/${companyId}`, request);
            return response.data;
        },
        {
            onSuccess: async (data, variables) => {
                await queryClient.invalidateQueries(['companies', variables.companyId]);
            },
        },
    );
};

export const useCreateCompanyImageMutation = (): UseMutationResult<Company, Error, CreateCompanyImageMutationVariables> => {
    const queryClient = useQueryClient();

    return useMutation<Company, Error, CreateCompanyImageMutationVariables>(
        async ({companyId, request}) => {
            const formData = new FormData();
            if (request?.file !== undefined) {
                formData.append('file', request?.file as Blob);
            }

            const response = await httpClient.post(`/companies/${companyId}/images`, formData, {headers: {'Content-Type': 'multipart/form-data'}});
            return response.data;
        },
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries('company-images');
            },
        },
    );
};
