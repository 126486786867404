import React from "react";
import { GridColDef, GridEventListener, GridRenderCellParams, GridRowParams } from "@mui/x-data-grid";
import { Avatar, Box, Paper, Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../routes/AppRoutes";
import { AppButton } from "../../../components";
import { useHeaderStyles } from "../../../utils/style";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import AppFormHeader from "../../../components/AppFormHeader";
import { AppCreateEditForm } from "../../../components/AppCreateEditForm";
import AppDataGrid from "../../../components/AppDataGrid/AppDataGrid";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import { useGetAllSuccessStoriesQuery } from "../../../queries/successStories";
import { SuccessStoryImageUrls } from "../../../types/successStories";
import { DeleteButton } from "../../DeleteButton";
import { ListNames } from "../../../types/shared";

const columns: GridColDef[] = [
    {
        field: "urls", headerName: "", width: 30, renderCell: (params: GridRenderCellParams<SuccessStoryImageUrls>) => {
            if (params.value) {
                return <Avatar alt="Remy Sharp" sx={{ width: 30, height: 30 }} src={params.value["small"] || ""} />;
            } else {
                return <Skeleton variant="circular" width={30} height={30} animation={false} />;
            }
        }
    },
    { field: "name", headerName: "Name", width: 300 },
    { field: "description", headerName: "Description", flex: 1 },
    {
        field: "id",
        headerName: "",
        minWidth: 80,
        align: "right",
        sortable: false,
        renderCell: (params: GridRenderCellParams<string>) => <DeleteButton id={params.value as string}
                                                                            listName={ListNames.SuccessStories} />
    }
];

function SuccessStoriesIndex() {
    let navigate = useNavigate();
    const classes = useHeaderStyles();

    const [value, setValue] = React.useState("all");
    const { data: successStories, isLoading } = useGetAllSuccessStoriesQuery();

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const CreateSuccessStoryButton = () => {
        return <AppButton onClick={handleCreateSuccessStory}>Add Success Story</AppButton>;
    };

    const handleCreateSuccessStory = () => {
        navigate(`${AppRoutes.SUCCESS_STORIES}/create`);
    };

    const handleRowClickEvent: GridEventListener<"rowClick"> = (params: GridRowParams) => {
        navigate(`${AppRoutes.SUCCESS_STORIES}/${params.id}/edit`);
    };

    return (
        <AppCreateEditForm>
            <AppFormHeader level1Route={AppRoutes.SUCCESS_STORIES}
                           level1Caption="Success Stories"
                           level1Icon={AutoStoriesIcon}
                           level2Caption={null}
                           actionButton={CreateSuccessStoryButton()} />
            <Paper className={classes.paper}>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <TabList onChange={handleTabChange}>
                            <Tab label="All" value="all" />
                        </TabList>
                    </Box>
                    <TabPanel className={classes.panelTab} value="all">
                        <div style={{ display: "flex", height: "100%" }}>
                            <div style={{ flexGrow: 1 }}>
                                <AppDataGrid
                                    columns={columns}
                                    onRowClick={handleRowClickEvent}
                                    rows={successStories || []}
                                    isLoading={isLoading}
                                />
                            </div>
                        </div>
                    </TabPanel>
                </TabContext>
            </Paper>
        </AppCreateEditForm>
    );
}

export default SuccessStoriesIndex;


