import { PoolBlockchainFormValues } from "./types";
import abiPoolFactory from "../../../../contracts/abi/PoolFactory.json";
import abiPool from "../../../../contracts/abi/Pool.json";
import abiBlendedPool from "../../../../contracts/abi/BlendedPool.json";
import abiIERC20MetadataFactory from "../../../../contracts/abi/IERC20Metadata.json";
import { useWeb3React } from "@web3-react/core";
import { Address, ONE_DAY_TIME_UNIT } from "../../../../web3/constants";
import { PoolStatus } from "../../../../types/pools";
import { usePoolForm } from "../PoolForm/hooks";
import { ethers } from "ethers";
import { SystemIDs, whiteListedTokens } from "../../../../constants";
import { formatBigInt } from "../../../../utils";

export const useBlockChain = () => {
    const { account, library: provider } = useWeb3React();
    const { updateStatus } = usePoolForm();

    let CONTRACT_POOL_FACTORY = process.env.REACT_APP_CONTRACT_POOL_FACTORY!;
    let CONTRACT_BLENDED_POOL = process.env.REACT_APP_CONTRACT_BLENDED_POOL!;
    let CONTRACT_USDC = process.env.REACT_APP_CONTRACT_USDC!;
    let CONTRACT_HUSD = process.env.REACT_APP_HUSD!;

    const getPool_bch = async (poolId: string): Promise<PoolBlockchainFormValues> => {
        try {
            if (account) {
                const signer = provider.getSigner();

                if (poolId === SystemIDs.BlendedPoolID) {
                    console.log("Blended Pool", poolId);
                    const blendedPoolContract = new ethers.Contract(CONTRACT_BLENDED_POOL, abiBlendedPool, signer);
                    const IERC20Pool = new ethers.Contract(CONTRACT_BLENDED_POOL, abiIERC20MetadataFactory, provider);

                    if (!blendedPoolContract) {
                        return {
                            poolStatus: PoolStatus.Draft,
                        } as PoolBlockchainFormValues;
                    }

                    const [
                        poolInfo,
                        decimals,
                        stableCoinBalance,
                        currentDebt,
                        totalSupply,
                        poolTokenName,
                        poolTokenSymbol,
                        holdersInQueue,
                        yieldBalance,
                        asset,
                    ] = await Promise.all([
                        blendedPoolContract.poolInfo(),
                        blendedPoolContract.decimals(),
                        blendedPoolContract.principalBalanceAmount(),
                        blendedPoolContract.principalOut(),
                        blendedPoolContract.totalSupply(),
                        IERC20Pool.name(),
                        IERC20Pool.symbol(),
                        blendedPoolContract.getPendingWithdrawalHolders(),
                        blendedPoolContract.yieldBalanceAmount(),
                        blendedPoolContract.asset(),
                    ]);

                    const IERC20 = new ethers.Contract(asset, abiIERC20MetadataFactory, provider);
                    const symbol = await IERC20.symbol();

                    const { lockupPeriod, minInvestmentAmount } = poolInfo;

                    return {
                        poolId: poolId,
                        poolSize: 0,
                        minInvestmentSize: formatBigInt(minInvestmentAmount, decimals),
                        lockupPeriod: ethers.toNumber(BigInt(lockupPeriod)) / ONE_DAY_TIME_UNIT,
                        stableCoinBalance: formatBigInt(stableCoinBalance, decimals),
                        symbol,
                        poolStatus: PoolStatus.Active,
                        currentDebt: formatBigInt(currentDebt, decimals),
                        tokenSupply: formatBigInt(totalSupply, decimals),
                        poolTokenName: poolTokenName,
                        poolTokenSymbol: poolTokenSymbol,
                        holdersInQueue: holdersInQueue,
                        yieldBalance: formatBigInt(yieldBalance, decimals),
                    };
                }

                // Regional Pool Info Request
                const poolFactory = new ethers.Contract(CONTRACT_POOL_FACTORY, abiPoolFactory, signer);

                if (poolId) {
                    console.log("Regional Pool", poolId);
                    const address = await poolFactory.pools(poolId);
                    const IERC20Pool = new ethers.Contract(address, abiIERC20MetadataFactory, provider);
                    if (address === Address.Null) {
                        return {
                            poolStatus: PoolStatus.Draft,
                        } as PoolBlockchainFormValues;
                    }

                    // Create Pool contract object
                    const pool = new ethers.Contract(address, abiPool, signer);

                    if (!pool && address === Address.Null) {
                        return {
                            poolStatus: PoolStatus.Draft,
                        } as PoolBlockchainFormValues;
                    }

                    let poolStatus: PoolStatus;

                    const [
                        poolInfo,
                        decimals,
                        poolState,
                        stableCoinBalance,
                        currentDebt,
                        totalSupply,
                        poolTokenName,
                        poolTokenSymbol,
                        holdersInQueue,
                        yieldBalance,
                        asset,
                    ] = await Promise.all([
                        pool.poolInfo(),
                        pool.decimals(),
                        pool.poolState(),
                        pool.principalBalanceAmount(),
                        pool.principalOut(),
                        pool.totalSupply(),
                        IERC20Pool.name(),
                        IERC20Pool.symbol(),
                        pool.getPendingWithdrawalHolders(),
                        pool.yieldBalanceAmount(),
                        pool.asset(),
                    ]);

                    const IERC20 = new ethers.Contract(asset, abiIERC20MetadataFactory, provider);
                    const symbol = await IERC20.symbol();

                    switch (poolState) {
                        case 0:
                            poolStatus = PoolStatus.Active;
                            break;
                        case 1:
                            poolStatus = PoolStatus.Closed;
                            break;
                        default:
                            poolStatus = PoolStatus.Draft;
                            break;
                    }

                    const { lockupPeriod, investmentPoolSize, minInvestmentAmount } = poolInfo;

                    return {
                        poolId,
                        poolSize: formatBigInt(investmentPoolSize, decimals),
                        minInvestmentSize: formatBigInt(minInvestmentAmount, decimals),
                        lockupPeriod: ethers.toNumber(BigInt(lockupPeriod)) / ONE_DAY_TIME_UNIT, //+lockupPeriod / ONE_DAY_TIME_UNIT
                        stableCoinBalance: formatBigInt(stableCoinBalance, decimals),
                        symbol,
                        poolStatus,
                        currentDebt: formatBigInt(currentDebt, decimals),
                        tokenSupply: formatBigInt(totalSupply, decimals),
                        poolTokenName,
                        poolTokenSymbol,
                        holdersInQueue,
                        yieldBalance: formatBigInt(yieldBalance, decimals),
                    };
                }

                return {
                    poolStatus: PoolStatus.Draft,
                } as PoolBlockchainFormValues;
            }
            return {} as PoolBlockchainFormValues;
        } catch (error) {
            console.log("Get Pool Error:", error);
            return {
                poolStatus: PoolStatus.Draft,
            } as PoolBlockchainFormValues;
        }
    };

    const createPool_bch = async (data: PoolBlockchainFormValues): Promise<PoolBlockchainFormValues> => {
        try {
            const signer = provider.getSigner();
            const poolFactory = new ethers.Contract(CONTRACT_POOL_FACTORY, abiPoolFactory, signer);
            const { poolId, poolSize, minInvestmentSize, lockupPeriod, symbol } = data;
            const tokenAddress = symbol === whiteListedTokens.stablecoin ? CONTRACT_USDC : CONTRACT_HUSD;
            const IERC20 = new ethers.Contract(tokenAddress, abiIERC20MetadataFactory, provider);
            const decimals = await IERC20.decimals();
            const poolTokenName = data.poolTokenName ?? "Helios Pool Token";
            const poolTokenSymbol = data.poolTokenSymbol ?? "HLT";
            // Get PoolFactory contract object

            if (account == null) {
                throw Error("Account is null");
            }

            if (poolId) {
                // Create Pool contract object
                const newPoolTx: any = await poolFactory.createPool(
                    poolId,
                    tokenAddress,
                    lockupPeriod * ONE_DAY_TIME_UNIT,
                    ethers.parseUnits(minInvestmentSize.toString(), decimals), // old version: bigMinInvestmentSize.mul(dec.pow(decimals)),
                    ethers.parseUnits(poolSize.toString(), decimals), // old version: bigPoolSize.mul(dec.pow(decimals)),,
                    poolTokenName,
                    poolTokenSymbol
                );
                let newPoolTxReceipt = await provider.waitForTransaction(newPoolTx.hash);

                if (newPoolTxReceipt.status === 1) {
                    updateStatus(poolId, PoolStatus.Active);
                    // Get Pool contract object
                    await getPool_bch(data.poolId);
                    return {
                        poolId: data.poolId,
                        poolStatus: PoolStatus.Active,
                    } as PoolBlockchainFormValues;
                }
            }

            return {
                poolStatus: PoolStatus.Draft,
            } as PoolBlockchainFormValues;
        } catch (error) {
            console.log("error", error);
            return {
                poolStatus: PoolStatus.Draft,
            } as PoolBlockchainFormValues;
        }
    };

    const closePool_bch = async (poolId: string) => {
        try {
            const signer = provider.getSigner();
            const poolFactory = new ethers.Contract(CONTRACT_POOL_FACTORY, abiPoolFactory, signer);
            // Get PoolFactory contract object
            console.log("signer", account);
            if (account == null) {
                throw Error("Account is null");
            }

            if (poolId) {
                // Create Pool contract object

                const address = await poolFactory.pools(poolId);

                const pool = new ethers.Contract(address, abiPool, signer);
                if (!pool && address === Address.Null) {
                    throw Error("Pool is not deployed or dont exist");
                }

                const closeTx = await pool.close();
                const closeTxReceipt = await provider.waitForTransaction(closeTx.hash);

                if (closeTxReceipt.status === 1) {
                    updateStatus(poolId, PoolStatus.Closed);
                } else {
                    throw Error("Transaction failed");
                }
            }
        } catch (error) {
            console.log("Pool Finalize Error:", error);
        }
    };

    // returns native coin balance and the balance of the stablecoin of the admin wallet
    const getAdminBalances = async (symbol: whiteListedTokens) => {
        const tokenAddress = symbol === whiteListedTokens.stablecoin ? CONTRACT_USDC : CONTRACT_HUSD;
        try {
            if (account) {
                const IERC20 = new ethers.Contract(tokenAddress, abiIERC20MetadataFactory, provider);
                const decimals = await IERC20.decimals();
                const stablecoinBalance = await IERC20.balanceOf(account);
                const nativeCoinBalance = await provider.getBalance(account);

                const stablecoinBalanceNumber = formatBigInt(stablecoinBalance, decimals).toFixed(2);
                const ethBalanceNumber = formatBigInt(nativeCoinBalance, 18).toFixed(2);

                return {
                    stablecoin: stablecoinBalanceNumber,
                    eth: ethBalanceNumber,
                };
            }
            return {
                stablecoin: 0,
                eth: 0,
            };
        } catch (error) {
            console.log("Get Admin Balances Error:", error);
            return {
                stablecoin: 0,
                eth: 0,
            };
        }
    };

    return { createPool_bch, getPool_bch, closePool_bch, getAdminBalances };
};
