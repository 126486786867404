import React from "react";
import { useParams } from "react-router-dom";
import { Box, Container, Divider, Hidden, Paper, Typography, CircularProgress } from "@mui/material";
import { AppRoutes } from "../../../routes/AppRoutes";
import { AppCreateEditForm } from "../../../components/AppCreateEditForm";
import AppFormHeader from "../../../components/AppFormHeader";
import TokenIcon from "@mui/icons-material/Token";
import { useHeaderStyles } from "../../../utils/style";
import { ProjectForm, ProjectManageForm } from "../Forms";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import SettingsIcon from "@mui/icons-material/Settings";
import TabPanel from "@mui/lab/TabPanel";
import { useGetSingleProjectQuery } from "../../../queries/projects";

export const CreateEditProject = () => {
    const { id } = useParams();
    const [value, setValue] = React.useState("overview");
    const classes = useHeaderStyles();

    const { data: project, isLoading } = useGetSingleProjectQuery(id || "");
    console.log("isLoading", isLoading);
    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    return (
        <AppCreateEditForm>
            <AppFormHeader level1Route={AppRoutes.PROJECTS} level1Caption="Projects" level1Icon={TokenIcon}
                           level2Caption={ id || "Create Project"} actionButton={null} />
            <Paper className={classes.paper}>
                <Container sx={{ pt: 3, pb: 2 }}>
                    <Typography variant="h5" gutterBottom>
                        {!isLoading ? `${project ? "Edit Project" : "Create Project"}` : <CircularProgress />}
                    </Typography>
                </Container>
                <Divider />
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <TabList onChange={handleTabChange}>
                            <Tab icon={<ManageAccountsIcon />} label={<Hidden smDown>Overview</Hidden>}
                                 value="overview" />
                            <Tab icon={<SettingsIcon />} label={<Hidden smDown>Manage</Hidden>} value="manage"
                                 disabled={!project} />
                        </TabList>
                    </Box>
                    <TabPanel className={classes.panelTab} value="overview">
                        {!isLoading ? <ProjectForm model={project} onTabChange={(value: string) => setValue(value)} /> :
                            <CircularProgress />}
                    </TabPanel>
                    <TabPanel className={classes.panelTab} value="manage">
                        {!isLoading ?
                            <ProjectManageForm model={project} onTabChange={(value: string) => setValue(value)} /> :
                            <CircularProgress />}
                    </TabPanel>
                </TabContext>
            </Paper>
        </AppCreateEditForm>
    );
};
