import { useGetAllPoolsQuery } from "../../../../queries/pools";
import { Box, LinearProgress } from "@mui/material";
import { PoolCard } from "./PoolCard";

export const PoolsCards = () => {
    const { data = [], isLoading } = useGetAllPoolsQuery();

    return (
        <>
            {isLoading ? (
                <LinearProgress />
            ) : (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: "1rem" }}>
                    {data.map((pool) => {
                        return <PoolCard key={pool.id} pool={pool} />;
                    })}
                </Box>
            )}
        </>
    );
};
