import {Route, Routes} from 'react-router-dom';
import AuthRoutes from '../views/Auth';
import {NotFound} from '../views';
import AboutView from '../views/About';
import LoginForm from "../views/Auth/LoginForm";

/**
 * List of routes available only for anonymous users
 */
const PublicRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<LoginForm/>}/>
            <Route path="auth/*" element={<AuthRoutes/>}/>
            <Route path="about" element={<AboutView/>}/>,
            <Route path="*" element={<NotFound/>}/>
        </Routes>
    );
};

export default PublicRoutes;
