import React, {ReactElement, useCallback} from "react";
import {Form, Formik, FormikHelpers, FormikProps} from "formik";
import {Box, Container, Grid} from "@mui/material";
import { AppTextField } from "../../../../components/AppFields";
import {AppButton} from "../../../../components";
import {AppRoutes} from "../../../../routes/AppRoutes";
import {useNavigate} from "react-router-dom";
import useTeamMemberForm from "./hooks";
import {TeamMemberFormProps, TeamMemberFormValues, TeamMemberValidationSchema} from "./types";
import {useGetSingleTeamMemberQuery} from "../../../../queries/team";

const TeamMemberForm: React.FC<TeamMemberFormProps> = (props: TeamMemberFormProps): ReactElement => {
    const navigate = useNavigate();
    const {create, update} = useTeamMemberForm();
    const id = props.model?.id;
    const {data: model} = useGetSingleTeamMemberQuery(id);

    let initialValues: TeamMemberFormValues;

    const handleFinish = useCallback(() => {
        navigate(`${AppRoutes.TEAM}`);
    }, [navigate]);


    if (model) {
        initialValues = {
            first: model.fullName?.firstName,
            last: model.fullName?.lastName,
            description: model.description,
            position: model.position
        };
    } else {
        initialValues = {
            first: '',
            last: '',
            description: '',
            position: ''
        };
    }

    return (
        <>
            <Formik
                validateOnChange={true}
                validateOnBlur={false}
                initialValues={initialValues}
                enableReinitialize={true}
                validationSchema={TeamMemberValidationSchema}
                onSubmit={
                    async (values: TeamMemberFormValues, actions: FormikHelpers<TeamMemberFormValues>) => {
                        if (model?.id) {
                            await update(values, actions, model);
                        } else {
                            await create(values, actions);
                        }
                        navigate(`${AppRoutes.TEAM}`);
                    }
                }
            >
                {(formProps: FormikProps<TeamMemberFormValues>) => {
                    const {
                        values,
                        errors,
                        handleChange,
                        isSubmitting,
                    } = formProps;
                    return (
                        <Form noValidate>
                            <Container sx={{pt: 3, pb: 3}}>
                                <Grid
                                    rowSpacing={4}
                                    container spacing={2}
                                    columnSpacing={{xs: 1, sm: 2, md: 3}}
                                >
                                    <Grid item xs={12}>
                                        <AppTextField
                                            id="first"
                                            label="First Name"
                                            required
                                            error={!!errors.first}
                                            helperText={errors.first}
                                            value={values.first}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} >
                                        <AppTextField
                                            id="last"
                                            label="Last Name"
                                            required
                                            error={!!errors.last}
                                            helperText={errors.last}
                                            value={values.last}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <AppTextField
                                            id="position"
                                            label="Position"
                                            error={!!errors.position}
                                            helperText={errors.position}
                                            value={values.position}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <AppTextField
                                            id="description"
                                            label="Description"
                                            multiline
                                            rows={10}
                                            error={!!errors.description}
                                            helperText={errors.description}
                                            value={values.description}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                </Grid>
                            </Container>
                            <Container sx={{pt: 2, pb: 2}}>
                                <Grid container spacing={2} rowSpacing={2}>
                                    {/*<Grid item xs={12}>*/}
                                    {/*    <Typography variant="h6" gutterBottom>*/}
                                    {/*        Manage settings*/}
                                    {/*    </Typography>*/}
                                    {/*</Grid>*/}
                                    <Grid item xs={12}>
                                        <Box display="flex" justifyContent="flex-end">
                                            <AppButton variant='outlined' onClick={handleFinish}>
                                                Cancel
                                            </AppButton>

                                            <AppButton type='submit' variant='contained' disabled={isSubmitting}>
                                                {model?.id ? 'Save' : 'Create'}
                                            </AppButton>
                                        </Box>
                                    </Grid>
                                    {/*{model?.id &&*/}
                                    {/*    <>*/}
                                    {/*        <Grid item xs={12}>*/}
                                    {/*            <Divider/>*/}
                                    {/*        </Grid>*/}
                                    {/*        <Grid item xs={12}>*/}
                                    {/*            <Typography color="error" variant="h6" gutterBottom>*/}
                                    {/*                Danger zone*/}
                                    {/*            </Typography>*/}
                                    {/*        </Grid>*/}
                                    {/*        <Grid item xs={6}>*/}
                                    {/*            <Typography color="error" gutterBottom>*/}
                                    {/*                Delete success story*/}
                                    {/*            </Typography>*/}
                                    {/*        </Grid>*/}
                                    {/*        <Grid item xs={6}>*/}
                                    {/*            <Box display="flex" justifyContent="flex-end">*/}
                                    {/*                <AppButtonError variant="text">*/}
                                    {/*                    Delete*/}
                                    {/*                </AppButtonError>*/}
                                    {/*            </Box>*/}
                                    {/*        </Grid>*/}
                                    {/*    </>*/}
                                    {/*}*/}
                                </Grid>
                            </Container>
                        </Form>
                    );
                }
                }
            </Formik>
        </>
    );
};

export default TeamMemberForm;
