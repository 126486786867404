import { UseWalletConnect } from "./types";
import { useWeb3React } from "@web3-react/core";
import { injected } from "./injected";

const useWalletConnect = (): UseWalletConnect => {
    const { active, activate, deactivate, chainId } = useWeb3React();

    const isActive = (): boolean => {
        return active;
    };

    const disconnect = async () => {
        try {
            deactivate();
        } catch (ex) {
            console.log(ex);
        }
    };

    const switchNetwork = async (id: string): Promise<void> => {
        const networkUrl = process.env.REACT_APP_CHAIN_NETWORK_URL;
        const explorerUrl = process.env.REACT_APP_TRANSACTION_EXPLORER_LINK;

        console.log(networkUrl, explorerUrl);

        try {
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: `0x${parseInt(id, 10).toString(16)}` }],
            });
        } catch (switchError: any) {
            if (switchError.code === 4902 || switchError.code === -32602) {
                try {
                    await window.ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [
                            {
                                chainId: `0x${parseInt(id, 10).toString(16)}`,
                                chainName: 'Base Sepolia',
                                nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
                                rpcUrls: [networkUrl],
                                blockExplorerUrls: [explorerUrl],
                                iconUrls: ['future'],
                            },
                        ],
                    });
                } catch (addError) {
                    console.log('Error');
                }
            } else {
                console.log(switchError, "You can't switch your network right now");
            }
        }
    };

    const connectWallet = async () => {
        try {
            const id = await window.ethereum.request({
                method: 'eth_chainId',
            });
            if (id && (Number(id)?.toString() !== process.env.REACT_APP_CHAIN_ID)) {
                await switchNetwork(process.env.REACT_APP_CHAIN_ID as string);
            } else {
                try {
                    if (id && Number(id)?.toString() === process.env.REACT_APP_CHAIN_ID!) {
                        localStorage.setItem('isWalletConnected', 'true');
                    }
                } catch (e) {
                    console.log(e);
                }
            }
            await activate(injected);
        } catch (ex) {
            console.log(ex);
        }
    };

    const connect = async () => {
        try {
            try {
                const id = await window.ethereum.request({
                    method: 'eth_chainId',
                });

                if (id && Number(id)?.toString() !== process.env.REACT_APP_CHAIN_ID) {
                    await switchNetwork(process.env.REACT_APP_CHAIN_ID as string);
                }
                await connectWallet();
            } catch (ex) {
                console.error(ex);
            }
        } catch (ex) {
            console.log(ex);
        }
    };

    return {
        connect,
        disconnect,
        isActive
    };
};


export default useWalletConnect;
