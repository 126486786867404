import React, { useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@mui/material";
import { Pool, PoolStatus } from "../types/pools";
import { ListNames } from "../types/shared";
import { useListForm } from "./hooks";
import { AppButton } from "../components";

type DeleteButtonProps = {
    id: string
    listName: ListNames,
    item?: Pool,
    isRedirect?: boolean,
}

export const DeleteButton: React.FC<DeleteButtonProps> = ({ id, listName, item, isRedirect }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const { remove } = useListForm(listName);

    const handleCloseModal = () => {
        setModalIsOpen(false);
    };

    const handleDelete = () => {
        setModalIsOpen(false);
        switch (listName) {
            case ListNames.Pools: {
                if (item?.status === PoolStatus.Draft) {
                    if (isRedirect) {
                        remove(id, setLoading, listName);
                    } else {
                        remove(id, setLoading);
                    }
                }
            }
                break;
            default: {
                remove(id, setLoading);
                if (isRedirect) {
                    remove(id, setLoading, listName);
                }
            }
        }
    };

    return <>
        <AppButton
            disabled={(listName === ListNames.Pools && ((item as Pool).status !== PoolStatus.Draft)) || loading}
            // loading={loading}
            onClick={
                (e) => {
                    e.stopPropagation();
                    setModalIsOpen(true);
                }
            }
            variant="text"
        >
            Delete
        </AppButton>
        <Dialog
            open={modalIsOpen}
            onClose={handleCloseModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {`Do you want to delete?`}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {`This operation will delete all data.`}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseModal}>Disagree</Button>
                <Button onClick={handleDelete} autoFocus>
                    Agree
                </Button>
            </DialogActions>
        </Dialog>
    </>;
};
