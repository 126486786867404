import { ethers } from "ethers";
import abiPoolFactory from "../../../../contracts/abi/PoolFactory.json";
import abiPool from "../../../../contracts/abi/Pool.json";
import abiBlendedPool from "../../../../contracts/abi/BlendedPool.json";
import abiIERC20Factory from "../../../../contracts/abi/IERC20.json";
import { useWeb3React } from "@web3-react/core";
import { Address } from "../../../../web3/constants";
import { FundsInterface, TransactionStatusValues, TransactionStatus } from "./types";
import { SystemIDs } from "../../../../constants";

export const useFunds = (): FundsInterface => {
    const { active, library: provider } = useWeb3React();

    const CONTRACT_POOL_FACTORY = process.env.REACT_APP_CONTRACT_POOL_FACTORY!;
    const CONTRACT_BLENDED_POOL = process.env.REACT_APP_CONTRACT_BLENDED_POOL!;
    const CONTRACT_USDC = process.env.REACT_APP_CONTRACT_USDC!;
    const CONTRACT_HUSD = process.env.REACT_APP_HUSD!;

    // If the provider is not connected, return an object with functions that return an error message
    // Otherwise it will crash with the white screen
    if (provider === undefined) {
        return {
            repay: async () => {
                return {
                    status: TransactionStatus.Error,
                    message: "Please connect your wallet first.",
                };
            },
            borrow: async () => {
                return {
                    status: TransactionStatus.Error,
                    message: "Please connect your wallet first.",
                };
            },
            distributeYields: async () => {
                return {
                    status: TransactionStatus.Error,
                    message: "Please connect your wallet first.",
                };
            },
            mintHUSD: async () => {
                return {
                    status: TransactionStatus.Error,
                    message: "Please connect your wallet first.",
                };
            },
            invest: async () => {
                return {
                    status: TransactionStatus.Error,
                    message: "Please connect your wallet first.",
                };
            },
        };
    }

    const signer = provider.getSigner();
    const signerAddress = signer.getAddress();

    const poolFactory = new ethers.Contract(CONTRACT_POOL_FACTORY, abiPoolFactory, signer);

    // Default USDC contract object
    let IERC20 = new ethers.Contract(CONTRACT_USDC, abiIERC20Factory, signer.connectUnchecked());

    // Default HUSD contract object
    const HUSD = new ethers.Contract(CONTRACT_HUSD, abiIERC20Factory, signer.connectUnchecked());

    const poolTransactionFlowHOF = async (
        txName: string,
        amount: number,
        poolId: string,
        needsTokenApproval: boolean,
        withSigner: boolean,
        txFunctionName: string,
        setLoader: (name: string, value: boolean) => void
    ): Promise<TransactionStatusValues> => {
        try {
            if (poolId && active) {
                // Declaring defaults
                let poolAddress: string;
                let pool: ethers.Contract;

                // Blended Pool Support
                setLoader(txName, true);

                // Reassign pool address
                if (poolId === SystemIDs.BlendedPoolID) {
                    poolAddress = CONTRACT_BLENDED_POOL;
                } else {
                    poolAddress = await poolFactory.pools(poolId);
                }

                if (poolAddress === Address.Null) {
                    setLoader(txName, false);
                    return {
                        status: TransactionStatus.Error,
                        message: "Pool address doesnt exist.",
                    };
                }

                // Reassign pool object
                if (poolId === SystemIDs.BlendedPoolID) {
                    console.log("Blended Pool Instance");
                    pool = new ethers.Contract(poolAddress, abiBlendedPool, signer.connectUnchecked());
                } else {
                    console.log("Regional Pool Instance");
                    pool = new ethers.Contract(poolAddress, abiPool, signer.connectUnchecked());
                }

                const asset = await pool.asset();

                // Rewrite default asset
                IERC20 = new ethers.Contract(asset, abiIERC20Factory, signer.connectUnchecked());

                if (!pool) {
                    setLoader(txName, false);
                    return {
                        status: TransactionStatus.Error,
                        message: "Pool data doesnt exist.",
                    };
                }

                if (signerAddress) {
                    const decimals = await pool.decimals();
                    const amountWad = ethers.parseUnits(amount.toString(), decimals);

                    if (amountWad === BigInt(0)) {
                        setLoader(txName, false);
                        return {
                            status: TransactionStatus.Warning,
                            message: "No amount mentioned for the transaction.",
                        };
                    }

                    if (needsTokenApproval) {
                        console.log("Requesting Token Approval");
                        const approveTx = await IERC20.approve(poolAddress, amountWad);
                        let approveReceipt = await provider.waitForTransaction(approveTx.hash);

                        if (approveReceipt.status !== 1) {
                            setLoader(txName, false);
                            return {
                                status: TransactionStatus.Error,
                                message: "Approve failed!",
                            };
                        }
                    }

                    let tx;

                    if (withSigner) {
                        tx = await pool[txFunctionName](signerAddress, amountWad);
                    } else {
                        tx = await pool[txFunctionName](amountWad);
                    }

                    const receipt = await provider.waitForTransaction(tx.hash);

                    if (receipt.status === 1) {
                        setLoader(txName, false);
                        return {
                            status: TransactionStatus.Success,
                            message: `${txName} succeed!`,
                        };
                    }
                } else {
                    setLoader(txName, false);
                    return {
                        status: TransactionStatus.Warning,
                        message: `Current account is not an admin. Please switch to admin account.`,
                    };
                }
            } else {
                setLoader(txName, false);
                return {
                    status: TransactionStatus.Error,
                    message: "Pool data doesnt exist.",
                };
            }
        } catch (error: any) {
            setLoader(txName, false);
            return {
                status: TransactionStatus.Error,
                message: `"${txName} failed: ${error.message}`,
            };
        }

        return {
            status: TransactionStatus.Error,
            message: `"${txName} failed`,
        };
    };

    // Get PoolFactory contract object
    const makePayment = async (
        poolId: string,
        amount: number,
        setLoader: (name: string, value: boolean) => void
    ): Promise<TransactionStatusValues> => {
        return poolTransactionFlowHOF("Repayment", amount, poolId, true, false, "repay", setLoader);
    };

    const borrow = async (
        poolId: string,
        amount: number,
        setLoader: (name: string, value: boolean) => void
    ): Promise<TransactionStatusValues> => {
        return poolTransactionFlowHOF("Borrow", amount, poolId, false, true, "borrow", setLoader);
    };

    const distributeYields = async (
        poolId: string,
        amount: number,
        setLoader: (name: string, value: boolean) => void
    ): Promise<TransactionStatusValues> => {
        return poolTransactionFlowHOF("Distribute Yield", amount, poolId, true, false, "repayYield", setLoader);
    };

    // Functionality related to the investment management of the pool (if the pool has HUSD asset)
    const mintHUSD = async (
        amount: number,
        setLoader: (name: string, value: boolean) => void
    ): Promise<TransactionStatusValues> => {
        try {
            const decimals = await HUSD.decimals();
            // parse value in eth to wei
            const amountWad = ethers.parseUnits(amount.toString(), decimals);
            setLoader("mintHUSD", true);
            // mint tokens to the signer wallet
            const mintTx = await HUSD.mint(signer.getAddress(), amountWad);
            let receipt = await provider.waitForTransaction(mintTx.hash);

            if (receipt.status === 1) {
                setLoader("mintHUSD", false);
                return {
                    status: TransactionStatus.Success,
                    message: "Minting HUSD succeeded!",
                };
            } else {
                setLoader("mintHUSD", false);
                return {
                    status: TransactionStatus.Error,
                    message: "Minting HUSD failed!",
                };
            }
        } catch {
            setLoader("mintHUSD", false);
            return {
                status: TransactionStatus.Error,
                message: "Minting HUSD failed!",
            };
        }
    };

    const invest = async (
        poolId: string,
        amount: number,
        setLoader: (name: string, value: boolean) => void
    ): Promise<TransactionStatusValues> => {
        return poolTransactionFlowHOF("Investment", amount, poolId, true, false, "deposit", setLoader);
    };

    return { repay: makePayment, borrow: borrow, distributeYields: distributeYields, mintHUSD, invest };
};
