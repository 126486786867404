export const enum TransactionStatus {
    Success = "success",
    Error = "error",
    Warning = "warning"
}

export interface TransactionStatusValues {
    status?: TransactionStatus;
    message?: string,
}

export interface FundsInterface {
    repay: (poolId: string, amount: number, setLoader: (name: string, value: boolean)=>void) => Promise<TransactionStatusValues>,
    borrow: (poolId: string, amount: number, setLoader: (name: string, value: boolean)=>void)=>Promise<TransactionStatusValues>,
    distributeYields: (poolId: string, amount: number, setLoader: (name: string, value: boolean)=>void)=>Promise<TransactionStatusValues>,
    mintHUSD: (amount: number, setLoader: (name: string, value: boolean)=>void)=>Promise<TransactionStatusValues>,
    invest: (poolId: string, amount: number, setLoader: (name: string, value: boolean)=>void)=>Promise<TransactionStatusValues>,
}
