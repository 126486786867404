import {useQuery, useQueryClient, UseQueryResult} from "react-query";
import {httpClient} from "../../api/axios";
import {Company} from "../../types/companies";

export const useGetAllCompaniesQuery = (): UseQueryResult<Company[], Error> => useQuery<Company[], Error>(
    'companies',
    async () => {
        const response = await httpClient.get('/companies');
        return response.data;
    },
);

export const useGetSingleCompanyQuery = (companyId: string | undefined): UseQueryResult<Company, Error> => {
    const queryClient = useQueryClient();

    return useQuery<Company, Error>(
        ['companies', companyId],
        async () => {
            if (companyId) {
                const response = await httpClient.get(`/companies/${companyId}`);
                return response.data;
            }
        },
        {
            placeholderData: (): Company | undefined => queryClient
                .getQueryData<Company[]>('companies')
                ?.find((c: Company) => c.id === companyId),
        },
    );
};