import React, { ReactElement, useCallback, useState } from "react";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import {
    Box,
    Card,
    CardMedia,
    Container,
    Divider,
    Grid,
    Typography,
    Alert,
} from "@mui/material";
import { AppTextField } from "../../../../components/AppFields";
import { AppButton } from "../../../../components";
import { AppRoutes } from "../../../../routes/AppRoutes";
import { useNavigate } from "react-router-dom";
import { useProjectForm, useProjectImageForm } from "./hooks";
import { DraftStatus } from "../../../../types/shared";
import { ProjectFormProps, ProjectFormValues, ProjectImageFormValues } from "./types";
import { ProjectValidationSchema } from "./validation";
import { isEmpty } from "lodash";
import AddIcon from "@mui/icons-material/Add";

export const ProjectForm: React.FC<ProjectFormProps> = ({
                                                            model = {},
                                                            onTabChange
                                                        }: ProjectFormProps): ReactElement => {
    const navigate = useNavigate();
    const { create, update } = useProjectForm();
    const { create: createImg } = useProjectImageForm();

    const [isValidate, setIsValidate] = useState(false);
    const [alertIsOpen, setAlertIsOpen] = useState(false);


    const handleFinish = useCallback(() => {
        navigate(`${AppRoutes.PROJECTS}`);
    }, [navigate]);

    const projectId = model.id;
    const projectImage = model.images?.length ? model?.images[0] : undefined;

    return (
        <Formik
            validateOnChange={isValidate}
            validateOnBlur={isValidate}
            initialValues={{
                name: model.name || "",
                overview: model.overview || "",
                draft: model.draft || DraftStatus.DraftIn,
                image: projectImage ? {
                    projectId,
                    url: projectImage?.urls ? projectImage?.urls["original"] : ""
                } : { projectId }
            }}
            validationSchema={ProjectValidationSchema}
            onSubmit={
                async (values: ProjectFormValues, actions: FormikHelpers<ProjectFormValues>) => {
                    if (projectId) {
                        await update(values, projectId);
                    } else {
                        await create(values, actions);
                        navigate(`${AppRoutes.PROJECTS}`);
                    }

                    if (values.image?.file && projectId) {
                        await createImg(values.image as ProjectImageFormValues, actions as FormikHelpers<ProjectImageFormValues>);
                    }
                    setAlertIsOpen(true);
                    setTimeout(() => {
                        setAlertIsOpen(false);
                    }, 3000);
                }
            }
        >
            {(formProps: FormikProps<ProjectFormValues>) => {
                const {
                    values,
                    errors,
                    handleChange,
                    setFieldValue,
                    isSubmitting,
                    validateForm,
                    submitForm,
                    dirty
                } = formProps;

                const handleFileChange = (event: React.FormEvent<HTMLInputElement>) => {
                    if (event.currentTarget.files?.length) {
                        const fileBlob = event.currentTarget.files[0];
                        const imageFile = URL.createObjectURL(fileBlob);
                        setFieldValue("image", { ...values.image, file: fileBlob, url: imageFile });
                    }
                };

                const handleSubmit = () => {
                    if (!isSubmitting) {
                        validateForm(values).then(async (invalidValues) => {
                            if (isEmpty(invalidValues)) {
                                await submitForm();
                            }
                            setIsValidate(true);
                        });
                    }
                };

                const redirect = () => {
                    if (!isSubmitting) {
                        if (onTabChange) {
                            onTabChange("manage");
                        } else {
                            navigate(`${AppRoutes.PROJECTS}`);
                        }
                    }
                };

                return (
                    <Form>
                        <Container sx={{ pt: 3, pb: 3 }}>
                            <Grid
                                rowSpacing={4}
                                container spacing={2}
                                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                            >
                                <Grid item xs={12} sm={6}>
                                    <AppTextField
                                        id="name"
                                        label="Name"
                                        required
                                        error={!!errors.name}
                                        helperText={errors.name}
                                        value={values.name}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <AppTextField
                                        id="overview"
                                        label="Overview"
                                        error={!!errors.overview}
                                        helperText={errors.overview}
                                        value={values.overview}
                                        onChange={handleChange}
                                    />
                                </Grid>
                            </Grid>
                        </Container>
                        <Divider />
                        <Container sx={{ pt: 3, pb: 3 }}>
                            <Grid
                                rowSpacing={4}
                                container spacing={2}
                                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                            >
                                <Grid item xs={12}>
                                    <Typography variant="h6" gutterBottom>
                                        Project Image
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Card variant="outlined">
                                        <CardMedia
                                            component="img"
                                            image={values.image ? values.image.url : ""}
                                        />
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <AppButton variant="contained" component="label">
                                        <AddIcon /> Select file
                                        <input type="file" name="file" id="file" hidden
                                               onChange={handleFileChange} />
                                    </AppButton>
                                </Grid>
                            </Grid>
                        </Container>
                        <Divider />
                        <Container sx={{ pt: 2, pb: 2 }}>
                            <Grid container spacing={2} rowSpacing={2}>
                                <Grid item xs={12}><Box display="flex" justifyContent="space-between">
                                    {alertIsOpen &&
                                    <Alert severity="success">Project data is successfully saved!</Alert>}
                                    <Box display="flex" flex={1} justifyContent="flex-end">
                                        <AppButton variant="outlined" onClick={handleFinish}>
                                            Cancel
                                        </AppButton>
                                        <AppButton
                                            // loading={isSubmitting}
                                            onClick={dirty || !model.id ? handleSubmit : redirect}
                                            // loadingIndicator={<CircularProgress color="inherit" size={16} />}
                                            variant="contained"
                                            disabled={isSubmitting}
                                        >
                                            {!dirty && model.id ? "Next" : "Save"}
                                        </AppButton>
                                    </Box>
                                </Box>
                                </Grid>
                            </Grid>
                        </Container>
                    </Form>
                );
            }
            }
        </Formik>
    );
};
