import React, { PropsWithChildren, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import { Theme, useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { Grid, useMediaQuery } from "@mui/material";
import { useAppStore } from "../store";
import TopBar from "../components/TopBar";
import SideBar from "../components/SideBar/SideBar";
import { LinkToPage } from "../utils";
import { AppRoutes } from "../routes/AppRoutes";

const TITLE_PRIVATE = "Helios Admin";
const MOBILE_SIDEBAR_ANCHOR = "left"; // 'right';
const DESKTOP_SIDEBAR_ANCHOR = "left"; // 'right';
export const SIDEBAR_WIDTH = 240; // 240px

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        minHeight: "100vh", // Full screen height
        paddingTop: 56,
        [theme.breakpoints.up("sm")]: {
            paddingTop: 64,
        },
    },
    header: {},
    shiftContent: {
        paddingLeft: DESKTOP_SIDEBAR_ANCHOR.includes("left") ? SIDEBAR_WIDTH : 0,
        paddingRight: DESKTOP_SIDEBAR_ANCHOR.includes("right") ? SIDEBAR_WIDTH : 0,
    },
    content: {
        flexGrow: 1, // Takes all possible space
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingTop: theme.spacing(4),
    },
    footer: {},
}));

function updateDocumentTitle(title = "") {
    if (title) {
        document.title = `${title} - ${TITLE_PRIVATE}`;
    } else {
        document.title = TITLE_PRIVATE;
    }
    return document.title;
}
// Certain paths are disabled for future use, later on they will be enabled and data used by landing page after refactoring
const SIDE_BAR_PRIVATE_ITEMS: Array<LinkToPage> = [
    {
        title: "Regional Pools",
        path: `${AppRoutes.POOLS}`,
        icon: "pool",
    },
    {
        title: "Blended Pool",
        path: `${AppRoutes.BLENDED_POOL}`,
        icon: "pool",
    },
    {
        title: "Projects",
        path: `${AppRoutes.PROJECTS}`,
        icon: "project",
    },
    // {
    //     title: "Team",
    //     path: `${AppRoutes.TEAM}`,
    //     icon: "team"
    // },
    // {
    //     title: "Success Stories",
    //     path: `${AppRoutes.SUCCESS_STORIES}`,
    //     icon: "success"
    // },
    // {
    //     title: "Testimonials",
    //     path: `${AppRoutes.TESTIMONIALS}`,
    //     icon: "testimonial"
    // },
    // {
    //     title: "Companies",
    //     path: `${AppRoutes.COMPANIES}`,
    //     icon: "company"
    // },
    {
        title: "Platform Impact",
        path: `${AppRoutes.PLATFORM_IMPACT}`,
        icon: "platform",
    },
];

const PrivateLayout: React.FC<PropsWithChildren> = ({ children }) => {
    const [state] = useAppStore();
    const [openSideBar, setOpenSideBar] = useState(false);
    const theme = useTheme();
    const classes = useStyles();
    const isDesktop = useMediaQuery(theme.breakpoints.up("md"), { defaultMatches: true });
    const navigation = useNavigate();

    const handleLogoClick = useCallback(() => {
        // Navigate to '/' when clicking on Logo/Menu icon when the SideBar is already visible
        navigation("/");
    }, [navigation]);

    const handleSideBarOpen = useCallback(() => {
        if (!openSideBar) setOpenSideBar(true);
    }, [openSideBar]);

    const handleSideBarClose = useCallback(() => {
        if (openSideBar) setOpenSideBar(false);
    }, [openSideBar]);

    const classRoot = clsx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop,
    });
    const title = updateDocumentTitle();
    const shouldOpenSideBar = isDesktop ? true : openSideBar;

    return (
        <Grid container direction="column" className={classRoot}>
            <Grid item className={classes.header} component="header">
                <TopBar
                    isAuthenticated={state.isAuthenticated}
                    title={title}
                    onMenu={shouldOpenSideBar ? handleLogoClick : handleSideBarOpen}
                />

                <SideBar
                    anchor={isDesktop ? DESKTOP_SIDEBAR_ANCHOR : MOBILE_SIDEBAR_ANCHOR}
                    open={shouldOpenSideBar}
                    variant={isDesktop ? "persistent" : "temporary"}
                    items={SIDE_BAR_PRIVATE_ITEMS}
                    onClose={handleSideBarClose}
                />
            </Grid>

            <Grid className={classes.content} component="main">
                {children}
            </Grid>
        </Grid>
    );
};

export default PrivateLayout;
