import * as yup from "yup";
import {FormProps} from "../../../../utils/FormProps";
import {FormikHelpers} from "formik";
import {Testimonial} from "../../../../types/testimonials";

export interface TestimonialFormValues {
    id?: string;
    first?: string | null;
    last?: string | null;
    jobTitle?: string | null;
    description?: string | null;
}

export const TestimonialValidationSchema = yup.object().shape({
    first: yup.string().required("Required"),
    last: yup.string().required("Required"),
    description: yup.string().required("Required"),
});

export type TestimonialFormProps = FormProps<Testimonial>;

export interface UseTestimonialForm {
    create: (values: TestimonialFormValues, actions: FormikHelpers<TestimonialFormValues>) => void
    update: (values: TestimonialFormValues, actions: FormikHelpers<TestimonialFormValues>, model: Testimonial) => void
}
