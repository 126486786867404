import {useMutation, UseMutationResult, useQueryClient} from 'react-query';
import {httpClient} from "../../api/axios";
import {
    CreateTeamMemberImageMutationVariables,
    CreateTeamMemberMutationVariables,
    UpdateTeamMemberMutationVariables
} from "./types";
import {TeamMember, TeamMemberImage} from "../../types/team";

export const useCreateTeamMemberMutation = (): UseMutationResult<TeamMember, Error, CreateTeamMemberMutationVariables> => {
    const queryClient = useQueryClient();

    return useMutation<TeamMember, Error, CreateTeamMemberMutationVariables>(
        async ({request}) => {
            const response = await httpClient.post('/team', request);
            return response.data;
        },
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries('team');
            },
        },
    );
};

export const useUpdateTeamMemberMutation = (): UseMutationResult<TeamMember, Error, UpdateTeamMemberMutationVariables> => {
    const queryClient = useQueryClient();

    return useMutation<TeamMember, Error, UpdateTeamMemberMutationVariables>(
        async ({request, teamMemberId}) => {
            const response = await httpClient.put(`/team/${teamMemberId}`, request);
            return response.data;
        },
        {
            onSuccess: async (data, variables) => {
                await queryClient.invalidateQueries(['team', variables.teamMemberId]);
            },
        },
    );
};

export const useCreateTeamMemberImageMutation = (): UseMutationResult<TeamMemberImage, Error, CreateTeamMemberImageMutationVariables> => {
    const queryClient = useQueryClient();

    return useMutation<TeamMemberImage, Error, CreateTeamMemberImageMutationVariables>(
        async ({teamMemberId, request}) => {
            const formData = new FormData();
            if (request?.file !== undefined) {
                formData.append('file', request?.file as Blob);
            }

            const response = await httpClient.post(`/team/${teamMemberId}/images`, formData, {headers: {'Content-Type': 'multipart/form-data'}});
            return response.data;
        },
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries('team-images');
            },
        },
    );
};
