import React from "react";
import {Box, Container, Divider, Paper, Typography,} from "@mui/material";
import {useParams} from "react-router-dom";
import {AppRoutes} from "../../../routes/AppRoutes";
import { AppCreateEditForm } from "../../../components/AppCreateEditForm";
import AppFormHeader from "../../../components/AppFormHeader";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import {useHeaderStyles} from "../../../utils/style";
import CompanyForm from "../Forms/ComapnyForm/CompanyForm";
import {useGetSingleCompanyQuery} from "../../../queries/companies";
import CompanyImageForm from "../Forms/ImageForm/CompanyImageForm";
import ApartmentIcon from "@mui/icons-material/Apartment";

function EditCompany() {
    const {id} = useParams();
    const classes = useHeaderStyles();
    const [value, setValue] = React.useState('overview');
    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const {data: company} = useGetSingleCompanyQuery(id || '');

    return (
        <AppCreateEditForm>
            <AppFormHeader level1Route={AppRoutes.COMPANIES}
                           level1Caption='Companies'
                           level1Icon={ApartmentIcon}
                           level2Caption={id!}
                           actionButton={null}/>
            <Paper className={classes.paper}>
                <Container sx={{pt: 3, pb: 2}}>
                    <Typography variant="h5" gutterBottom>
                        Edit Company
                    </Typography>
                </Container>
                <Divider/>
                <TabContext value={value}>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <TabList onChange={handleTabChange}>
                            <Tab label="Overview" value="overview"/>
                            <Tab label="Gallery" value="gallery"/>
                        </TabList>
                    </Box>
                    <TabPanel className={classes.panelTab} value="overview">
                        <CompanyForm model={company}/>
                    </TabPanel>
                    <TabPanel className={classes.panelTab} value="gallery">
                        <CompanyImageForm model={company}/>
                    </TabPanel>
                </TabContext>
            </Paper>
        </AppCreateEditForm>
    );
}

export default EditCompany;
