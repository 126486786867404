import { PoolBlockchainAccountValues, PoolBlockchainValues, PoolStatus } from "../../../../types/pools";
import { Address, ONE_DAY_TIME_UNIT } from "../../../../web3/constants";
import { ethers } from "ethers";
import abiPoolFactory from "../../../../contracts/abi/PoolFactory.json";
import abiIERC20MetaDataFactory from "../../../../contracts/abi/IERC20Metadata.json";
import abiPool from "../../../../contracts/abi/Pool.json";
import { formatBigInt } from "../../../../utils";
import { useQuery } from "react-query";
import { useWeb3React } from "@web3-react/core";

export function useInvestorBoard() {
    const { active, account, library } = useWeb3React();
    const CONTRACT_POOL_FACTORY = process.env.REACT_APP_CONTRACT_POOL_FACTORY!;
    const CONTRACT_USDC = process.env.REACT_APP_CONTRACT_USDC!;
    const BP_ADDRESS = process.env.REACT_APP_CONTRACT_BLENDED_POOL!;

    const provider = library;
    const poolFactory = new ethers.Contract(CONTRACT_POOL_FACTORY, abiPoolFactory, provider);
    const IERC20MetaData = new ethers.Contract(CONTRACT_USDC, abiIERC20MetaDataFactory, provider);
    let signer: any = null;

    if (provider) {
        signer = provider.getSigner();
    }

    const getPoolBlockchainData = async (poolId: string): Promise<PoolBlockchainValues> => {
        try {
            if (!poolId) throw new Error("Pool ID is required");

            const address = await poolFactory.pools(poolId);
            if (address === Address.Null) {
                return { poolStatus: PoolStatus.Draft } as PoolBlockchainValues;
            }

            const pool = new ethers.Contract(address, abiPool, provider);
            if (!pool) {
                return { poolStatus: PoolStatus.NotExist } as PoolBlockchainValues;
            }

            const [poolInfo, decimals, poolState, symbol, totalSupply] = await Promise.all([
                pool.poolInfo().catch(() => ({})),
                pool.decimals().catch(() => 18),
                pool.poolState().catch(() => -1),
                IERC20MetaData.symbol().catch(() => "UNKNOWN"),
                pool.totalSupply().catch(() => BigInt(0)),
            ]);

            const { lockupPeriod, investmentPoolSize, minInvestmentAmount } = poolInfo || {};
            let poolStatus: PoolStatus;
            switch (poolState) {
                case 0:
                    poolStatus = PoolStatus.Active;
                    break;
                case 1:
                    poolStatus = PoolStatus.Closed;
                    break;
                default:
                    poolStatus = PoolStatus.Error;
            }

            return {
                poolId,
                poolAddress: address,
                poolSize: formatBigInt(investmentPoolSize || BigInt(0), decimals),
                minInvestmentSize: formatBigInt(minInvestmentAmount || BigInt(0), decimals),
                lockupPeriod: ethers.toNumber(BigInt(lockupPeriod || 0)) / ONE_DAY_TIME_UNIT,
                totalDeposited: formatBigInt(totalSupply, decimals),
                symbol,
                poolStatus,
            };
        } catch (error) {
            console.error("Error fetching pool blockchain data:", error);
            return { poolStatus: PoolStatus.Error } as PoolBlockchainValues;
        }
    };

    const getAccountPoolBlockchainData = async (poolId: string): Promise<PoolBlockchainAccountValues> => {
        try {
            if (!poolId) throw new Error("Pool ID is required");

            const address = await poolFactory.pools(poolId);
            if (address === Address.Null) {
                return {} as PoolBlockchainAccountValues;
            }

            const pool = new ethers.Contract(address, abiPool, signer);
            if (!pool) {
                return {} as PoolBlockchainAccountValues;
            }

            // Use Promise.allSettled to handle both fulfilled and rejected promises
            const results = await Promise.allSettled([
                pool.yields(BP_ADDRESS), // Fetch yields
                pool.decimals(), // Fetch decimals
                pool.symbol(), // Fetch symbol
                pool.balanceOf(BP_ADDRESS), // Fetch balance
                pool.unlockedToWithdraw(BP_ADDRESS), // Fetch unlockedToWithdraw
                pool.getHolderUnlockDate(BP_ADDRESS), // Fetch unlockDate
            ]);

            // Extract values from results, using fallback values in case of rejection
            const yearnedYield = results[0].status === "fulfilled" ? results[0].value : BigInt(0);
            const decimals = results[1].status === "fulfilled" ? results[1].value : 18;
            const symbol = results[2].status === "fulfilled" ? results[2].value : "---";
            const balanceOf = results[3].status === "fulfilled" ? results[3].value : BigInt(0);
            const availableToWithdraw = results[4].status === "fulfilled" ? results[4].value : BigInt(0);
            const unlockDateRaw = results[5].status === "fulfilled" ? results[5].value : null;

            const unlockDate = unlockDateRaw ? BigInt(Math.floor(Number(unlockDateRaw))) : unlockDateRaw;

            return {
                yearnedYield: formatBigInt(yearnedYield, decimals),
                availableToWithdraw: formatBigInt(availableToWithdraw, decimals),
                investedAmount: formatBigInt(balanceOf, decimals),
                isInvested: BigInt(balanceOf) > BigInt(0),
                accountPoolBalance: formatBigInt(balanceOf, decimals) + formatBigInt(yearnedYield, decimals),
                symbol,
                decimals,
                unlockDate: unlockDate !== null ? new Date(Number(unlockDate) * 1000).toLocaleDateString() : unlockDate,
            } as PoolBlockchainAccountValues;
        } catch (error: any) {
            console.error("Error fetching account pool blockchain data:", error);

            // Handle general read error
            handleRPCError(error, "general");

            return {} as PoolBlockchainAccountValues;
        }
    };

    // Function to handle and log specific RPC read errors
    const handleRPCError = (error: any, context: string) => {
        if (error.code === -32603) {
            console.log(`MetaMask - RPC Error (${context}): Internal JSON-RPC error.`, error);

            if (error?.data?.message) {
                console.log(`Revert Reason for ${context}:`, error.data.message);
            } else if (error?.data?.cause?.message) {
                console.log(`Revert Cause for ${context}:`, error.data.cause.message);
            } else {
                console.log(`Unknown error occurred while reading ${context}`);
            }
        } else {
            console.log(`Unknown error occurred while reading ${context}`, error);
        }
    };

    const usePoolBlockchainData = (poolId: string) => {
        return useQuery(["poolBlockchainData", poolId], () => getPoolBlockchainData(poolId), {
            refetchOnMount: "always",
            refetchOnWindowFocus: false,
            retry: 3,
            retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000), // Exponential backoff
            onError: (error) => {
                console.error("Error fetching pool blockchain data:", error);
            },
        });
    };

    const useAccountPoolBlockchainData = (poolId: string) => {
        return useQuery(["accountPoolBlockchainData", poolId], () => getAccountPoolBlockchainData(poolId), {
            refetchOnMount: false,
            refetchOnWindowFocus: false,
        });
    };

    return {
        usePoolBlockchainData,
        useAccountPoolBlockchainData,
        getPoolBlockchainData,
        getAccountPoolBlockchainData,
    };
}
