import React from "react";
import { useHeaderStyles } from "../../../utils/style";
import { AppRoutes } from "../../../routes/AppRoutes";
import SolarPowerIcon from "@mui/icons-material/SolarPower";
import AppFormHeader from "../../../components/AppFormHeader";
import { Paper } from "@mui/material";
import { AppCreateEditForm } from "../../../components/AppCreateEditForm";
import PlatformImpactForm from "../Forms/PlatformImpactForm/PlatformImpactForm";


function PlatformImpactIndex() {
    const classes = useHeaderStyles();

    return (
        <AppCreateEditForm>
            <AppFormHeader level1Route={AppRoutes.PLATFORM_IMPACT}
                           level1Caption="Platform Impact"
                           level1Icon={SolarPowerIcon}
                           level2Caption={null}
                           actionButton={null} />
            <Paper className={classes.paper}>
                <PlatformImpactForm />
            </Paper>
        </AppCreateEditForm>
    );
}

export default PlatformImpactIndex;
