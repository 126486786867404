
import { useDeleteMutation } from "../queries/shared/mutations";
import { ListNames } from "../types/shared";
import { useNavigate } from "react-router-dom";

interface UseListForm {
    remove: (
        id: string,
        setLoading:(value:boolean)=>void,
        url?: ListNames,
        ) => void;
}

export const useListForm = (name: ListNames): UseListForm => {
    const deleteMutation = useDeleteMutation(name);
    const navigate = useNavigate();


    const remove = async (id: string, setLoading: (value: boolean)=>void, url?: ListNames) => {
        setLoading(true)
        try {
            const data = await deleteMutation.mutateAsync(
                { id },
                {
                    onSuccess: async () => {
                        setLoading(false)
                    }
                }
            );
            if(url){
                navigate(`/${url}`);
            }
            console.log("delete data", data);
        } catch (error) {
        }
    };

    return { remove };
};
