import React, { ReactNode, FormHTMLAttributes } from 'react';
import { Box, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {editFormStyle} from '../../utils/style';

export const useStyles = makeStyles(() => ({
  formBody: {
    ...editFormStyle(),
  },
}));

interface Props extends FormHTMLAttributes<HTMLFormElement> {
  children: ReactNode;
}

export const AppCreateEditForm: React.FC<Props> = ({ children, ...resOfProps }) => {
  const classes = useStyles();
  return (
      <Grid container direction="column" alignItems="center">
        <Box className={classes.formBody}>{children}</Box>
      </Grid>
  );
};
