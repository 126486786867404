import {FormikHelpers} from "formik";
import {useCreateTestimonialImageMutation} from "../../../../queries/testimonials/mutations";
import {CreateTestimonialImageRequest} from "../../../../queries/testimonials/types";
import {TestimonialImageFormValues, UseTestimonialImageForm} from "./types";

const useTestimonialImageForm = (): UseTestimonialImageForm => {
    const createMutation = useCreateTestimonialImageMutation();

    const create = async (values: TestimonialImageFormValues, actions: FormikHelpers<TestimonialImageFormValues>) => {
        try {
            const request: CreateTestimonialImageRequest = values;

            await createMutation.mutateAsync({testimonialId: values.testimonialId, request}, {
                onSuccess: async () => {
                },
            });
        } catch (error) {
        }
    };

    return {
        create
    };
};

export default useTestimonialImageForm;