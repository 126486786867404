import { useInvestorBoard } from "../../hooks/useInvestorBoard";
import { Box, LinearProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

interface BlockchainDataBlockProps {
    poolId: string | undefined;
}
export const BlockchainDataBlock = ({ poolId }: BlockchainDataBlockProps) => {
    const { usePoolBlockchainData } = useInvestorBoard();

    const { data, isLoading, error, isFetched, refetch } = usePoolBlockchainData(poolId || "");

    // Internal state to store last successful data
    const [internalData, setInternalData] = useState(data);

    useEffect(() => {
        // To fix the issue where initial fetch returns null data
        setTimeout(() => {
            refetch().then((dataX) => {
                setInternalData(dataX.data);
            });
        }, 1000);
    }, [isFetched, data]);

    if (isLoading) {
        return <LinearProgress />;
    }

    if (error) {
        return <div>Error Loading Data</div>;
    }

    return (
        <Box sx={{ display: "flex", gap: "12px", flexDirection: "column" }}>
            <Typography variant="body2" color="text.secondary">
                Pool Size: {internalData?.poolSize} {internalData?.symbol}
            </Typography>
            <Typography variant="body2" color="text.secondary">
                Total Deposited: {internalData?.totalDeposited} {internalData?.symbol}
            </Typography>
            <Typography variant="body2" color="text.secondary">
                Min Investment Size: {internalData?.minInvestmentSize} {internalData?.symbol}
            </Typography>
            <Typography variant="body2" color="text.secondary">
                Lockup Period: {internalData?.lockupPeriod} day(s)
            </Typography>
        </Box>
    );
};
