import { PoolStatus } from "../types/pools";
import { PoolPageTabs } from "../constants";
import { ethers } from "ethers";

export const IS_SERVER = typeof window === "undefined";

export function RowsPerPage(): number {
    return 10;
}

interface SwitchTabProps {
    newValue: PoolPageTabs;
    provider: any;
    pool: any;
    updateValue: (newValue: PoolPageTabs) => void;
}
export const switchTab = (props: SwitchTabProps) => {
    const { newValue, provider, pool, updateValue } = props;
    if (!provider && newValue !== "overview" && newValue !== "blockchain") {
        alert("Please connect your wallet first.");
        return;
    }

    if (newValue === "funds" && pool.status === PoolStatus.Draft) {
        alert("Please create the pool contract first.");
        return;
    }

    updateValue(newValue);
};

// Function to convert BigNumber to decimal
const bigNumberToDecimal = (value: ethers.BigNumberish, decimals: number): number =>
    parseFloat(ethers.formatUnits(value, decimals));

// Function to convert decimal to BigNumber
const decimalToBigNumber = (value: number, decimals: number): ethers.BigNumberish =>
    ethers.parseUnits(value.toString(), decimals);

// Updated transformNumber function
export const transformNumber = (value: ethers.BigNumberish | number, decimals: number, reverse: boolean) => {
    if (typeof value !== "number") {
        if (reverse) {
            return bigNumberToDecimal(value, decimals);
        }
        return ethers.toNumber(value);
    }
    if (reverse) {
        return value / 10 ** decimals;
    }
    return ethers.toNumber(decimalToBigNumber(value, decimals));
};

export const formatBigInt = (value: number | string, decimals: number) => {
    return Number(Number(ethers.formatUnits(BigInt(value), decimals).toString()).toFixed(2));
};
