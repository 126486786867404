import React from "react";
import { Container, Divider, Paper, Typography } from "@mui/material";
import { AppRoutes } from "../../../routes/AppRoutes";
import { AppCreateEditForm } from "../../../components/AppCreateEditForm";
import AppFormHeader from "../../../components/AppFormHeader";
import { useHeaderStyles } from "../../../utils/style";
import TestimonialForm from "../Forms/TestimonialForm/TestimonialForm";
import BallotIcon from "@mui/icons-material/Ballot";

function CreateTestimonial() {
    const classes = useHeaderStyles();
    return (
        <AppCreateEditForm>
            <AppFormHeader level1Route={AppRoutes.TESTIMONIALS}
                           level1Caption="Testimonials"
                           level1Icon={BallotIcon}
                           level2Caption="Create Testimonial"
                           actionButton={null} />
            <Paper className={classes.paper}>
                <Container sx={{ pt: 3, pb: 2 }}>
                    <Typography variant="h5" gutterBottom>
                        Create Testimonial
                    </Typography>
                </Container>
                <Divider />
                <TestimonialForm />
            </Paper>
        </AppCreateEditForm>
    );
}

export default CreateTestimonial;
