import React from "react";
import useInvestorList from "./useInvestorList";
import InvestorRow from "./InvestorRow";
import { PoolPageTabs } from "../../constants";
import { LinearProgress, Typography } from "@mui/material";

interface InvestorListProps {
    poolId: string | undefined;
    onTabChange?: (e: React.SyntheticEvent, value: PoolPageTabs) => void;
}

const InvestorList = ({ poolId }: InvestorListProps) => {
    const { useInvestorsListQuery } = useInvestorList();
    const { data, isLoading, error } = useInvestorsListQuery(poolId || "");

    return (
        <>
            {isLoading && <LinearProgress />}
            {error && <div>Error Loading Data</div>}
            {data && data.length === 0 && <Typography variant="h1">No Investors Found</Typography>}
            {data?.map((holder) => (
                <InvestorRow key={holder} holder={holder} poolId={poolId as string} />
            ))}
        </>
    );
};
export default InvestorList;
