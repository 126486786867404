import * as yup from "yup";

export const RegionalPoolValidationSchema = yup.object().shape({
    name: yup.string().required("Required"),
    status: yup.string().required("Required"),
    region: yup.string().required("Required"),
    overview: yup.string().required("Required"),
    description: yup.string().required("Required"),
    descriptionTitle: yup.string().required("Required"),
    annualEmissionsAverted: yup.number().moreThan(-1).integer(),
    annualSolarEnergyProduced: yup.number().moreThan(-1).integer(),
});

export const BlendedPoolValidationSchema = yup.object().shape({
    name: yup.string().required("Required"),
    overview: yup.string().required("Required"),
    description: yup.string().required("Required"),
    descriptionTitle: yup.string().required("Required"),
});
