import Safe from "@safe-global/protocol-kit";
import { apiKit, ethAdapter } from "./adapters";
import { SafeTransaction } from "@safe-global/safe-core-sdk-types/dist/src/types";
import { ethers } from "ethers";
import abiRegionalPool from "../contracts/abi/Pool.json";
import abiPoolFactory from "../contracts/abi/PoolFactory.json";
import abiBlendedPool from "../contracts/abi/BlendedPool.json";
import { SystemIDs } from "../constants";

const protocolKit = await Safe.create({
    ethAdapter,
    safeAddress: process.env.REACT_APP_GNOSIS_SAFE_ADDRESS as string,
});

async function createSafeTransaction(to: string, value: string, data: string) {
    console.log("Creating safe transaction");
    const nextNonce = await apiKit.getNextNonce(process.env.REACT_APP_GNOSIS_SAFE_ADDRESS as string);

    return protocolKit.createTransaction({
        transactions: [
            {
                to,
                value,
                data,
            },
        ],
        options: {
            nonce: nextNonce,
        },
    });
}

async function proposeTransaction(safeTransaction: SafeTransaction, signer: ethers.Signer) {
    console.log("Proposing transaction");
    const senderAddress = await signer.getAddress();
    const safeTxHash = await protocolKit.getTransactionHash(safeTransaction);
    const signature = await protocolKit.signHash(safeTxHash);

    return await apiKit.proposeTransaction({
        safeAddress: await protocolKit.getAddress(),
        safeTransactionData: safeTransaction.data,
        safeTxHash,
        senderAddress,
        senderSignature: signature.data,
        origin: "web",
    });
}

async function safeBorrowPool(amount: string, signer: ethers.Signer, poolId: string, decimals: number = 6) {
    let poolAddress: string;
    let abi_interface: ethers.Interface;

    if (poolId === SystemIDs.BlendedPoolID) {
        console.log("Borrowing from blended pool");
        poolAddress = process.env.REACT_APP_CONTRACT_BLENDED_POOL!;
        abi_interface = new ethers.Interface(abiBlendedPool);
    } else {
        console.log("Borrowing from regional pool");
        const CONTRACT_POOL_FACTORY = process.env.REACT_APP_CONTRACT_POOL_FACTORY!;
        console.log("Pool factory address: ", CONTRACT_POOL_FACTORY);
        const poolFactory = new ethers.Contract(CONTRACT_POOL_FACTORY, abiPoolFactory, signer);
        poolAddress = await poolFactory.pools(poolId);
        abi_interface = new ethers.Interface(abiRegionalPool);
    }

    console.log("Pool address: ", poolAddress);
    const parsedAmount = ethers.parseUnits(amount, decimals);
    const signerAddress = await signer.getAddress();

    const encodedFunctionCall = abi_interface.encodeFunctionData("borrow", [signerAddress, parsedAmount]);
    console.log("Encoded function call: ", encodedFunctionCall);
    const safeTransaction = await createSafeTransaction(poolAddress, "0", encodedFunctionCall);
    return await proposeTransaction(safeTransaction, signer);
}

export { safeBorrowPool };
