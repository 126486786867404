import React, { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PoolFormProps } from "../PoolForm/types";
import {
    Alert,
    Box,
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Grid,
    Typography,
} from "@mui/material";
import { AppRoutes } from "../../../../routes/AppRoutes";
import { AppButton } from "../../../../components";
import { useWeb3React } from "@web3-react/core";
import { AppNumberField } from "../../../../components/AppFields";
import { useFunds } from "./hooks";
import { TransactionStatus, TransactionStatusValues } from "./types";
import { useBlockChain } from "../BlockchainForm/hooks";
import { PoolBlockchainFormValues } from "../BlockchainForm/types";
import { PoolStatus } from "../../../../types/pools";
import { dummyReactSyntheticEvent, PoolPageTabs, SystemIDs, whiteListedTokens } from "../../../../constants";
import { safeBorrowPool } from "../../../../gnosis/gnosisTransactions";

export const FundsManageForm: React.FC<PoolFormProps> = (props: PoolFormProps): ReactElement => {
    const { account, library: provider } = useWeb3React();

    // kick out user if not connected
    useEffect(() => {
        if (provider === undefined) {
            props.onTabChange && props.onTabChange(dummyReactSyntheticEvent, PoolPageTabs.Overview);
        }
    }, [provider]);

    const navigate = useNavigate();
    const { repay, borrow, distributeYields, mintHUSD, invest } = useFunds();
    const [pool, setPool] = React.useState<PoolBlockchainFormValues>({} as PoolBlockchainFormValues);
    const [adminWalletBalances, setAdminWalletBalances] = useState<{
        eth: string | number;
        stablecoin: string | number;
    }>({
        eth: 0,
        stablecoin: 0,
    });
    const { getPool_bch, getAdminBalances } = useBlockChain();
    const [txData, setTxData] = useState<TransactionStatusValues>();
    const [isRender, setIsRender] = React.useState(false);
    const [drawdownLabel, setDrawdownLabel] = React.useState("");

    const [symbol, setSymbol] = React.useState("");

    const [drawdownAlert, setDrawdownAlert] = useState(false);
    const [paymentAlert, setPaymentAlert] = useState(false);
    const [yieldDistributionAlert, setYieldDistributionAlert] = useState(false);
    const [husdMintAlert, setHUSDMintAlert] = useState(false);
    const [investmentAlert, setInvestmentAlert] = useState(false);

    const [paymentAmount, setPaymentAmount] = React.useState(0);
    const [drawdownAmount, setDrawdownAmount] = React.useState(0);
    const [yieldDistributionAmount, setYieldDistributionAmount] = React.useState<number>(0);
    const [husdMintAmount, setHUSDMintAmount] = useState(0);
    const [investmentAmount, setInvestmentAmount] = useState(0);

    const [openPayment, setOpenPayment] = React.useState(false);
    const [openDrawdown, setOpenDrawdown] = React.useState(false);
    const [openMetamask, setOpenMetamask] = React.useState(false);
    const [openYieldsDistribution, setOpenYieldsDistribution] = React.useState(false);
    const [openHUSDMint, setOpenHUSDMint] = React.useState(false);
    const [openInvestment, setOpenInvestment] = React.useState(false);

    const [paymentLoader, setPaymentLoader] = React.useState(false);
    const [drawdownLoader, setDrawdownLoader] = React.useState(false);
    const [yieldDistributionLoader, setYieldDistributionLoader] = React.useState(false);
    const [husdMintLoader, setHUSDMintLoader] = React.useState(false);
    const [investmentLoader, setInvestmentLoader] = React.useState(false);

    const setLoader = (name: string, value: boolean) => {
        if (name === "Repayment") {
            setPaymentLoader(value);
        }
        if (name === "Borrow") {
            setDrawdownLoader(value);
        }

        if (name === "Distribute Yield") {
            console.log("setYieldDistributionLoader", value);
            setYieldDistributionLoader(value);
        }

        if (name === "mintHUSD") {
            setHUSDMintLoader(value);
        }

        if (name === "Investment") {
            setInvestmentLoader(value);
        }
    };

    const poolId = props.model?.id;

    useEffect(() => {
        if (props.model?.id) {
            getPool_bch(props.model?.id as string).then((pool) => {
                setPool(pool as PoolBlockchainFormValues);
                if (pool.symbol === whiteListedTokens.HUSD) {
                    setSymbol(whiteListedTokens.HUSD);
                } else {
                    setSymbol(whiteListedTokens.stablecoin);
                }
                getAdminBalances(
                    pool.symbol === whiteListedTokens.HUSD ? whiteListedTokens.HUSD : whiteListedTokens.stablecoin
                ).then((balances) => {
                    setAdminWalletBalances(balances);
                });
                setIsRender(true);
            });
        }
    }, [props.model?.id, txData]);

    // one big useMemo for values
    const poolData = useMemo(() => {
        return {
            poolId: poolId,
            poolSize: pool?.poolSize || 0,
            symbol: pool?.symbol,
            stableCoinBalance: pool?.stableCoinBalance || 0,
            yieldBalance: pool?.yieldBalance || 0,
            tokenSupply: pool?.tokenSupply || 0,
            poolTokenName: pool?.poolTokenName,
            poolTokenSymbol: pool?.poolTokenSymbol,
            poolStatus: pool?.poolStatus,
            currentDebt: pool?.currentDebt || 0,
            holdersInQueue: pool?.holdersInQueue || [],
            decimals: pool?.decimals || 6,
        };
    }, [
        poolId,
        pool.poolSize,
        pool.symbol,
        pool.stableCoinBalance,
        pool.yieldBalance,
        pool.tokenSupply,
        pool.poolTokenName,
        pool.poolTokenSymbol,
        pool.poolStatus,
        pool.currentDebt,
        pool.holdersInQueue,
        pool.decimals,
    ]);

    const {
        poolSize,
        stableCoinBalance,
        currentDebt,
        tokenSupply,
        poolTokenName,
        poolTokenSymbol,
        holdersInQueue,
        yieldBalance,
        poolStatus,
        decimals,
    } = poolData;

    useEffect(() => {
        if (drawdownAmount > stableCoinBalance) {
            setDrawdownLabel(`The Borrow amount can't be more then ${stableCoinBalance} ${symbol}`);
        } else {
            setDrawdownLabel(`Borrow amount`);
        }
    }, [drawdownAmount]);

    const handleBack = () => {
        if (props?.onTabChange) {
            if (pool.poolId === SystemIDs.BlendedPoolID) {
                props.onTabChange(dummyReactSyntheticEvent, PoolPageTabs.Overview);
            } else {
                props.onTabChange(dummyReactSyntheticEvent, PoolPageTabs.Manage);
            }
        } else {
            navigate(`${AppRoutes.POOLS}`);
        }
    };

    const handleFinish = useCallback(() => {
        navigate(`${AppRoutes.POOLS}`);
    }, [navigate]);

    const handleClosePayment = () => {
        setOpenPayment(false);
    };
    const handleCloseDrawdown = () => {
        setOpenDrawdown(false);
    };
    const handlePayment = () => {
        // Get PoolFactory contract object

        console.log("signer", account);
        if (account == null) {
            setOpenPayment(false);
            setOpenMetamask(true);
            throw Error("Account is null");
        }
        setOpenPayment(false);
        repay(poolId as string, paymentAmount, setLoader)
            .then(({ status, message }) => {
                setTxData({ status, message });
                setPaymentAmount(0);
                setPaymentAlert(true);
                setTimeout(() => {
                    setPaymentAlert(false);
                    setTxData({});
                }, 4000);
            })
            .catch(() => {});
    };

    const handleDrawdown = () => {
        if (account == null) {
            setOpenDrawdown(false);
            setOpenMetamask(true);
            throw Error("Account is null");
        }
        setOpenDrawdown(false);

        const signer = provider?.getSigner();
        console.log("Forming a proposal to borrow", drawdownAmount.toString(), poolId as string);
        safeBorrowPool(drawdownAmount.toString(), signer, poolId as string).then((res: any) => {
            setTxData({ status: TransactionStatus.Success, message: "Proposed successfully" });
            setDrawdownAlert(true);
            setDrawdownAmount(0);
        });
    };

    const handleCloseMetamask = () => {
        setOpenMetamask(false);
    };

    const handleCloseYieldsDistribution = () => {
        setOpenYieldsDistribution(false);
    };

    const handleYieldsDistribution = () => {
        // Get PoolFactory contract object

        console.log("signer", account);
        if (account == null) {
            setOpenYieldsDistribution(false);
            setOpenMetamask(true);
            throw Error("Account is null");
        }
        setOpenYieldsDistribution(false);
        distributeYields(poolId as string, yieldDistributionAmount, setLoader)
            .then(({ status, message }) => {
                setTxData({ status, message });
                setYieldDistributionAlert(true);
                setYieldDistributionAmount(0);
                setTimeout(() => {
                    setYieldDistributionAlert(false);
                    setTxData({});
                }, 4000);
            })
            .catch(() => {});
    };

    const handleHUSDMint = () => {
        if (account == null) {
            setOpenHUSDMint(false);
            setOpenMetamask(true);
            throw Error("Account is null");
        }
        setOpenHUSDMint(false);
        mintHUSD(husdMintAmount, setLoader)
            .then(({ status, message }) => {
                setTxData({ status, message });
                setHUSDMintAmount(0);
                setHUSDMintAlert(true);
                setTimeout(() => {
                    setTxData({});
                    setHUSDMintAlert(false);
                }, 4000);
            })
            .catch(() => {});
    };
    const handleInvest = () => {
        if (account == null) {
            setOpenInvestment(false);
            setOpenMetamask(true);
            throw Error("Account is null");
        }
        setOpenInvestment(false);
        invest(poolId as string, investmentAmount, setLoader)
            .then(({ status, message }) => {
                setTxData({ status, message });
                setInvestmentAmount(0);
                setInvestmentAlert(true);
                setTimeout(() => {
                    setTxData({});
                    setInvestmentAlert(false);
                }, 4000);
            })
            .catch(() => {});
    };

    return (
        <>
            {isRender && (
                <Container sx={{ pt: 2, pb: 2 }}>
                    <Grid container spacing={2} rowSpacing={2} alignItems="center" justifyContent="right">
                        <Dialog
                            open={openMetamask}
                            onClose={handleCloseMetamask}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"You are not connected to Metamask!"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    For sending data to blockchain you have to connect to Metamask.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseMetamask}>OK</Button>
                            </DialogActions>
                        </Dialog>
                        {props.model?.id && (
                            <>
                                {/*Admin Wallet balances*/}
                                <Grid item xs={4}>
                                    <Typography gutterBottom>Admin Eth balance</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    {adminWalletBalances.eth} ETH
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography gutterBottom>Admin stablecoin balance</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    {adminWalletBalances.stablecoin} {symbol}
                                </Grid>

                                {/* Current stablecoin debt and pool balance */}
                                <Grid item xs={12} marginBottom={5}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography gutterBottom>Stablecoin debt</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    {currentDebt} {symbol}
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography gutterBottom>Stablecoin balance</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    {stableCoinBalance} {symbol}
                                </Grid>

                                {/* Pool target size */}
                                {poolId !== SystemIDs.BlendedPoolID && (
                                    <>
                                        <Grid item xs={12} marginBottom={5}>
                                            <Divider />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography gutterBottom>Pool target size</Typography>
                                        </Grid>
                                        <Grid item xs={8} sm={8}>
                                            {poolSize} {symbol}
                                        </Grid>
                                    </>
                                )}

                                {/*Total supply*/}
                                <Grid item xs={12} marginBottom={5}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography gutterBottom>
                                        {poolTokenName} ({poolTokenSymbol}) Token Supply
                                    </Typography>
                                </Grid>
                                <Grid item xs={8} sm={8}>
                                    {tokenSupply} {poolTokenSymbol}
                                </Grid>

                                {/*Yield balance*/}
                                <Grid item xs={12} marginBottom={5}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography gutterBottom>Yield balance</Typography>
                                </Grid>
                                <Grid item xs={8} sm={8}>
                                    {yieldBalance} {symbol}
                                </Grid>

                                {/* Functionality for Helios USD Pools */}
                                {symbol === whiteListedTokens.HUSD && (
                                    <>
                                        <Grid item xs={12} marginBottom={5}>
                                            <Divider />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography gutterBottom align="center" paragraph>
                                                The functionality listed below is only accessible when the asst is{" "}
                                                {whiteListedTokens.HUSD}.
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} marginBottom={5}>
                                            <Divider />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography gutterBottom>Mint {whiteListedTokens.HUSD}</Typography>
                                        </Grid>
                                        <Grid item xs={8} sm={8}>
                                            <AppNumberField
                                                id="mint_husd"
                                                label={`Mint ${whiteListedTokens.HUSD}`}
                                                type="number"
                                                required
                                                value={husdMintAmount}
                                                setValue={(id, value) => setHUSDMintAmount(value)}
                                                suffix={` ${whiteListedTokens.HUSD}`}
                                            />
                                        </Grid>
                                        <Box marginY={1} display="flex" justifyContent="flex-end">
                                            {husdMintAlert && (
                                                <Alert severity={txData?.status}>
                                                    {txData?.message?.slice(0, 100)}
                                                </Alert>
                                            )}
                                            <AppButton
                                                color="success"
                                                onClick={() => {
                                                    setOpenHUSDMint(true);
                                                }}
                                                loading={husdMintLoader}
                                                disabled={husdMintLoader || !husdMintAmount}
                                            >
                                                Mint
                                            </AppButton>
                                            <Dialog
                                                open={openHUSDMint}
                                                onClose={handleHUSDMint}
                                                aria-labelledby="alert-dialog-title"
                                                aria-describedby="alert-dialog-description"
                                            >
                                                <DialogTitle id="alert-dialog-title">
                                                    <Typography variant="h6" gutterBottom>
                                                        Mint {whiteListedTokens.HUSD}
                                                    </Typography>
                                                    <Typography variant="caption" gutterBottom>
                                                        Are you sure you want to mint {husdMintAmount}{" "}
                                                        {whiteListedTokens.HUSD}?
                                                    </Typography>
                                                </DialogTitle>
                                                <DialogActions>
                                                    <Button
                                                        onClick={() => {
                                                            setOpenHUSDMint(false);
                                                        }}
                                                    >
                                                        Disagree
                                                    </Button>
                                                    <Button onClick={handleHUSDMint} autoFocus>
                                                        Agree
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>
                                        </Box>
                                        <Grid item xs={12} marginBottom={5}>
                                            <Divider />
                                        </Grid>

                                        <Grid item xs={4}>
                                            <Typography gutterBottom>Invest</Typography>
                                        </Grid>
                                        <Grid item xs={8} sm={8}>
                                            <AppNumberField
                                                id="invest"
                                                label="Invest amount"
                                                type="number"
                                                required
                                                value={investmentAmount}
                                                setValue={(id, value) => setInvestmentAmount(value)}
                                                suffix={` ${symbol}`}
                                            />
                                        </Grid>
                                        <Box marginY={1} display="flex" justifyContent="flex-end">
                                            {investmentAlert && (
                                                <Alert severity={txData?.status}>
                                                    {txData?.message?.slice(0, 100)}
                                                </Alert>
                                            )}
                                            <AppButton
                                                color="success"
                                                onClick={() => {
                                                    setOpenInvestment(true);
                                                }}
                                                loading={investmentLoader}
                                                disabled={investmentLoader || !investmentAmount}
                                            >
                                                Invest
                                            </AppButton>
                                            <Dialog
                                                open={openInvestment}
                                                onClose={handleInvest}
                                                aria-labelledby="alert-dialog-title"
                                                aria-describedby="alert-dialog-description"
                                            >
                                                <DialogTitle id="alert-dialog-title">
                                                    <Typography variant="h6" gutterBottom>
                                                        Invest {symbol}
                                                    </Typography>
                                                    <Typography variant="caption" gutterBottom>
                                                        Are you sure you want to invest {investmentAmount} {symbol}?
                                                    </Typography>
                                                </DialogTitle>
                                                <DialogActions>
                                                    <Button onClick={() => setOpenInvestment(false)}>Disagree</Button>
                                                    <Button onClick={handleInvest} autoFocus>
                                                        Agree
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>
                                        </Box>
                                    </>
                                )}

                                {poolId !== SystemIDs.BlendedPoolID && (
                                    <>
                                        <Grid item xs={12} marginBottom={5}>
                                            <Divider />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography gutterBottom align="center" paragraph>
                                                The functionality listed below is only accessible when the pool is in a{" "}
                                                <strong>closed state</strong>.
                                            </Typography>
                                            <Typography gutterBottom align="center" paragraph>
                                                To perform actions such as distributing yield, repaying, and borrowing,
                                                please <strong>ensure that the pool is closed</strong>.
                                            </Typography>
                                        </Grid>
                                    </>
                                )}
                                <Grid item xs={12} marginBottom={5}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography gutterBottom>Distribute yields</Typography>
                                </Grid>
                                <Grid item xs={8} sm={8}>
                                    <AppNumberField
                                        id="distribute_yields"
                                        label="Distribute Yield"
                                        type="number"
                                        required
                                        value={yieldDistributionAmount}
                                        setValue={(id, value) => setYieldDistributionAmount(value)}
                                        suffix={` ${symbol}`}
                                    />
                                </Grid>
                                <Box marginY={1} display="flex" justifyContent="flex-end">
                                    {yieldDistributionAlert && (
                                        <Alert severity={txData?.status}>{txData?.message?.slice(0, 100)}</Alert>
                                    )}
                                    <AppButton
                                        color="success"
                                        onClick={() => {
                                            setOpenYieldsDistribution(true);
                                        }}
                                        loading={yieldDistributionLoader}
                                        disabled={
                                            yieldDistributionLoader ||
                                            !yieldDistributionAmount ||
                                            (poolStatus === PoolStatus.Active && poolId !== SystemIDs.BlendedPoolID)
                                        }
                                    >
                                        Distribute
                                    </AppButton>
                                    <Dialog
                                        open={openYieldsDistribution}
                                        onClose={handleCloseYieldsDistribution}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >
                                        <DialogTitle id="alert-dialog-title">
                                            {`Distribute Yields: ${yieldDistributionAmount} ${symbol}.`}
                                            <br />
                                            <Typography variant="caption" gutterBottom>
                                                Are you sure you want to distribute yields?
                                                <br />
                                                The amount will be taken from your wallet and distributed to the pool
                                                holders.
                                            </Typography>
                                        </DialogTitle>
                                        <DialogActions>
                                            <Button onClick={handleCloseYieldsDistribution}>Disagree</Button>
                                            <Button onClick={handleYieldsDistribution} autoFocus>
                                                Agree
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                </Box>
                                <Grid item xs={12} marginBottom={5}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography gutterBottom>Borrow</Typography>
                                </Grid>
                                <Grid item xs={8} sm={8}>
                                    <AppNumberField
                                        id="Borrow"
                                        label={drawdownLabel}
                                        type="number"
                                        required
                                        error={drawdownAmount > stableCoinBalance}
                                        value={drawdownAmount}
                                        setValue={(id, value = 0) => {
                                            setDrawdownAmount(value);
                                        }}
                                        suffix={` ${symbol}`}
                                    />
                                </Grid>
                                <Box marginY={1} display="flex" justifyContent="flex-end">
                                    {drawdownAlert && (
                                        <Alert severity={txData?.status}>{txData?.message?.slice(0, 100)}</Alert>
                                    )}
                                    <AppButton
                                        color="success"
                                        onClick={() => {
                                            setOpenDrawdown(true);
                                        }}
                                        disabled={
                                            drawdownLoader ||
                                            drawdownAmount > stableCoinBalance ||
                                            !drawdownAmount ||
                                            (poolStatus === PoolStatus.Active && poolId !== SystemIDs.BlendedPoolID)
                                        }
                                        loading={drawdownLoader}
                                    >
                                        Borrow
                                    </AppButton>
                                    <Dialog
                                        open={openDrawdown}
                                        onClose={handleCloseDrawdown}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >
                                        <DialogTitle id="alert-dialog-title">
                                            {`Are you sure you want to borrow ${drawdownAmount} ${symbol}?`}
                                        </DialogTitle>
                                        <DialogActions>
                                            <Button onClick={handleCloseDrawdown}>Disagree</Button>
                                            <Button onClick={handleDrawdown} autoFocus>
                                                Agree
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                </Box>
                                <Grid item xs={12} marginBottom={5}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography gutterBottom>Repay</Typography>
                                </Grid>
                                <Grid item xs={8} sm={8}>
                                    <AppNumberField
                                        id="payment"
                                        label="Payment amount"
                                        type="number"
                                        required
                                        value={paymentAmount}
                                        setValue={(id, value) => setPaymentAmount(value)}
                                        suffix={` ${symbol}`}
                                    />
                                </Grid>
                                <Box marginY={1} display="flex" justifyContent="flex-end">
                                    {paymentAlert && (
                                        <Alert severity={txData?.status}>{txData?.message?.slice(0, 100)}</Alert>
                                    )}
                                    <AppButton
                                        color="success"
                                        onClick={() => {
                                            setOpenPayment(true);
                                        }}
                                        loading={paymentLoader}
                                        disabled={
                                            paymentLoader ||
                                            !paymentAmount ||
                                            (poolStatus === PoolStatus.Active && poolId !== SystemIDs.BlendedPoolID)
                                        }
                                    >
                                        Repay
                                    </AppButton>
                                    <Dialog
                                        open={openPayment}
                                        onClose={handleClosePayment}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >
                                        <DialogTitle id="alert-dialog-title">
                                            {`Make payment of ${paymentAmount} ${symbol}.`}
                                        </DialogTitle>
                                        <DialogActions>
                                            <Button onClick={handleClosePayment}>Disagree</Button>
                                            <Button onClick={handlePayment} autoFocus>
                                                Agree
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                </Box>
                            </>
                        )}
                        <Grid width={"100%"}>
                            <Box sx={{ display: "flex", flexDirection: "row", pt: 10 }}>
                                <AppButton color="inherit" onClick={handleBack} sx={{ mr: 1 }}>
                                    Back
                                </AppButton>
                                <Box sx={{ flex: "1 1 auto" }} />
                                <AppButton type="button" onClick={handleFinish} variant="contained">
                                    Finish
                                </AppButton>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            )}
        </>
    );
};
