import { dummyReactSyntheticEvent, PoolPageTabs } from "../../../constants";
import { PoolsCards } from "./components/PoolsCards";
import { useEffect } from "react";
import { useWeb3React } from "@web3-react/core";

interface InvestmentsManagementsProps {
    onTabChange?: (e: React.SyntheticEvent, value: PoolPageTabs) => void;
}

export const InvestmentsManagements = (props: InvestmentsManagementsProps) => {
    const { library: provider, account } = useWeb3React();
    useEffect(() => {
        if (provider === undefined || account === undefined) {
            props.onTabChange?.(dummyReactSyntheticEvent, PoolPageTabs.Blockchain);
        }
    }, [provider]);

    return <PoolsCards />;
};
