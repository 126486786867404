import React from "react";
import { GridColDef, GridEventListener, GridRenderCellParams, GridRowParams } from "@mui/x-data-grid";
import { Avatar, Box, Paper, Skeleton } from "@mui/material";
import {useNavigate} from "react-router-dom";
import {AppRoutes} from "../../../routes/AppRoutes";
import {AppButton} from "../../../components";
import {useHeaderStyles} from "../../../utils/style";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import AppFormHeader from "../../../components/AppFormHeader";
import { AppCreateEditForm } from "../../../components/AppCreateEditForm";
import AppDataGrid from "../../../components/AppDataGrid/AppDataGrid";
import ApartmentIcon from "@mui/icons-material/Apartment";
import {useGetAllCompaniesQuery} from "../../../queries/companies";
import { CompanyUrls } from "../../../types/companies";
import { DeleteButton } from "../../DeleteButton";
import { ListNames } from "../../../types/shared";

const columns: GridColDef[] = [
    {field: "urls", headerName: "", width: 30, renderCell: (params: GridRenderCellParams<CompanyUrls>) => {
            if(params.value){
                return <Avatar alt="Remy Sharp"  sx={{ width: 30, height: 30 }} src={params.value['small'] || ''} />
            }else{
                return <Skeleton variant="circular" width={30} height={30} animation={false}/>
            }
        }},
    {field: "name", headerName: "Name", width: 300},
    {field: "description", headerName: "Description", flex: 1},
    {field: "draft", headerName: "Draft", width: 300},
    {
        field: "id",
        headerName: "",
        minWidth: 80,
        align: 'right',
        sortable: false,
        renderCell: (params: GridRenderCellParams<string>) => <DeleteButton id={params.value as string} listName={ListNames.Companies}/>
    }
];

function CompaniesIndex() {
    let navigate = useNavigate();
    const classes = useHeaderStyles();

    const [value, setValue] = React.useState('all');
    const {data: companies, isLoading} = useGetAllCompaniesQuery();

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const CreateCompanyButton = () => {
        return <AppButton onClick={handleCreateCompany}>Add Company</AppButton>;
    };

    const handleCreateCompany = () => {
        navigate(`${AppRoutes.COMPANIES}/create`);
    };

    const handleRowClickEvent: GridEventListener<"rowClick"> = (params: GridRowParams) => {
        navigate(`${AppRoutes.COMPANIES}/${params.id}/edit`);
    };

    return (
        <AppCreateEditForm>
            <AppFormHeader level1Route={AppRoutes.COMPANIES}
                           level1Caption='Companies'
                           level1Icon={ApartmentIcon}
                           level2Caption={null}
                           actionButton={CreateCompanyButton()}/>

            <Paper className={classes.paper}>
                <TabContext value={value}>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <TabList onChange={handleTabChange}>
                            <Tab label="All" value="all"/>
                        </TabList>
                    </Box>
                    <TabPanel className={classes.panelTab} value="all">
                        <div style={{display: 'flex', height: '100%'}}>
                            <div style={{flexGrow: 1}}>
                                <AppDataGrid
                                    columns={columns}
                                    onRowClick={handleRowClickEvent}
                                    rows={companies || []}
                                    isLoading={isLoading}
                                />
                            </div>
                        </div>
                    </TabPanel>
                </TabContext>
            </Paper>
        </AppCreateEditForm>
    );
}

export default CompaniesIndex;

