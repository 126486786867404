import AppFormHeader from "../../components/AppFormHeader";
import { AppRoutes } from "../../routes/AppRoutes";
import TokenIcon from "@mui/icons-material/Token";
import React, { useCallback, useState } from "react";
import { AppCreateEditForm } from "../../components/AppCreateEditForm";
import TabContext from "@mui/lab/TabContext";
import { Box, Container, Divider, Hidden, Paper, Typography } from "@mui/material";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import CurrencyBitcoinIcon from "@mui/icons-material/CurrencyBitcoin";
import SettingsIcon from "@mui/icons-material/Settings";
import TabPanel from "@mui/lab/TabPanel";
import { FundsManageForm, PoolForm } from "../Pools/Forms";
import { useGetSinglePoolQuery } from "../../queries/pools";
import { useHeaderStyles } from "../../utils/style";
import { BlockchainInfo } from "./BlockchainInfo";
import { InvestmentsManagements } from "./InvestorBoard/InvestmentsManagements";
import { useWeb3React } from "@web3-react/core";
import { PoolPageTabs, SystemIDs } from "../../constants";
import { PoolStatus } from "../../types/pools";
import { switchTab } from "../../utils";
import InvestorList from "../InvestorsList/InvestorList";

function BlendedPoolIndex() {
    const [value, setValue] = useState<PoolPageTabs>(PoolPageTabs.Blockchain);
    const { library: provider } = useWeb3React();

    const {
        data: pool = {
            id: SystemIDs.BlendedPoolID,
            status: PoolStatus.Active,
        },
    } = useGetSinglePoolQuery("");
    const classes = useHeaderStyles();

    const handleTabChange = useCallback(
        (event: React.SyntheticEvent, newValue: PoolPageTabs) => {
            switchTab({ newValue, provider, pool: pool, updateValue: setValue });
        },
        [provider, pool, setValue]
    );

    return (
        <AppCreateEditForm>
            <AppFormHeader
                level1Route={AppRoutes.BLENDED_POOL}
                level1Caption="Blended Pool"
                level1Icon={TokenIcon}
                level2Caption={null}
                actionButton={<></>}
            />
            <h1>Blended Pool</h1>
            <Paper className={classes.paper}>
                <Container
                    sx={{ pt: 3, pb: 2, display: "flex", justifyContent: "space-between", alignItems: "center" }}
                >
                    <Typography variant="h5" gutterBottom>
                        Manage Blended Pool
                    </Typography>
                    {!!pool.id && (
                        <Typography
                            variant="h6"
                            gutterBottom
                            sx={{
                                color: "#298d0a",
                            }}
                        >
                            {pool.status}
                        </Typography>
                    )}
                </Container>
                <Divider />
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <TabList onChange={handleTabChange}>
                            <Tab
                                icon={<ManageAccountsIcon />}
                                label={<Hidden smDown>Overview</Hidden>}
                                value={PoolPageTabs.Overview}
                            />
                            <Tab
                                icon={<CurrencyBitcoinIcon />}
                                label={<Hidden smDown>Blockchain</Hidden>}
                                value={PoolPageTabs.Blockchain}
                            />
                            <Tab
                                icon={<SettingsIcon />}
                                label={<Hidden smDown>Funds Management</Hidden>}
                                value={PoolPageTabs.Funds}
                            />
                            <Tab
                                icon={<SettingsIcon />}
                                label={<Hidden smDown>Investments</Hidden>}
                                value={PoolPageTabs.Investments}
                            />
                            <Tab
                                icon={<SettingsIcon />}
                                label={<Hidden smDown>Investors</Hidden>}
                                value={PoolPageTabs.Investors}
                            />
                        </TabList>
                    </Box>
                    <TabPanel className={classes.panelTab} value={PoolPageTabs.Overview}>
                        <PoolForm model={pool} onTabChange={handleTabChange} />
                    </TabPanel>
                    <TabPanel className={classes.panelTab} value={PoolPageTabs.Blockchain}>
                        <BlockchainInfo />
                    </TabPanel>
                    <TabPanel className={classes.panelTab} value={PoolPageTabs.Funds}>
                        <FundsManageForm model={pool} onTabChange={handleTabChange} />
                    </TabPanel>
                    <TabPanel className={classes.panelTab} value={PoolPageTabs.Investments}>
                        <InvestmentsManagements onTabChange={handleTabChange} />
                    </TabPanel>
                    <TabPanel className={classes.panelTab} value={PoolPageTabs.Investors}>
                        <InvestorList onTabChange={handleTabChange} poolId={pool.id} />
                    </TabPanel>
                </TabContext>
            </Paper>
        </AppCreateEditForm>
    );
}

export default BlendedPoolIndex;
