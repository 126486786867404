import React, { ReactElement, useCallback } from "react";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import { Box, Container, Grid } from "@mui/material";
import { AppTextField } from "../../../../components/AppFields";
import { AppButton } from "../../../../components";
import { AppRoutes } from "../../../../routes/AppRoutes";
import { useNavigate } from "react-router-dom";
import useTestimonialForm from "./hooks";
import { TestimonialFormProps, TestimonialFormValues, TestimonialValidationSchema } from "./types";
import { useGetSingleTestimonialQuery } from "../../../../queries/testimonials";

const TestimonialForm: React.FC<TestimonialFormProps> = (props: TestimonialFormProps): ReactElement => {
    const navigate = useNavigate();
    const { create, update } = useTestimonialForm();
    const id = props.model?.id;
    const { data: model } = useGetSingleTestimonialQuery(id);

    let initialValues: TestimonialFormValues;

    const handleFinish = useCallback(() => {
        navigate(`${AppRoutes.TESTIMONIALS}`);
    }, [navigate]);


    if (model) {
        initialValues = {
            first: model.fullName?.firstName,
            last: model.fullName?.lastName,
            jobTitle: model.jobTitle || "",
            description: model.description
        };
    } else {
        initialValues = {
            first: "",
            last: "",
            jobTitle: "",
            description: ""
        };
    }

    return (
        <>
            <Formik
                validateOnChange={true}
                validateOnBlur={false}
                initialValues={initialValues}
                enableReinitialize={true}
                validationSchema={TestimonialValidationSchema}
                onSubmit={
                    async (values: TestimonialFormValues, actions: FormikHelpers<TestimonialFormValues>) => {
                        if (model?.id) {
                            await update(values, actions, model);
                        } else {
                            await create(values, actions);
                        }
                        navigate(`${AppRoutes.TESTIMONIALS}`);
                    }
                }
            >
                {(formProps: FormikProps<TestimonialFormValues>) => {
                    const {
                        values,
                        errors,
                        handleChange,
                        isSubmitting
                    } = formProps;
                    return (
                        <Form noValidate>
                            <Container sx={{ pt: 3, pb: 3 }}>
                                <Grid
                                    rowSpacing={4}
                                    container spacing={2}
                                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                                >
                                    <Grid item xs={12} sm={6}>
                                        <AppTextField
                                            id="first"
                                            label="First Name"
                                            required
                                            error={!!errors.first}
                                            helperText={errors.first}
                                            value={values.first}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <AppTextField
                                            id="last"
                                            label="Last Name"
                                            required
                                            error={!!errors.last}
                                            helperText={errors.last}
                                            value={values.last}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <AppTextField
                                            id="jobTitle"
                                            label="Job Title"
                                            // error={!!errors.last}
                                            // helperText={errors.last}
                                            value={values.jobTitle}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <AppTextField
                                            id="description"
                                            label="Description"
                                            multiline
                                            rows={10}
                                            error={!!errors.description}
                                            helperText={errors.description}
                                            value={values.description}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                </Grid>
                            </Container>
                            <Container sx={{ pt: 2, pb: 2 }}>
                                <Grid container spacing={2} rowSpacing={2}>
                                    {/*<Grid item xs={12}>*/}
                                    {/*    <Typography variant="h6" gutterBottom>*/}
                                    {/*        Manage settings*/}
                                    {/*    </Typography>*/}
                                    {/*</Grid>*/}
                                    <Grid item xs={12}>
                                        <Box display="flex" justifyContent="flex-end">
                                            <AppButton variant="outlined" onClick={handleFinish}>
                                                Cancel
                                            </AppButton>
                                            <AppButton type="submit" variant="contained" disabled={isSubmitting}>
                                                {model?.id ? "Save" : "Create"}
                                            </AppButton>
                                        </Box>
                                    </Grid>
                                    {/*// model?.id &&*/}
                                    {/*// <>*/}
                                    {/*//     <Grid item xs={12}>*/}
                                    {/*//         <Divider />*/}
                                    {/*//     </Grid>*/}
                                    {/*//     <Grid item xs={12}>*/}
                                    {/*//         <Typography color="error" variant="h6" gutterBottom>*/}
                                    {/*//             Danger zone*/}
                                    {/*//         </Typography>*/}
                                    {/*//     </Grid>*/}
                                    {/*//     <Grid item xs={6}>*/}
                                    {/*//         <Typography color="error" gutterBottom>*/}
                                    {/*//             Delete success story*/}
                                    {/*//         </Typography>*/}
                                    {/*//     </Grid>*/}
                                    {/*//     <Grid item xs={6}>*/}
                                    {/*//         <Box display="flex" justifyContent="flex-end">*/}
                                    {/*//             <AppButtonError variant="text">*/}
                                    {/*//                 Delete*/}
                                    {/*//             </AppButtonError>*/}
                                    {/*//         </Box>*/}
                                    {/*//     </Grid>*/}
                                    {/*// </>*/}
                                </Grid>
                            </Container>
                        </Form>
                    );
                }
                }
            </Formik>
        </>
    );
};

export default TestimonialForm;
