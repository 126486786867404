import { useInvestorBoard } from "../../hooks/useInvestorBoard";
import { useState, useEffect } from "react";
import { Box, Divider, LinearProgress, Typography } from "@mui/material";

interface AccountDataProps {
    poolId: string | undefined;
}

export const AccountData = ({ poolId }: AccountDataProps) => {
    const { useAccountPoolBlockchainData } = useInvestorBoard();
    const { data, isLoading, error, refetch, isFetched } = useAccountPoolBlockchainData(poolId || "");

    // Internal state to store last successful data
    const [internalData, setInternalData] = useState(data);

    useEffect(() => {
        // To fix the issue where initial fetch returns null data
        setTimeout(() => {
            refetch().then((dataX) => {
                setInternalData(dataX.data);
            });
        }, 1000);
    }, [isFetched, data]);

    if (isLoading || !internalData) {
        return <LinearProgress />;
    }

    if (error) {
        return <div>Error Loading Data</div>;
    }

    return (
        <Box sx={{ display: "flex", gap: "12px", flexDirection: "column", marginTop: "12px" }}>
            <Typography variant="body2" color="text.secondary">
                Pool symbol: {internalData.symbol || "Unknown"}
            </Typography>

            <Divider />

            <Typography variant="body2">Blended Pool Investor Data:</Typography>

            <Typography variant="body2" color="text.secondary">
                BP Invested: {internalData.investedAmount ?? 0}
            </Typography>
            <Typography variant="body2" color="text.secondary">
                Yearned Yield: {internalData.yearnedYield ?? 0}
            </Typography>
            <Typography variant="body2" color="text.secondary">
                Total BP balance: {internalData.accountPoolBalance ?? 0}
            </Typography>
            <Typography variant="body2" color="text.secondary">
                Available To Withdraw: {internalData.availableToWithdraw ?? 0}
            </Typography>

            <Divider />

            <Typography variant="body2" color="text.secondary">
                Unlock Date: {internalData?.unlockDate ?? "---"}
            </Typography>
        </Box>
    );
};
