import {ThemeOptions} from '@mui/material';
import {PALETTE_COLORS} from './colors';

/**
 * MUI theme options for "Dark Mode"
 */
export const DARK_THEME: ThemeOptions = {
    palette: {
        mode: 'dark',
        background: {
            paper: '#111827',
            default: '#0B0F19',
        },
        ...PALETTE_COLORS,
    },
    shape: {
        borderRadius: 8
    },

    spacing: 6,
};
