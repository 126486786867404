import React, { PropsWithChildren, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { AppBar, BottomNavigation, BottomNavigationAction, Grid, Toolbar, Typography } from "@mui/material/";
import { AppIcon, AppIconButton } from "../components";
import SideBar from "../components/SideBar/SideBar";
import { LinkToPage } from "../utils";

const TITLE_PUBLIC = "Helios Admin";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        minHeight: "100vh", // Full screen height
        paddingTop: 56, // on Small screen
        [theme.breakpoints.up("sm")]: {
            paddingTop: 64 // on Large screen
        }
    },
    header: {},
    toolbar: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
    },
    title: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        flexGrow: 1,
        textAlign: "center",
        whiteSpace: "nowrap"
    },
    content: {
        flexGrow: 1, // Takes all possible space
        padding: theme.spacing(1)
    },
    footer: {}
}));

const SIDE_BAR_PUBLIC_ITEMS: Array<LinkToPage> = [
    {
        title: "Log In",
        path: "/auth/login",
        icon: "login"
    },
    {
        title: "About",
        path: "/about",
        icon: "info"
    }
];

const PublicLayout: React.FC<PropsWithChildren> = ({ children }) => {
    const classes = useStyles();
    const [openSideBar, setOpenSideBar] = useState(false);
    const navigation = useNavigate();

    const title = TITLE_PUBLIC;
    document.title = title;

    const handleSideBarOpen = useCallback(() => {
        if (!openSideBar) setOpenSideBar(true);
    }, [openSideBar]);

    const handleSideBarClose = useCallback(() => {
        if (openSideBar) setOpenSideBar(false);
    }, [openSideBar]);

    const handleBottomNavigationChange = (event: React.SyntheticEvent<{}>, value: any) => {
        navigation(value);
    };

    return (
        <Grid container direction="column" className={classes.root}>
            <Grid item className={classes.header} component="header">
                <AppBar component="div">
                    <Toolbar className={classes.toolbar} disableGutters>
                        <AppIconButton icon="logo" onClick={handleSideBarOpen} />

                        <Typography className={classes.title} variant="h6">
                            {title}
                        </Typography>

                    </Toolbar>
                </AppBar>

                <SideBar
                    anchor="left"
                    open={openSideBar}
                    variant="temporary"
                    items={SIDE_BAR_PUBLIC_ITEMS}
                    onClose={handleSideBarClose}
                />
            </Grid>

            <Grid item className={classes.content} component="main">
                {children}
            </Grid>

            <Grid item className={classes.footer} component="footer">
                <BottomNavigation onChange={handleBottomNavigationChange} showLabels>
                    <BottomNavigationAction label="Login" value="/auth/login" icon={<AppIcon icon="login" />} />
                    <BottomNavigationAction label="About" value="/about" icon={<AppIcon icon="info" />} />
                </BottomNavigation>
            </Grid>
        </Grid>
    );
};

export default PublicLayout;
