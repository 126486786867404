import {FormikHelpers} from "formik";
import {TestimonialFormValues, UseTestimonialForm} from "./types";
import {useCreateTestimonialMutation, useUpdateTestimonialMutation} from "../../../../queries/testimonials/mutations";
import {CreateTestimonialRequest, UpdateTestimonialRequest} from "../../../../queries/testimonials/types";
import {Testimonial} from "../../../../types/testimonials";

const useTestimonialForm = (): UseTestimonialForm => {
    const createMutation = useCreateTestimonialMutation();
    const updateMutation = useUpdateTestimonialMutation();

    const create = async (values: TestimonialFormValues, actions: FormikHelpers<TestimonialFormValues>) => {
        try {
            const request: CreateTestimonialRequest = {
                fullName: {
                    firstName: values.first,
                    lastName: values.last
                },
                jobTitle:values.jobTitle,
                description: values.description
            };

            await createMutation.mutateAsync({request}, {
                onSuccess: async () => {
                },
            });
        } catch (error) {
        }
    };

    const update = async (values: TestimonialFormValues, actions: FormikHelpers<TestimonialFormValues>, model: Testimonial) => {
        try {
            const request: UpdateTestimonialRequest = {
                fullName: {
                    firstName: values.first,
                    lastName: values.last
                },
                jobTitle:values.jobTitle,
                description: values.description
            };

            await updateMutation.mutateAsync(
                {request, testimonialId: String(model.id)},
                {
                    onSuccess: async () => {
                    },
                },
            );
        } catch (error) {
        }
    };

    return {
        create,
        update,
    };
};

export default useTestimonialForm;
