import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";
import abiPoolFactory from "../../contracts/abi/PoolFactory.json";
import { SystemIDs } from "../../constants";
import abiBlendedPool from "../../contracts/abi/BlendedPool.json";
import abiIERC20Factory from "../../contracts/abi/IERC20.json";
import abiPool from "../../contracts/abi/Pool.json";
import { useQuery } from "react-query";
import { formatBigInt } from "../../utils";

const useInvestorList = () => {
    const { library: provider } = useWeb3React();

    const CONTRACT_POOL_FACTORY = process.env.REACT_APP_CONTRACT_POOL_FACTORY!;
    const CONTRACT_BLENDED_POOL = process.env.REACT_APP_CONTRACT_BLENDED_POOL!;

    const poolFactory = new ethers.Contract(CONTRACT_POOL_FACTORY, abiPoolFactory, provider);

    let poolAddress: string;
    let pool: ethers.Contract;

    let signer: any = null;

    if (provider) {
        signer = provider.getSigner();
    }

    const reassignPoolInfo = async (poolId: string) => {
        // Reassign pool address
        if (poolId === SystemIDs.BlendedPoolID) {
            poolAddress = CONTRACT_BLENDED_POOL;
            pool = new ethers.Contract(poolAddress, abiBlendedPool, signer.connectUnchecked());
        } else {
            poolAddress = await poolFactory.pools(poolId);
            pool = new ethers.Contract(poolAddress, abiPool, signer.connectUnchecked());
        }

        console.log("Pool Address: ", poolAddress);
    };

    const getInvestorsList = async (poolId: string): Promise<string[]> => {
        await reassignPoolInfo(poolId);
        return await pool.getHolders();
    };

    const getInvestorsFinancialData = async (investorAddress: string, poolId: string) => {
        await reassignPoolInfo(poolId);

        try {
            const [yearnedYield, decimals, asset] = await Promise.all([
                pool.yields(investorAddress),
                pool.decimals(),
                pool.asset(),
            ]);

            const assetContract = new ethers.Contract(asset, abiIERC20Factory, signer);
            const symbol = await assetContract.symbol();

            return {
                yearnedYield: formatBigInt(yearnedYield, decimals),
                decimals,
                symbol,
            };
        } catch (e) {
            console.error(e);
        }
    };

    const withdrawInvestorsYield = async (investorAddress: string, beneficiary: string, poolId: string) => {
        await reassignPoolInfo(poolId);

        try {
            const tx = await pool["withdrawYield(address, address)"](investorAddress, beneficiary);
            const txReceipt = await provider.waitForTransaction(tx.hash);

            if (txReceipt.status === 1) {
                return {
                    status: true,
                    message: "Withdrawal successful",
                };
            } else {
                return {
                    status: false,
                    message: "Withdrawal failed",
                };
            }
        } catch (e: any) {
            console.error(e);
            return {
                status: false,
                message: e.message,
            };
        }
    };

    const useInvestorsListQuery = (poolId: string) => {
        return useQuery(["investorsList", poolId], () => getInvestorsList(poolId), {
            staleTime: 1000 * 60 * 10, // data will be considered stale after 5 minutes
            cacheTime: 1000 * 60 * 60, // data will be cached for 30 minutes
        });
    };

    const useInvestorsFinancialDataQuery = (investorAddress: string, poolId: string) => {
        return useQuery(
            ["investorsFinancialData", investorAddress, poolId],
            () => getInvestorsFinancialData(investorAddress, poolId),
            {
                staleTime: 1000 * 60 * 10, // data will be considered stale after 5 minutes
                cacheTime: 1000 * 60 * 60, // data will be cached for 30 minutes
            }
        );
    };

    return {
        useInvestorsListQuery,
        useInvestorsFinancialDataQuery,
        getInvestorsFinancialData,
        withdrawInvestorsYield,
    };
};

export default useInvestorList;
