import React from "react";
import {Box, Container, Divider, Paper, Typography,} from "@mui/material";
import {useParams} from "react-router-dom";
import {AppRoutes} from "../../../routes/AppRoutes";
import { AppCreateEditForm } from "../../../components/AppCreateEditForm";
import AppFormHeader from "../../../components/AppFormHeader";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import {useHeaderStyles} from "../../../utils/style";
import {useGetSingleSuccessStoryQuery} from "../../../queries/successStories";
import SuccessStoryForm from "../Forms/SuccessStoryForm/SuccessStoryForm";
import SuccessStoryImageForm from "../Forms/ImageForm/SuccessStoryImageForm";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";

function EditSuccessStory() {
    const {id} = useParams();
    const classes = useHeaderStyles();
    const [value, setValue] = React.useState('overview');
    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const {data: successStory} = useGetSingleSuccessStoryQuery(id || '');

    return (
        <AppCreateEditForm>
            <AppFormHeader level1Route={AppRoutes.SUCCESS_STORIES}
                           level1Caption='Success Stories'
                           level1Icon={AutoStoriesIcon}
                           level2Caption={id!}
                           actionButton={null}/>
            <Paper className={classes.paper}>
                <Container sx={{pt: 3, pb: 2}}>
                    <Typography variant="h5" gutterBottom>
                        Edit Success Story
                    </Typography>
                </Container>
                <Divider/>
                <TabContext value={value}>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <TabList onChange={handleTabChange}>
                            <Tab label="Overview" value="overview"/>
                            <Tab label="Gallery" value="gallery"/>
                        </TabList>
                    </Box>
                    <TabPanel className={classes.panelTab} value="overview">
                        <SuccessStoryForm model={successStory}/>
                    </TabPanel>
                    <TabPanel className={classes.panelTab} value="gallery">
                        <SuccessStoryImageForm model={successStory}/>
                    </TabPanel>
                </TabContext>
            </Paper>
        </AppCreateEditForm>
    );
}

export default EditSuccessStory;
