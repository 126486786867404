import {FunctionComponent} from 'react';
import {Link} from 'react-router-dom';

interface Props {
    name?: string;
}

const NotImplementedView: FunctionComponent<Props> = ({name}) => {
    const componentName = name || 'View';

    return (
        <div>
            <h1>{componentName} is under construction</h1>
            <p>
                This view is not implemented yet. Go to <Link to="/">home page</Link>
            </p>
            <img width="600vh" src="/img/under-construction.jpg" alt="Under construction"/>
        </div>
    );
};

export default NotImplementedView;
