import React from "react";

import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/material";
import { LoadingButton, LoadingButtonProps } from "@mui/lab";

import { ColorName } from "../../utils/style";

const APP_BUTTON_VARIANT = "contained"; // | 'text' | 'outlined'
const APP_BUTTON_MARGIN = 1;

const useStyles = makeStyles(() => ({
    box: {
        display: "inline-block"
    },
    button: {
        backgroundColor: '#3c52b2',
        color: '#fff',
        '&:hover': {
            opacity: 0.8,
        },
    }
}));

interface Props extends Omit<LoadingButtonProps, "color"> {
    color?: ColorName | "inherit";
    label?: string;
    text?: string;
    m?: number;
    mt?: number;
    mb?: number;
    ml?: number;
    mr?: number;
    // Missing props
    component?: React.ElementType; // Could be RouterLink, AppLink, etc.
    to?: string; // Link prop
    href?: string; // Link prop
    openInNewTab?: boolean; // Link prop
    underline?: "none" | "hover" | "always"; // Link prop
}

 export const AppButton: React.FC<Props> = ({
                                        children,
                                        className,
                                        color = "default",
                                        label,
                                        m = 0,
                                        mt = APP_BUTTON_MARGIN,
                                        mb = APP_BUTTON_MARGIN,
                                        ml = APP_BUTTON_MARGIN,
                                        mr = APP_BUTTON_MARGIN,
                                        text,
                                        underline = "none",
                                        variant = APP_BUTTON_VARIANT,
                                        ...restOfProps
                                    }) => {
    const classes = useStyles();
    return (
        <Box {...{ m, mt, mb, ml, mr }} className={classes.box}>
            <LoadingButton variant={variant}
                           {...{ ...restOfProps, underline }}>
                {children || label || text}
            </LoadingButton>
        </Box>
    );
};

export const AppButtonError: React.FC<Props> = ({
                                             children,
                                             className,
                                             color = "default",
                                             label,
                                             m = 0,
                                             mt = APP_BUTTON_MARGIN,
                                             mb = APP_BUTTON_MARGIN,
                                             ml = APP_BUTTON_MARGIN,
                                             mr = APP_BUTTON_MARGIN,
                                             text,
                                             underline = "none",
                                             variant = APP_BUTTON_VARIANT,
                                             ...restOfProps
                                         }) => {
    const classes = useStyles();
    return (
        <Box {...{ m, mt, mb, ml, mr }} className={classes.box}>
            <LoadingButton
                // loadingIndicator={(<CircularProgress color="inherit" size={16} />)}
                color={'error'}
                variant={variant}
                {...{ ...restOfProps, underline }}
            >
                {children || label || text}
            </LoadingButton>
        </Box>
    );
};
