import React from "react";
import { GridColDef, GridEventListener, GridRenderCellParams, GridRowParams } from "@mui/x-data-grid";
import { Avatar, Box, Paper, Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../routes/AppRoutes";
import { AppButton } from "../../../components";
import { useHeaderStyles } from "../../../utils/style";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import AppFormHeader from "../../../components/AppFormHeader";
import { AppCreateEditForm } from "../../../components/AppCreateEditForm";
import AppDataGrid from "../../../components/AppDataGrid/AppDataGrid";
import SolarPowerIcon from "@mui/icons-material/SolarPower";
import { useGetAllProjectsQuery } from "../../../queries/projects";
import { ProjectImage } from "../../../types/projects";
import { DraftStatus, ListNames } from "../../../types/shared";
import { DeleteButton } from "../../DeleteButton";

const columns: GridColDef[] = [
    {field: "images", headerName: "", width: 30, renderCell: (params: GridRenderCellParams<ProjectImage[]>) => {
            if(params.value?.length && params.value[0]['urls']){
                return <Avatar alt="Remy Sharp"  sx={{ width: 30, height: 30 }} src={params.value[0]['urls']['small'] || ''} />
            }else{
                return <Skeleton variant="circular" width={30} height={30} animation={false}/>
            }
        }},
    {field: "name", headerName: "Name", minWidth: 300},
    {field: "overview", headerName: "Overview", flex: 1},
    {field: "draft", headerName: "Status", minWidth: 200},
    {
        field: "id",
        headerName: "",
        minWidth: 80,
        align: "right",
        sortable: false,
        renderCell: (params: GridRenderCellParams<string>) => <DeleteButton id={params.value as string}
                                                                            listName={ListNames.Projects} />
    }
];

function ProjectsIndex() {
    let navigate = useNavigate();
    const classes = useHeaderStyles();

    const [value, setValue] = React.useState('all');
    const {data: projects, isLoading} = useGetAllProjectsQuery();

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const CreateProjectButton = () => {
        return <AppButton onClick={handleCreateProject}>Add Project</AppButton>;
    };

    const handleCreateProject = () => {
        navigate(`${AppRoutes.PROJECTS}/create`);
    };

    const handleRowClickEvent: GridEventListener<"rowClick"> = (params: GridRowParams) => {
        navigate(`${AppRoutes.PROJECTS}/${params.id}/edit`);
    };

    return (
        <AppCreateEditForm>
            <AppFormHeader level1Route={AppRoutes.PROJECTS}
                           level1Caption='Projects'
                           level1Icon={SolarPowerIcon}
                           level2Caption={null}
                           actionButton={CreateProjectButton()}/>

            <Paper className={classes.paper}>
                <TabContext value={value}>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <TabList onChange={handleTabChange}>
                            <Tab label="All" value="all"/>
                            <Tab label="Active" value="active"/>
                            <Tab label="Drafts" value="draft"/>
                        </TabList>
                    </Box>
                    <TabPanel className={classes.panelTab} value="all">
                        <div style={{display: 'flex', height: '100%'}}>
                            <div style={{flexGrow: 1}}>
                                <AppDataGrid
                                    columns={columns}
                                    onRowClick={handleRowClickEvent}
                                    rows={projects || []}
                                    isLoading={isLoading}
                                />
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel className={classes.panelTab} value="active">
                        <div style={{display: 'flex', height: '100%'}}>
                            <div style={{flexGrow: 1}}>
                                <AppDataGrid
                                    columns={columns}
                                    onRowClick={handleRowClickEvent}
                                    rows={projects || []}
                                    isLoading={isLoading}
                                    columnField='draft'
                                    filterValue={DraftStatus.DraftOut}
                                />
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel className={classes.panelTab} value="draft">
                        <div style={{display: 'flex', height: '100%'}}>
                            <div style={{flexGrow: 1}}>
                                <AppDataGrid
                                    columns={columns}
                                    onRowClick={handleRowClickEvent}
                                    rows={projects || []}
                                    isLoading={isLoading}
                                    columnField='draft'
                                    filterValue={DraftStatus.DraftIn}
                                />
                            </div>
                        </div>
                    </TabPanel>
                </TabContext>
            </Paper>
        </AppCreateEditForm>
    );
}

export default ProjectsIndex;
