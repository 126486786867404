import {FormikHelpers} from 'formik';
import {useState} from 'react';
import {LoginFormValues, UseLoginForm} from './types';
import {postAuthLogin} from "../../../queries/auth/mutations";
import {AlertProps} from "../../../components/AppAlert/AppAlert";
import {LOG_IN_ACTION} from "../../../store/AppReducer";
import {useAppStore} from "../../../store";

const useLoginForm = (): UseLoginForm => {
    const [, dispatch] = useAppStore();
    const [formAlert, setFormAlert] = useState<AlertProps>({
        message: '',
        type: undefined,
    });

    const authenticate = async (values: LoginFormValues, actions: FormikHelpers<LoginFormValues>) => {
        setFormAlert({message: '', type: undefined});

        try {
            const result = await postAuthLogin(values);
            if (!result) {
                actions.setErrors(result);
                return;
            }

            dispatch({
                type: LOG_IN_ACTION,
                payload: result.accessToken,
            });

        } catch (error) {
            setFormAlert({
                message: 'Something went wrong',
                type: 'error',
            });
        }
    }

    return {
        authenticate,
        formAlert,
    };
};


export default useLoginForm;
