import React, { ReactElement, useCallback } from "react";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import { Box, Container, Grid, Typography } from "@mui/material";
import { AppNumberField } from "../../../../components/AppFields";
import { AppButton } from "../../../../components";
import { AppRoutes } from "../../../../routes/AppRoutes";
import { useNavigate } from "react-router-dom";
import { PlatformImpactFormProps, PlatformImpactFormValues, PlatformImpactValidationSchema } from "./types";
import usePlatformImpactForm from "./hooks";
import { useGetPlatformImpactQuery } from "../../../../queries/platform";

const PlatformImpactForm: React.FC<PlatformImpactFormProps> = (): ReactElement => {
        const navigate = useNavigate();
        const { update } = usePlatformImpactForm();
        const { data: model } = useGetPlatformImpactQuery();


        const handleFinish = useCallback(() => {
            navigate(`${AppRoutes.PLATFORM_IMPACT}`);
        }, [navigate]);


         const  initialValues: PlatformImpactFormValues = {
                emissionsAverted: model?.emissionsAverted || 0,
                totalSolarFunded: model?.totalSolarFunded || 0,
                projectsFunded: model?.projectsFunded || 0,
                returnsGenerated: model?.returnsGenerated || 0
            };

        return (
            <>
                <Formik
                    validateOnChange={true}
                    validateOnBlur={false}
                    initialValues={initialValues}
                    enableReinitialize={true}
                    validationSchema={PlatformImpactValidationSchema}
                    onSubmit={
                        async (values: PlatformImpactFormValues, actions: FormikHelpers<PlatformImpactFormValues>) => {
                            await update(values, actions, model!);
                            navigate(`${AppRoutes.PLATFORM_IMPACT}`);
                        }
                    }
                >
                    {(formProps: FormikProps<PlatformImpactFormValues>) => {
                        const {
                            values,
                            errors,
                            isSubmitting,
                            setFieldValue,
                        } = formProps;
                        return (
                            <Form noValidate>
                                <Container sx={{ pt: 3, pb: 3 }}>
                                    <Grid
                                        rowSpacing={4}
                                        container spacing={2}
                                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                                    >
                                        <Grid item xs={12}>
                                            <Typography variant="h6">Settings</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <AppNumberField
                                                id="emissionsAverted"
                                                label="Emissions Averted"
                                                required
                                                error={!!errors.emissionsAverted}
                                                helperText={errors.emissionsAverted}
                                                value={values.emissionsAverted}
                                                setValue={setFieldValue}
                                                suffix=" KG"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <AppNumberField
                                                id="totalSolarFunded"
                                                label="Total Solar Funded"
                                                error={!!errors.totalSolarFunded}
                                                helperText={errors.totalSolarFunded}
                                                value={values.totalSolarFunded}
                                                setValue={setFieldValue}
                                                prefix="$"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <AppNumberField
                                                id="projectsFunded"
                                                label="Projects Funded"
                                                required
                                                error={!!errors.projectsFunded}
                                                helperText={errors.projectsFunded}
                                                value={values.projectsFunded}
                                                setValue={setFieldValue}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <AppNumberField
                                                id="returnsGenerated"
                                                label="Returns Generated"
                                                error={!!errors.returnsGenerated}
                                                helperText={errors.returnsGenerated}
                                                value={values.returnsGenerated}
                                                setValue={setFieldValue}
                                                prefix="$"
                                            />
                                        </Grid>
                                    </Grid>
                                </Container>
                                <Container sx={{ pt: 2, pb: 2 }}>
                                    <Grid container spacing={2} rowSpacing={2}>
                                        <Grid item xs={12}>
                                            <Box display="flex" justifyContent="flex-end">
                                                <AppButton variant="outlined" onClick={handleFinish}>
                                                    Cancel
                                                </AppButton>

                                                <AppButton type="submit" variant="contained" disabled={isSubmitting}>
                                                    Save
                                                </AppButton>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Container>
                            </Form>
                        );
                    }
                    }
                </Formik>
            </>
        );
    }
;

export default PlatformImpactForm;
