import React, { useCallback, useEffect, useState } from "react";
import {
    Box,
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Hidden,
    Paper,
    Typography,
} from "@mui/material";
import { AppRoutes } from "../../../routes/AppRoutes";
import { AppCreateEditForm } from "../../../components/AppCreateEditForm";
import AppFormHeader from "../../../components/AppFormHeader";
import TokenIcon from "@mui/icons-material/Token";
import { useHeaderStyles } from "../../../utils/style";
import { useParams } from "react-router-dom";
import { useGetSinglePoolQuery } from "../../../queries/pools";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import { PoolForm, BlockchainForm, PoolManageForm, FundsManageForm } from "../Forms";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import CurrencyBitcoinIcon from "@mui/icons-material/CurrencyBitcoin";
import SettingsIcon from "@mui/icons-material/Settings";
import { PoolStatus } from "../../../types/pools";
import { useWeb3React } from "@web3-react/core";
import { switchTab } from "../../../utils";
import { PoolPageTabs } from "../../../constants";
import InvestorList from "../../InvestorsList/InvestorList";

export const CreateEditPool = () => {
    const { id } = useParams();
    const { account, chainId = process.env.REACT_APP_CHAIN_ID!, library: provider } = useWeb3React();
    const [value, setValue] = useState<PoolPageTabs>(PoolPageTabs.Overview);
    const [netErrorIsOpen, setNetErrorIsOpen] = useState(false);
    const classes = useHeaderStyles();

    const { data: pool = {} } = useGetSinglePoolQuery(id || "");

    const handleTabChange = useCallback(
        (event: React.SyntheticEvent, newValue: PoolPageTabs) => {
            switchTab({ newValue, provider, pool: pool, updateValue: setValue });
        },
        [provider, pool, setValue]
    );

    useEffect(() => {
        if (chainId.toString() !== process.env.REACT_APP_CHAIN_ID! && account) {
            setNetErrorIsOpen(true);
        }
    }, [account, chainId]);

    const getTextColor = (status: PoolStatus) => {
        switch (true) {
            case status === PoolStatus.Draft:
                return "text.secondary";
            case status === PoolStatus.Active:
                return "success.main";
            case status === PoolStatus.Closed:
                return "text.primary";
            default:
                return "text.disabled";
        }
    };

    const handleCloseNetError = () => {
        setNetErrorIsOpen(false);
    };

    return (
        <AppCreateEditForm>
            <AppFormHeader
                level1Route={AppRoutes.POOLS}
                level1Caption="Pools"
                level1Icon={TokenIcon}
                level2Caption={pool.id || "Create Pool"}
                actionButton={null}
            />
            <Paper className={classes.paper}>
                <Container
                    sx={{ pt: 3, pb: 2, display: "flex", justifyContent: "space-between", alignItems: "center" }}
                >
                    <Typography variant="h5" gutterBottom>
                        {pool.id ? "Edit Pool" : "Create Pool"}
                    </Typography>
                    {!!pool.id && (
                        <Typography
                            variant="h6"
                            gutterBottom
                            sx={{
                                color: getTextColor(pool.status as PoolStatus),
                            }}
                        >
                            {pool.status}
                        </Typography>
                    )}
                </Container>
                <Divider />
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <TabList onChange={handleTabChange}>
                            <Tab
                                icon={<ManageAccountsIcon />}
                                label={<Hidden smDown>Overview</Hidden>}
                                value={PoolPageTabs.Overview}
                            />
                            <Tab
                                icon={<CurrencyBitcoinIcon />}
                                label={<Hidden smDown>Blockchain</Hidden>}
                                value={PoolPageTabs.Blockchain}
                                disabled={
                                    !pool.id || !account || chainId.toString() !== process.env.REACT_APP_CHAIN_ID!
                                }
                            />
                            <Tab
                                icon={<SettingsIcon />}
                                label={<Hidden smDown>Pool Management</Hidden>}
                                value="manage"
                                disabled={
                                    !pool.id || !account || chainId.toString() !== process.env.REACT_APP_CHAIN_ID!
                                }
                            />
                            <Tab
                                icon={<SettingsIcon />}
                                label={<Hidden smDown>Funds Management</Hidden>}
                                value={PoolPageTabs.Funds}
                                disabled={
                                    !pool.id || !account || chainId.toString() !== process.env.REACT_APP_CHAIN_ID!
                                }
                            />
                            <Tab
                                icon={<SettingsIcon />}
                                label={<Hidden smDown>Investors</Hidden>}
                                value={PoolPageTabs.Investors}
                                disabled={
                                    !pool.id || !account || chainId.toString() !== process.env.REACT_APP_CHAIN_ID!
                                }
                            />
                        </TabList>
                    </Box>
                    <TabPanel className={classes.panelTab} value={PoolPageTabs.Overview}>
                        <PoolForm model={pool} onTabChange={handleTabChange} />
                    </TabPanel>
                    <TabPanel className={classes.panelTab} value={PoolPageTabs.Blockchain}>
                        <BlockchainForm model={pool} onTabChange={handleTabChange} />
                    </TabPanel>
                    <TabPanel className={classes.panelTab} value={PoolPageTabs.Manage}>
                        <PoolManageForm model={pool} onTabChange={handleTabChange} />
                    </TabPanel>
                    <TabPanel className={classes.panelTab} value={PoolPageTabs.Funds}>
                        <FundsManageForm model={pool} onTabChange={handleTabChange} />
                    </TabPanel>
                    <TabPanel className={classes.panelTab} value={PoolPageTabs.Investors}>
                        <InvestorList onTabChange={handleTabChange} poolId={pool.id} />
                    </TabPanel>
                </TabContext>
            </Paper>
            <Dialog
                open={netErrorIsOpen}
                onClose={handleCloseNetError}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Network error!"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{`Please select CELO network.`}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseNetError}>OK</Button>
                </DialogActions>
            </Dialog>
        </AppCreateEditForm>
    );
};
