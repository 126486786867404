import React, {createContext, FunctionComponent, PropsWithChildren, useContext, useReducer} from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import AppReducer from './AppReducer';
import {DARK_MODE_KEY, localStorageGet, TOKEN_KEY} from '../utils';

export interface IAppState {
    darkMode: boolean;
    isAuthenticated: boolean;
    token: string | undefined;
    account: any,
    backdropOpen: boolean;
}

const initialAppState: IAppState = {
    darkMode: false,
    isAuthenticated: false,
    backdropOpen: false,
    token: undefined,
    account:undefined
};

type IAppContext = [IAppState, React.Dispatch<any>];
const AppContext = createContext<IAppContext>([initialAppState, () => null]);

const AppStoreProvider: FunctionComponent<PropsWithChildren<{}>> = ({children}) => {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const previousDarkMode = Boolean(localStorageGet(DARK_MODE_KEY));
    const tokenExists = Boolean(localStorageGet(TOKEN_KEY));
    const token = localStorageGet(TOKEN_KEY);

    const initialState: IAppState = {
        ...initialAppState,
        darkMode: previousDarkMode || prefersDarkMode,
        isAuthenticated: tokenExists,
        token: token,
    };

    const value: IAppContext = useReducer(AppReducer, initialState);

    return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

const useAppStore = (): IAppContext => useContext(AppContext);

interface WithAppStoreProps {
    store: object;
}

const withAppStore =
    (Component: React.ComponentType<WithAppStoreProps>): React.FC =>
        (props) => {
            return <Component {...props} store={useAppStore()}/>;
        };

export {AppStoreProvider as AppStore, AppContext, useAppStore, withAppStore};
