import {DARK_MODE_KEY, localStorageSet, TOKEN_KEY} from '../utils';
import {IAppState} from './AppStore';
import React from "react";

export const DARK_MODE_ACTION = 'DARK_MODE_ACTION';
export const LOG_IN_ACTION = 'LOG_IN_ACTION';
export const LOG_OUT_ACTION = 'LOG_OUT_ACTION';

export const LOADER_ACTION_ON = 'LOADER_ACTION_ON';

export const LOADER_ACTION_OFF = 'LOADER_ACTION_OFF';
const AppReducer: React.Reducer<IAppState, any> = (state, action) => {
    switch (action.type || action.action) {
        case LOG_IN_ACTION:
            localStorageSet(TOKEN_KEY, action?.payload);
            return {
                ...state,
                isAuthenticated: true,
                token: action?.payload,
            };
        case LOG_OUT_ACTION:
            localStorageSet(TOKEN_KEY, null);
            return {
                ...state,
                isAuthenticated: false,
                token: undefined
            };
        case DARK_MODE_ACTION: {
            const darkMode = action?.darkMode ?? action?.payload;
            localStorageSet(DARK_MODE_KEY, darkMode);
            return {
                ...state,
                darkMode,
            };
        }
        case LOADER_ACTION_OFF:
            return {
                ...state,
                backdropOpen: false,
            };
        case LOADER_ACTION_ON:
            return {
                ...state,
                backdropOpen: true,
            };

        default:
            return state;
    }
};

export default AppReducer;
