import {useQuery, UseQueryResult} from "react-query";
import {httpClient} from "../../api/axios";
import {PlatformImpact} from "../../types/platformImpact";

export const useGetPlatformImpactQuery = (): UseQueryResult<PlatformImpact, Error> => useQuery<PlatformImpact, Error>(
    'platform',
    async () => {
        const response = await httpClient.get('/platform');
        return response.data;
    },
);