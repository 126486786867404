import {useQuery, useQueryClient, UseQueryResult} from "react-query";
import {httpClient} from "../../api/axios";
import {TeamMember} from "../../types/team";

export const useGetAllTeamMembersQuery = (): UseQueryResult<TeamMember[], Error> => useQuery<TeamMember[], Error>(
    'team',
    async () => {
        const response = await httpClient.get('/team');
        return response.data;
    },
);

export const useGetSingleTeamMemberQuery = (teamMemberId: string | undefined): UseQueryResult<TeamMember, Error> => {
    const queryClient = useQueryClient();

    return useQuery<TeamMember, Error>(
        ['team-members', teamMemberId],
        async () => {
            if (teamMemberId) {
                const response = await httpClient.get(`/team/${teamMemberId}`);
                return response.data;
            }
        },
        {
            placeholderData: (): TeamMember | undefined => queryClient
                .getQueryData<TeamMember[]>('team-members')
                ?.find((c: TeamMember) => c.id === teamMemberId),
        },
    );
};