import {FormikHelpers} from "formik";
import {useCreateTeamMemberImageMutation} from "../../../../queries/team/mutations";
import {CreateTeamMemberImageRequest} from "../../../../queries/team/types";
import {TeamMemberImageFormValues, UseTeamMemberImageForm} from "./types";

const useTeamMemberImageForm = (): UseTeamMemberImageForm => {
    const createMutation = useCreateTeamMemberImageMutation();

    const create = async (values: TeamMemberImageFormValues, actions: FormikHelpers<TeamMemberImageFormValues>) => {
        try {
            const request: CreateTeamMemberImageRequest = values;

            await createMutation.mutateAsync({teamMemberId: values.teamMemberId, request}, {
                onSuccess: async () => {
                },
            });
        } catch (error) {
        }
    };

    return {
        create
    };
};

export default useTeamMemberImageForm;