import { DraftStatus } from "./shared";

// eslint-disable-next-line @typescript-eslint/no-redeclare
export enum PoolStatus {
    Draft = "Draft",
    Active = "Active",
    Closed = "Closed",
    Error = "Error",
    NotExist = "NotExist",
}

export type PoolRegion = typeof PoolRegion[keyof typeof PoolRegion];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PoolRegion = {
    None: "",
    Europe: "Europe",
    Americas: "Americas",
    Asia: "Asia",
    Africa: "Africa",
    Oceania: "Oceania",
    Antarctic: "Antarctic",
} as const;

export interface Pool {
    id?: string;
    name?: string | null;
    status?: PoolStatus;
    apy?: number | 0;
    overview?: string | null;
    description?: string | null;
    descriptionTitle?: string | null;
    annualEmissionsAverted?: number;
    annualSolarEnergyProduced?: number;
    isVisible?: boolean;
    region?: PoolRegion;
    images?: PoolImage[] | null;
    projects?: PoolProject[] | null;
}

export interface PoolBlockchainValues {
    poolId: string;
    poolAddress: string;
    poolSize: number;
    minInvestmentSize: number;
    lockupPeriod: number;
    accountInvestmentSize?: number;
    accountPoolBalance?: number;
    totalDeposited?: number;
    availableToWithdraw?: number;
    symbol?: string;
    poolStatus?: PoolStatus;
}

export interface PoolBlockchainAccountValues {
    yearnedYield?: number;
    availableToWithdraw?: number;
    investedAmount?: number;
    isInvested?: boolean;
    accountPoolBalance?: number;
    symbol?: string;
    unlockDate?: any;
    decimals?: number | string;
}

export type PoolImageUrls = { [key: string]: string } | null;

export interface PoolImage {
    id?: string;
    path?: string | null;
    isPrimary?: boolean;
    urls?: PoolImageUrls;
}

export interface PoolProject {
    id?: string;
    name?: string | null;
    draft?: DraftStatus;
    overview?: string | null;
}
