import createCache from '@emotion/cache';
import {CacheProvider, EmotionCache} from '@emotion/react';
import {createTheme, CssBaseline, ThemeProvider} from '@mui/material';
import {FunctionComponent, PropsWithChildren, useMemo} from 'react';
import {useAppStore} from '../store';
import { DARK_THEME } from './dark';
import { LIGHT_THEME } from './light';

function createEmotionCache() {
    return createCache({key: 'css', prepend: true});
}

// Client-side cache, shared for the whole session of the user in the browser.
const CLIENT_SIDE_EMOTION_CACHE = createEmotionCache();

interface Props extends PropsWithChildren<{}> {
    emotionCache?: EmotionCache; // You can omit it if you don't want to use Emotion styling library
}

const AppThemeProvider: FunctionComponent<Props> = ({children, emotionCache = CLIENT_SIDE_EMOTION_CACHE}) => {
    const [state] = useAppStore();
    const theme = useMemo(() => (state.darkMode ? createTheme(DARK_THEME) : createTheme(LIGHT_THEME)), [state.darkMode]);

    return (
        <CacheProvider value={emotionCache}>
            <ThemeProvider theme={theme}>
                <CssBaseline /* MUI Styles */ />
                {children}
            </ThemeProvider>
        </CacheProvider>
    );
};

export default AppThemeProvider;
