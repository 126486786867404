import React, { PropsWithChildren, ReactNode } from "react";
import { useAppStore } from "../store";
import PrivateLayout from "./PrivateLayout";
import PublicLayout from "./PublicLayout";

interface Props extends PropsWithChildren {
    children: ReactNode;
}

const CurrentLayout: React.FC<Props> = (props) => {
    const [state] = useAppStore();
    return state.isAuthenticated ? <PrivateLayout {...props}>{props.children}</PrivateLayout> :
        <PublicLayout {...props}>{props.children}</PublicLayout>;
};

export default CurrentLayout;
