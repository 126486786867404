import * as yup from "yup";
import {FormProps} from "../../../../utils/FormProps";
import {FormikHelpers} from "formik";
import {Company} from "../../../../types/companies";

export interface CompanyFormValues {
    id?: string;
    name?: string | null;
    description?: string | null;
}

export const CompanyValidationSchema = yup.object().shape({
    name: yup.string().required("Required"),
    description: yup.string().required("Required"),
});

export type CompanyFormProps = FormProps<Company>;

export interface UseCompanyForm {
    create: (values: CompanyFormValues, actions: FormikHelpers<CompanyFormValues>) => void
    update: (values: CompanyFormValues, actions: FormikHelpers<CompanyFormValues>, model: Company) => void
}
