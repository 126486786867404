import { Route, Routes } from "react-router-dom";
import PoolsIndex from "../views/Pools/PoolsIndex/PoolsIndex";
import ProjectsIndex from "../views/Projects/ProjectsIndex/ProjectsIndex";
import TeamIndex from "../views/Team/TeamIndex/TeamIndex";
import SuccessStoriesIndex from "../views/SuccessStory/SuccessStoriesIndex/SuccessStoriesIndex";
import TestimonialsIndex from "../views/Testimonials/TestimonialsList/TestimonialsList";
import CompaniesIndex from "../views/Companies/CompaniesIndex/CompaniesIndex";
import PlatformImpactIndex from "../views/PlatformImpact/PlatformImpactIndex/PlatformImpactIndex";
import { AppRoutes } from "./AppRoutes";
import { CreateEditPool } from "../views/Pools/CreateEditPool";
import NotImplementedView from "../views/NotImplemented";
import EditTeamMember from "../views/Team/EditTeamMember/EditTeamMember";
import EditCompany from "../views/Companies/EditCompany/EditCompany";
import CreateCompany from "../views/Companies/EditCompany/CreateCompany";
import EditSuccessStory from "../views/SuccessStory/EditSuccessStory/EditSuccessStory";
import CreateTestimonial from "../views/Testimonials/CreateTestimonial/CreateTestimonial";
import CreateSuccessStory from "../views/SuccessStory/EditSuccessStory/CreateSuccessStory";
import CreateTeamMember from "../views/Team/EditTeamMember/CreateTeamMember";
import EditTestimonial from "../views/Testimonials/CreateTestimonial/EditTestimonial";
import { CreateEditProject } from "../views/Projects/CreateEditProject";
import BlendedPoolIndex from "../views/BlendedPool";

const PrivateRoutes = () => {
    return (
        <Routes>
            <Route path={`/`} element={<PoolsIndex />} />
            <Route path={`${AppRoutes.POOLS}`} element={<PoolsIndex />} />
            <Route path={`${AppRoutes.POOLS}/:id/edit`} element={<CreateEditPool />} />
            <Route path={`${AppRoutes.POOLS}/create`} element={<CreateEditPool />} />
            <Route path={`${AppRoutes.BLENDED_POOL}`} element={<BlendedPoolIndex />} />

            {/* Projects */}
            <Route path={`${AppRoutes.PROJECTS}`} element={<ProjectsIndex />} />
            <Route path={`${AppRoutes.PROJECTS}/:id/edit`} element={<CreateEditProject />} />
            <Route path={`${AppRoutes.PROJECTS}/create`} element={<CreateEditProject />} />

            {/* Team */}
            <Route path={`${AppRoutes.TEAM}`} element={<TeamIndex />} />
            <Route path={`${AppRoutes.TEAM}/:id/edit`} element={<EditTeamMember />} />
            <Route path={`${AppRoutes.TEAM}/create`} element={<CreateTeamMember />} />

            {/* Success Stories */}
            <Route path={`${AppRoutes.SUCCESS_STORIES}`} element={<SuccessStoriesIndex />} />
            <Route path={`${AppRoutes.SUCCESS_STORIES}/:id/edit`} element={<EditSuccessStory />} />
            <Route path={`${AppRoutes.SUCCESS_STORIES}/create`} element={<CreateSuccessStory />} />

            {/* Testimonials */}
            <Route path={`${AppRoutes.TESTIMONIALS}`} element={<TestimonialsIndex />} />
            <Route path={`${AppRoutes.TESTIMONIALS}/:id/edit`} element={<EditTestimonial />} />
            <Route path={`${AppRoutes.TESTIMONIALS}/create`} element={<CreateTestimonial />} />

            {/* Companies */}
            <Route path={`${AppRoutes.COMPANIES}`} element={<CompaniesIndex />} />
            <Route path={`${AppRoutes.COMPANIES}/:id/edit`} element={<EditCompany />} />
            <Route path={`${AppRoutes.COMPANIES}/create`} element={<CreateCompany />} />

            {/* Platform impact */}
            <Route path={`${AppRoutes.PLATFORM_IMPACT}`} element={<PlatformImpactIndex />} />

            <Route path={`${AppRoutes.UNDER_CONSTRUCTION}`} element={<NotImplementedView />} />
        </Routes>
    );
};

export default PrivateRoutes;
