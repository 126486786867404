import {useMutation, UseMutationResult, useQueryClient} from 'react-query';
import {httpClient} from "../../api/axios";
import {SuccessStory, SuccessStoryImage} from "../../types/successStories";
import {
    CreateSuccessStoryImageMutationVariables,
    CreateSuccessStoryMutationVariables,
    UpdateSuccessStoryMutationVariables
} from "./types";

export const useCreateSuccessStoryMutation = (): UseMutationResult<SuccessStory, Error, CreateSuccessStoryMutationVariables> => {
    const queryClient = useQueryClient();

    return useMutation<SuccessStory, Error, CreateSuccessStoryMutationVariables>(
        async ({request}) => {
            const response = await httpClient.post('/success-stories', request);
            return response.data;
        },
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries('success-stories');
            },
        },
    );
};

export const useUpdateSuccessStoryMutation = (): UseMutationResult<SuccessStory, Error, UpdateSuccessStoryMutationVariables> => {
    const queryClient = useQueryClient();

    return useMutation<SuccessStory, Error, UpdateSuccessStoryMutationVariables>(
        async ({request, successStoryId}) => {
            const response = await httpClient.put(`/success-stories/${successStoryId}`, request);
            return response.data;
        },
        {
            onSuccess: async (data, variables) => {
                await queryClient.invalidateQueries(['success-stories', variables.successStoryId]);
            },
        },
    );
};

export const useCreateSuccessStoryImageMutation = (): UseMutationResult<SuccessStoryImage, Error, CreateSuccessStoryImageMutationVariables> => {
    const queryClient = useQueryClient();

    return useMutation<SuccessStoryImage, Error, CreateSuccessStoryImageMutationVariables>(
        async ({successStoryId, request}) => {
            const formData = new FormData();
            if (request?.file !== undefined) {
                formData.append('file', request?.file as Blob);
            }

            const response = await httpClient.post(`/success-stories/${successStoryId}/images`, formData, {headers: {'Content-Type': 'multipart/form-data'}});
            return response.data;
        },
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries('success-stories-images');
            },
        },
    );
};
