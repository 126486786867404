import React, {FormHTMLAttributes, ReactNode} from 'react';
import {Box, Grid} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {formStyle} from '../../utils/style';

export const useStyles = makeStyles(() => ({
    formBody: {
        ...formStyle(),
    },
}));

interface Props extends FormHTMLAttributes<HTMLFormElement> {
    children: ReactNode;
}

const AppForm: React.FC<Props> = ({children}) => {
    const classes = useStyles();
    return (
        <Grid container direction="column" alignItems="center">
            <Box className={classes.formBody}>{children}</Box>
        </Grid>
    );
};

export default AppForm;
