export interface FullName {
    firstName?: string | null;
    lastName?: string | null;
}

export enum DraftStatus {
    DraftIn = "DraftIn",
    DraftOut = "DraftOut",
}

export enum ListNames {
    Pools = "pools",
    Projects = "projects",
    Team = "team",
    Companies = "company",
    SuccessStories = "success-stories",
    Testimonials = "testimonials",
}
