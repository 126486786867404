import React, { ReactElement, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ProjectFormProps, ProjectFormValues } from "../ProjectForm/types";
import {
    Box, Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Grid,
    Switch,
    Typography
} from "@mui/material";
import { AppRoutes } from "../../../../routes/AppRoutes";
import { AppButton } from "../../../../components";
import { DraftStatus } from "../../../../types/shared";
import { AppButtonError } from "../../../../components/AppButton";
import { useProjectForm } from "../ProjectForm/hooks";

export const ProjectManageForm: React.FC<ProjectFormProps> = ({
                                                                  model = {},
                                                                  onTabChange
                                                              }: ProjectFormProps): ReactElement => {
    const navigate = useNavigate();
    const { update } = useProjectForm();

    const [isDraft, setIsDraft] = useState(model.draft === DraftStatus.DraftIn);

    const [openDelete, setOpenDelete] = React.useState(false);

    const projectId = model?.id;

    const handleBack = () => {
        if (onTabChange) {
            onTabChange("overview");
        } else {
            navigate(`${AppRoutes.PROJECTS}`);
        }
    };


    const handleFinish = useCallback(() => {
        navigate(`${AppRoutes.PROJECTS}`);
    }, [navigate]);


    const handleCloseDelete = () => {
        setOpenDelete(false);
    };


    return (
        <Container sx={{ pt: 2, pb: 2 }}>
            <Grid container spacing={2} rowSpacing={2} alignItems="center">
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                        Manage settings
                    </Typography>
                </Grid>

                <Grid item xs={6}>
                    <Typography gutterBottom>
                        Make Project Info Public
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Box display="flex" justifyContent="flex-end">
                        <Switch
                            id="draft"
                            checked={!isDraft}
                            onChange={() => {
                                if (projectId) {
                                    const draft = model.draft === DraftStatus.DraftOut ? DraftStatus.DraftIn : DraftStatus.DraftOut;
                                    update({ ...model, draft } as ProjectFormValues, projectId as string);
                                    setIsDraft(draft === DraftStatus.DraftIn);
                                }
                            }}
                            inputProps={{ "aria-label": "controlled" }}
                        />
                    </Box>
                </Grid>
                {projectId &&
                <>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={6}>
                        <Typography color="error" gutterBottom>
                            Delete project
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Box display="flex" justifyContent="flex-end">
                            <AppButtonError variant="text" onClick={() => {
                                setOpenDelete(true);
                            }}>
                                Delete
                            </AppButtonError>
                            <Dialog
                                open={openDelete}
                                onClose={handleCloseDelete}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">
                                    {"Do you want to delete the project?"}
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        This operation will delete project all data.
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleCloseDelete}>Disagree</Button>
                                    <Button onClick={handleCloseDelete} autoFocus>
                                        Agree
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </Box>
                    </Grid>

                </>
                }
                <Grid width={"100%"}>
                    <Box sx={{ display: "flex", flexDirection: "row", pt: 10 }}>
                        <AppButton
                            color="inherit"
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                        >
                            Back
                        </AppButton>
                        <Box sx={{ flex: "1 1 auto" }} />
                        <AppButton type="button"
                                   onClick={handleFinish}
                                   variant="contained"
                        >
                            Finish
                        </AppButton>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};
