import React from "react";
import { GridColDef, GridEventListener, GridRenderCellParams, GridRowParams } from "@mui/x-data-grid";
import { Avatar, Box, Paper, Skeleton, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";

import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../routes/AppRoutes";
import { AppButton } from "../../../components";
import { useHeaderStyles } from "../../../utils/style";
import TokenIcon from "@mui/icons-material/Token";
import AppFormHeader from "../../../components/AppFormHeader";
import { useGetAllPoolsQuery } from "../../../queries/pools";
import { AppCreateEditForm } from "../../../components/AppCreateEditForm";
import AppDataGrid from "../../../components/AppDataGrid/AppDataGrid";
import { Pool, PoolImage, PoolStatus } from "../../../types/pools";
import { DeleteButton } from "../../DeleteButton";
import { ListNames } from "../../../types/shared";

const getColumns = (getPool: (id: string) => Pool | undefined): GridColDef[] => [
    {
        field: "images",
        headerName: "",
        filterable: false,
        minWidth: 30,
        renderCell: (params: GridRenderCellParams<PoolImage[]>) => {
            if (params.value?.length && params.value[0]["urls"]) {
                return (
                    <Avatar
                        alt="Remy Sharp"
                        sx={{ width: 30, height: 30 }}
                        src={params.value[0]["urls"]["original"] || ""}
                    />
                );
            } else {
                return <Skeleton variant="circular" width={30} height={30} animation={false} />;
            }
        },
    },
    { field: "name", headerName: "Name", minWidth: 150 },
    { field: "status", headerName: "Status", minWidth: 90 },
    { field: "overview", headerName: "Overview", flex: 1 },
    {
        field: "id",
        headerName: "",
        minWidth: 80,
        align: "right",
        sortable: false,
        renderCell: (params: GridRenderCellParams<string>) => (
            <DeleteButton
                id={params.value as string}
                item={getPool(params.value as string)}
                listName={ListNames.Pools}
            />
        ),
    },
];

function PoolsIndex() {
    let navigate = useNavigate();

    const classes = useHeaderStyles();

    const [value, setValue] = React.useState("all");
    const { data: pools = [], isLoading } = useGetAllPoolsQuery();

    const getPool = (id: string) => pools.find((pool) => pool.id === id);

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const CreatePoolButton = () => {
        return <AppButton onClick={handleCreatePool}>Add Pool</AppButton>;
    };

    const handleCreatePool = () => {
        navigate(`${AppRoutes.POOLS}/create`);
    };

    const handleRowClickEvent: GridEventListener<"rowClick"> = (params: GridRowParams) => {
        navigate(`${AppRoutes.POOLS}/${params.id}/edit`);
    };

    return (
        <AppCreateEditForm>
            <AppFormHeader
                level1Route={AppRoutes.POOLS}
                level1Caption="Pools"
                level1Icon={TokenIcon}
                level2Caption={null}
                actionButton={CreatePoolButton()}
            />

            <Paper className={classes.paper}>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <TabList onChange={handleTabChange}>
                            <Tab label="All" value="all" />
                            <Tab label="Active" value="active" />
                            <Tab label="Closed" value="closed" />
                            <Tab label="Draft" value="draft" />
                        </TabList>
                    </Box>
                    <TabPanel className={classes.panelTab} value="all">
                        <div style={{ display: "flex", height: "100%" }}>
                            <div style={{ flexGrow: 1 }}>
                                <AppDataGrid
                                    columns={getColumns(getPool)}
                                    onRowClick={handleRowClickEvent}
                                    rows={pools || []}
                                    isLoading={isLoading}
                                />
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel className={classes.panelTab} value="active">
                        <div style={{ display: "flex", height: "100%" }}>
                            <div style={{ flexGrow: 1 }}>
                                <AppDataGrid
                                    columns={getColumns(getPool)}
                                    onRowClick={handleRowClickEvent}
                                    rows={pools || []}
                                    isLoading={isLoading}
                                    filterValue={PoolStatus.Active}
                                    columnField="status"
                                />
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel className={classes.panelTab} value="closed">
                        <div style={{ display: "flex", height: "100%" }}>
                            <div style={{ flexGrow: 1 }}>
                                <AppDataGrid
                                    columns={getColumns(getPool)}
                                    onRowClick={handleRowClickEvent}
                                    rows={pools || []}
                                    isLoading={isLoading}
                                    filterValue={PoolStatus.Closed}
                                    columnField="status"
                                />
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel className={classes.panelTab} value="draft">
                        <div style={{ display: "flex", height: "100%" }}>
                            <div style={{ flexGrow: 1 }}>
                                <AppDataGrid
                                    columns={getColumns(getPool)}
                                    onRowClick={handleRowClickEvent}
                                    rows={pools || []}
                                    isLoading={isLoading}
                                    filterValue={PoolStatus.Draft}
                                    columnField="status"
                                />
                            </div>
                        </div>
                    </TabPanel>
                </TabContext>
            </Paper>
        </AppCreateEditForm>
    );
}

export default PoolsIndex;
