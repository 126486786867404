import * as yup from "yup";

const tickerRegex = /^[a-zA-Z0-9]{3,5}$/;
const nameRegex = /^[a-zA-Z0-9\s]*$/;

export const PoolBlockchainValidationSchema = yup.object().shape({
    poolSize: yup.number().moreThan(-1).required("Required"),
    minInvestmentSize: yup.number().moreThan(-1).required("Required"),
    lockupPeriod: yup.number().moreThan(-1).required("Required"),
    poolTokenName: yup
        .string()
        .matches(nameRegex, "Token name can only contain alphanumeric characters and symbols")
        .required("Required"),
    poolTokenSymbol: yup
        .string()
        .matches(tickerRegex, "Ticker can only contain 3-5 alphanumeric characters")
        .required("Required"),
});
