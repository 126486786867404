/**
 * Navigates to the specified URL with options
 */
export function navigateTo(url: string, replaceInsteadOfPush = false, optionalTitle = "") {
    if (replaceInsteadOfPush) {
        window.history.replaceState(null, optionalTitle, url);
    } else {
        window.history.pushState(null, optionalTitle, url);
    }
}
