import {FormikHelpers} from "formik";
import {CompanyFormValues, UseCompanyForm} from "./types";
import {Company} from "../../../../types/companies";
import {CreateCompanyRequest, UpdateCompanyRequest} from "../../../../queries/companies/types";
import {useCreateCompanyMutation, useUpdateCompanyMutation} from "../../../../queries/companies/mutations";

const useCompanyForm = (): UseCompanyForm => {
    const createMutation = useCreateCompanyMutation();
    const updateMutation = useUpdateCompanyMutation();

    const create = async (values: CompanyFormValues, actions: FormikHelpers<CompanyFormValues>) => {
        try {
            const request: CreateCompanyRequest = values;
            await createMutation.mutateAsync({request}, {
                onSuccess: async () => {
                },
            });
        } catch (error) {
        }
    };

    const update = async (values: CompanyFormValues, actions: FormikHelpers<CompanyFormValues>, model: Company) => {
        try {
            const request: UpdateCompanyRequest = values;
            await updateMutation.mutateAsync(
                {request, companyId: String(model.id)},
                {
                    onSuccess: async () => {
                    },
                },
            );
        } catch (error) {
        }
    };

    return {
        create,
        update,
    };
};

export default useCompanyForm;