// Functionality:

// BP call: depositToOpenPool(poolAddress, amount) - invest from Blended Pool to Regional Pool(poolAddress)
// BP call: withdrawYieldFromPool(poolAddress) - withdraw yields from Regional Pool(poolAddress) to Blended Pool’s balance
// BP call: withdrawFromPool(poolAddress, amount) - withdraw from Regional Pool(poolAddress) to Blended Pool’s balance

import { ethers } from "ethers";
import abiBP from "../../../../contracts/abi/BlendedPool.json";
import abiPF from "../../../../contracts/abi/PoolFactory.json";
import abiRP from "../../../../contracts/abi/Pool.json";
import { useWeb3React } from "@web3-react/core";
import { TransactionStatus, TransactionStatusValues } from "../../../Pools/Forms/FundsManageForm/types";

interface TransactionsBPHooksInterface {
    depositToOpenPool: (amount: number) => Promise<TransactionStatusValues>;
    withdrawYieldFromPool: () => Promise<TransactionStatusValues>;
    withdrawFromPool: (amount: number) => Promise<TransactionStatusValues>;
}

export const useTransactionsBP = (poolId: string): TransactionsBPHooksInterface => {
    const { library: provider } = useWeb3React();
    let signer: any = null;

    if (provider !== undefined) {
        signer = provider.getSigner();
    }

    const BlendedPoolAddress = process.env.REACT_APP_CONTRACT_BLENDED_POOL as string;
    const BlendedPoolContract = new ethers.Contract(BlendedPoolAddress, abiBP, signer);

    const PoolFactoryAddress = process.env.REACT_APP_CONTRACT_POOL_FACTORY as string;
    const PoolFactoryContract = new ethers.Contract(PoolFactoryAddress, abiPF, signer);

    const getRegionalPoolAddress = async (): Promise<string> => {
        return await PoolFactoryContract.pools(poolId);
    };

    const getRegionalPoolContract = async (): Promise<ethers.Contract> => {
        const regionalPoolAddress = await getRegionalPoolAddress();
        return new ethers.Contract(regionalPoolAddress, abiRP, signer);
    };

    const getRPAssetDecimals = async (): Promise<number> => {
        const regionalPoolContract = await getRegionalPoolContract();
        return await regionalPoolContract.decimals();
    };

    const depositToOpenPool = async (amount: number) => {
        const decimals = await getRPAssetDecimals();
        const decimalsNumber = ethers.toNumber(decimals);
        const parsedAmount = ethers.parseUnits(amount.toString(), decimals);

        console.log("Parsed amount: ", parsedAmount);
        console.log("Decimals: ", decimalsNumber);

        try {
            const regionalPoolAddress = await getRegionalPoolAddress();
            console.log("Pool address: ", regionalPoolAddress);
            const tx = await BlendedPoolContract.depositToOpenPool(regionalPoolAddress, parsedAmount);
            const receipt = await provider.waitForTransaction(tx.hash);

            if (receipt.status === 1) {
                return {
                    status: TransactionStatus.Success,
                    message: "Transaction successful!",
                };
            } else {
                return {
                    status: TransactionStatus.Error,
                    message: "Transaction failed!",
                };
            }
        } catch (error: Error | any) {
            return {
                status: TransactionStatus.Error,
                message: `${error.code}: ${error.message}`,
            };
        }
    };

    const withdrawYieldFromPool = async () => {
        try {
            const regionalPoolAddress = await getRegionalPoolAddress();
            const tx = await BlendedPoolContract.withdrawYieldFromPool(regionalPoolAddress);
            const receipt = await provider.waitForTransaction(tx.hash);

            if (receipt.status === 1) {
                return {
                    status: TransactionStatus.Success,
                    message: "Transaction successful!",
                };
            } else {
                return {
                    status: TransactionStatus.Error,
                    message: "Transaction failed!",
                };
            }
        } catch (error: Error | any) {
            return {
                status: TransactionStatus.Error,
                message: `${error.code}: ${error.message}`,
            };
        }
    };

    const withdrawFromPool = async (amount: number) => {
        const decimals = await getRPAssetDecimals();
        const parsedAmount = ethers.parseUnits(amount.toString(), decimals);
        try {
            const regionalPoolAddress = await getRegionalPoolAddress();
            const tx = await BlendedPoolContract.withdrawFromPool(regionalPoolAddress, parsedAmount);
            const receipt = await provider.waitForTransaction(tx.hash);

            if (receipt.status === 1) {
                return {
                    status: TransactionStatus.Success,
                    message: "Transaction successful!",
                };
            } else {
                return {
                    status: TransactionStatus.Error,
                    message: "Transaction failed!",
                };
            }
        } catch (error: Error | any) {
            return {
                status: TransactionStatus.Error,
                message: `${error.code}: ${error.message}`,
            };
        }
    };

    return { depositToOpenPool, withdrawYieldFromPool, withdrawFromPool };
};
