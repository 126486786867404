import {FormikHelpers} from "formik";
import {CompanyImageFormValues, UseCompanyImageForm} from "./types";
import {CreateCompanyImageRequest} from "../../../../queries/companies/types";
import {useCreateCompanyImageMutation} from "../../../../queries/companies/mutations";

const useCompanyImageForm = (): UseCompanyImageForm => {
    const createMutation = useCreateCompanyImageMutation();

    const create = async (values: CompanyImageFormValues, actions: FormikHelpers<CompanyImageFormValues>) => {
        try {
            const request: CreateCompanyImageRequest = values;

            await createMutation.mutateAsync({companyId: values.companyId, request}, {
                onSuccess: async () => {
                },
            });
        } catch (error) {
        }
    };

    return {
        create
    };
};

export default useCompanyImageForm;